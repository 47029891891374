import React from "react";
import Icon from "@mdi/react";
import { mdiChevronUp, mdiChevronDown } from "@mdi/js";
import DisplayCard from "./displayCards";

const MainDisplay = ({
  applications,
  color,
  name,
  status,
  toggle,
  index,
  open,
  ClickButton,
  dropDownSelect,
}) => {
  return (
    <div
      className={`rounded p-3 main-pipeline ${index === open ? "active" : ""}`}
    >
      <div className="d-flex px-2 justify-content-between align-items-baseline ">
        <div
          className="d-flex align-items-center p-cursor"
          onClick={() => toggle(index)}
        >
          <Icon
            path={index === open ? mdiChevronDown : mdiChevronUp}
            size={0.9}
            color={color}
          />
          <h1 className="pb-0 ml-3 other_det_sm">{name}</h1>
        </div>

        <div className="mx-3">{ClickButton}</div>
      </div>
      <div>
        {applications.length > 0
          ? applications.map((application, i) => {
              return (
                <DisplayCard
                  application={application}
                  key={i}
                  status={status}
                  dropDownSelect={dropDownSelect}
                />
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default MainDisplay;
