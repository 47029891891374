import React from "react";
import Project from "../projects/Project";

const EmpProject = ({ projects, editTheProject }) => {
  return (
    <div className="row staff-grid-row">
      {projects.length
        ? projects.map((project) => {
            let projectTeam = [];
            let projectLeader = [];
            if (project.team && project.team.length) {
              projectTeam = project.team.map((employee) => {
                let name = employee.personalInfo.name.first;
                name += employee.personalInfo.name.middle
                  ? " " + employee.personalInfo.name.middle
                  : "";
                name += employee.personalInfo.name.last
                  ? " " + employee.personalInfo.name.last
                  : "";
                const id = employee._id;
                const image = employee.emergencyInfo.image;
                return { name: name, id: id, image: image };
              });
            }
            if (project.projectLeader && project.projectLeader.length) {
              projectLeader = project.projectLeader.map((employee) => {
                let name = employee.personalInfo.name.first;
                name += employee.personalInfo.name.middle
                  ? " " + employee.personalInfo.name.middle
                  : "";
                name += employee.personalInfo.name.last
                  ? " " + employee.personalInfo.name.last
                  : "";
                const id = employee._id;
                const image = employee.emergencyInfo.image;
                return { name: name, id: id, image: image };
              });
            }
            return (
              <Project
                key={project._id}
                project={project}
                projectTeamList={projectTeam}
                projectLeaderList={projectLeader}
                onEdit={editTheProject}
                canShowControls={false}
              />
            );
          })
        : ""}
    </div>
  );
};

export default EmpProject;
