import React from "react";

const BarChart = () => {
  return (
    <div className="w-full rounded border w-100 chart_bar">
      <div className=" d-flex align-items-center justify-content-between">
        <h4 className="pb-0 other_det_sm">Application Received Time</h4>
        <div className="pb-0">
          <button className="">
            <p className="pb-0"></p>
             <select name="select"  className=" p-2 rounded select_bar" id="select_year" value="this year">
              <option value="This year">This year</option>
              <option value="last month">Last Month</option>
              <option value="last 6 months">last 6 months</option>
              <option value="last year">last year</option>
             </select>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BarChart;
