import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import TopbarGuest from "../topbar/TopbarGuest";
import { URLS } from "../urls";
import Icon from "@mdi/react";
import "../../assets/scss/job.css";
import currency from "currency.js";

import "../../assets/css/style.css";
import {
  mdiShareVariantOutline,
  mdiCalendarBlankOutline,
  mdiAccountOutline,
  mdiEyeOutline,
  mdiCheck,
  mdiBriefcaseOutline,
  mdiRocketOutline,
  mdiMapMarkerOutline,
  mdiAccountGroupOutline,
  mdiCardAccountDetailsOutline,
} from "@mdi/js";
import ModalLoader from "../ModalLoader";
import ApplyJob from "./ApplyJob";
import { changeDateFormat } from "../../utils";

const JobView = (props) => {
  const [openApply, setOpenApply] = useState(false);

  const [state, setState] = useState({
    job: {
      _id: "",
      jobTitle: "",
      department: "",
      location: "",
      vacancies: 0,
      applications: 0,
      views: 0,
      experience: "",
      salaryFrom: "",
      salaryTo: "",
      jobType: "Full Time",
      status: "Open",
      startDate: "",
      expiredDate: "",
      description: "",
    },
    isLoading: false,
  });

  const componentWillMount = () => {
    const id = window.location.pathname.split("/")[2];
    const token = "dummy token";
    setState((el) => ({ ...el, isLoading: true }));
    fetch(`${URLS.backendJobs}/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({ ...el, isLoading: false }));
        setState((el) => ({
          ...el,
          job: result.job,
        }));
      });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      componentWillMount();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const { job, isLoading } = state;

  const displayWriteUps = (text) => {
    let disText = "";

    if (text) {
      const splitText = text?.split("\n");
      if (splitText.length > 0) {
        disText = splitText?.map((el) => (
          <li className="mb-1 d-flex align-items-start  flex-row">
            <div>
              <Icon path={mdiCheck} size={0.7} color="#7BAC80" />
            </div>
            <p className="ml-2 secondary-text">{el}</p>
          </li>
        ));
      } else {
        disText = text;
      }
    }

    return disText;
  };

  return (
    <body>
      <ModalLoader show={isLoading} />
      {/* <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet> */}
      <div className="main-wrapper">
        {/* <TopbarGuest /> */}

        <div className="page-wrapper mt-n4 job-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content">
            <div className=" p-3 mb-3 w-100 bg-white rounded  border d-flex align-items-center justify-content-between">
              <div className=" d-flex gap-3 align-items-start flex-column">
                <h3 className="job_title">{job.jobTitle}</h3>
                <p className="softer-text">{job.department}</p>
                <div className="d-flex align-items-center">
                  <div className=" list-unstyled d-flex gap-3 align-items-center justify-content-center">
                    <li className="softer-text gap-2 align-items-center">
                      <Icon
                        path={mdiCalendarBlankOutline}
                        size={0.7}
                        color="#94A3B8"
                      />
                      Post Date:{" "}
                      <span className="softcore-text pb-0 ">
                        {changeDateFormat(job.startDate)}
                      </span>
                    </li>
                    <p className="dot pb-0">.</p>
                    <li className="softer-text pb-0 gap-2">
                      <Icon
                        path={mdiCalendarBlankOutline}
                        size={0.7}
                        color="#94A3B8"
                      />
                      Last Date:{" "}
                      <span className="softcore-text pb-0">
                        {changeDateFormat(job.expiredDate)}
                      </span>
                    </li>
                    <p className="dot pb-0">.</p>
                    <li className="softer-text pb-0 gap-2">
                      <Icon
                        path={mdiAccountOutline}
                        size={0.7}
                        color="#94A3B8"
                      />
                      Applications:{" "}
                      <span className=" softcore-text pb-0">
                        {job.applications}
                      </span>
                    </li>
                    <p className="dot pb-0">.</p>
                    <li className="softer-text pb-0 gap-2">
                      <Icon path={mdiEyeOutline} size={0.7} color="#94A3B8" />
                      Views:{" "}
                      <span className="softcore-text pb-0">{job.views}</span>
                    </li>
                  </div>
                </div>
              </div>{" "}
              <div className=" d-flex gap-5 align-items-center ">
                <Icon path={mdiShareVariantOutline} size={1} color="#9ca3af" />
                <div className="line-long"></div>
                <button
                  className="job-btn rounded search-bt"
                  onClick={() => setOpenApply(true)}
                >
                  Apply
                </button>
              </div>
            </div>
            <div className="det-wrap rounded rounded-lg">
              <div className="description_box">
                <div className="job-content rounded border job-widget">
                  <div className="job-description">
                    <div className="job">
                      <h3>Job Description</h3>
                    </div>
                    {/* <p>{job.description}</p> */}
                    <ul className=" p-0 m-0">
                      {displayWriteUps(job?.description)}
                    </ul>
                  </div>
                  <div className="mt-4">
                    <div className="job">
                      <h3>Responsibilities</h3>
                    </div>

                    <ul className=" p-0 m-0">
                      {displayWriteUps(job?.responsibilities)}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="job_role ">
                <div className="job-det-info border w-100 job-widget">
                  <div className="info-list w-100 d-flex align-items-center justify-content-between">
                    <span className="secondary-text align-items-center d-flex">
                      <Icon
                        path={mdiBriefcaseOutline}
                        size={0.9}
                        color="#334155"
                      />
                      <h5 className="ml-1 secondary-text">Job Type</h5>
                    </span>
                    <p className="job__type p-1 rounded"> {job.jobType}</p>
                  </div>
                  <div className="info-list w-100 d-flex align-items-center justify-content-between">
                    <span className="secondary-text align-items-center d-flex">
                      <Icon
                        path={mdiCardAccountDetailsOutline}
                        size={0.9}
                        color="#334155"
                      />
                      <h5 className="ml-1 secondary-text">Salary</h5>
                    </span>
                    <p className="other_det">
                      {currency(job.salaryFrom, {
                        symbol: "",
                      }).format()}
                      k -{" "}
                      {currency(job.salaryTo, {
                        symbol: "",
                      }).format()}
                      k
                    </p>
                  </div>
                  <div className="info-list mt-2 w-100 d-flex align-items-center justify-content-between">
                    <span className="secondary-text align-items-center d-flex">
                      <Icon
                        path={mdiRocketOutline}
                        size={0.9}
                        color="#334155"
                      />
                      <h5 className="ml-1 secondary-text">Experience</h5>
                    </span>
                    <p className="other_det"> {job.experience}</p>
                  </div>
                  <div className="info-list mt-2 w-100 d-flex align-items-center justify-content-between">
                    <span className="secondary-text align-items-center d-flex">
                      <Icon
                        path={mdiMapMarkerOutline}
                        size={0.9}
                        color="#334155"
                      />
                      <h5 className="ml-1 secondary-text">Location</h5>
                    </span>
                    <p className="other_det"> {job.location}</p>
                  </div>
                  <div className="info-list mt-2 w-100 d-flex align-items-center justify-content-between">
                    <span className="secondary-text align-items-center d-flex">
                      <Icon
                        path={mdiAccountGroupOutline}
                        size={0.9}
                        color="#334155"
                      />
                      <h5 className="ml-1 secondary-text">Vacancy</h5>
                    </span>
                    <p className="other_det"> {job.vacancies}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Page Content -->*/}

          {/*<!-- Apply Job Modal -->*/}
          {openApply && (
            <ApplyJob
              _id={job._id}
              openApply={openApply}
              setOpenApply={setOpenApply}
              componentWillMount={componentWillMount}
            />
          )}
          {/*<!-- /Apply Job Modal -->*/}
        </div>

        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
};

export default JobView;
