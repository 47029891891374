import React, { useEffect, useState } from "react";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import Select from "react-select";
import DatePicker from "react-datetime";
import moment from "moment";
import { Modal, Row, Col, Form } from "react-bootstrap";
import { useCookie } from "../../utils";

export const AddSuspension = ({
  show,
  handleClose,
  suspensions,
  setSuspensions,
}) => {
  const date = new Date();
  const branch_id = useCookie("branch_id");
  const [employees, setEmployees] = useState([]);
  const [suspensionData, setSuspensionData] = useState({
    employeeId: "",
    noticeDate: moment(date).format("DD/MM/YYYY"),
    suspensionDate: moment(date).format("DD/MM/YYYY"),
    reason: "",
    departmentId: "",
    branch_id,
    type: "",
  });

  useEffect(() => {
    const getEmployees = () => {
      const token = "dummy token";
      fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          const emp = result.employees
            .filter(
              (el) =>
                el.hasResigned !== true &&
                el.isTerminated !== true &&
                el.hasSuspended !== true
            )
            .map((em) => ({
              ...em,
              label: `${em.personalInfo.name.first || ""} ${
                em.personalInfo.name.middle || ""
              } ${em.personalInfo.name.last || ""}`,
              value: em.userId,
            }));

          setEmployees(emp);
        })
        .catch((err) => console.log(err));
    };

    getEmployees();
    return () => {};
  }, []);

  const setEmployeeId = (userId) => {
    const employee = employees.find((employee) => employee.userId === userId);

    setSuspensionData({
      ...suspensionData,
      employeeId: userId,
      departmentId: employee?.jobInfo?.department?._id,
    });
  };

  const submitSuspension = (e) => {
    e.preventDefault();

    const token = "dummy token";
    fetch(`${URLS.backendSuspension}/save`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ suspensionData }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          setSuspensions([...suspensions, result.suspension]);
          toast("Suspension saved", {
            type: "success",
          });
          handleClose();
        } else {
          if (result.exists) {
            toast("Employee is already on suspension", {
              type: "error",
            });
            return;
          }
          throw Error();
        }
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to save Suspension", {
          type: "error",
        });
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby=""
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Suspension</Modal.Title>
      </Modal.Header>
      <div className="modal-body">
        <form onSubmit={(e) => submitSuspension(e)}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  <span className="text-danger">*</span>Employee
                </Form.Label>
                {employees && (
                  <Select
                    closeMenuOnSelect={true}
                    isSearchable
                    required
                    classNamePrefix="form-control"
                    value={employees?.find(
                      (el) => el.value === suspensionData?.employeeId
                    )}
                    onChange={(selected) => {
                      setEmployeeId(selected.value);
                    }}
                    options={employees}
                  />
                )}
              </Form.Group>
            </Col>
            {/*  */}
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  <span className="text-danger">*</span> Type
                </Form.Label>
                <select
                  className="form-control"
                  aria-label="Default select example"
                  value={suspensionData.type}
                  name="type"
                  onChange={(e) =>
                    setSuspensionData({
                      ...suspensionData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Payment">With Payment</option>
                  <option value="Non-Payment">Without Payment</option>
                </select>
              </Form.Group>
            </Col>
          </Row>

          {/*  */}
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  <span className="text-danger">*</span> Notice Date
                </Form.Label>
                <DatePicker
                  value={suspensionData.noticeDate}
                  required
                  onChange={(e) => {
                    setSuspensionData({
                      ...suspensionData,
                      noticeDate: moment(e._d).format("DD/MM/YYYY"),
                    });
                  }}
                  closeOnSelect={true}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  <span className="text-danger">*</span> Suspension Date
                </Form.Label>
                <DatePicker
                  value={suspensionData.suspensionDate}
                  required
                  onChange={(e) => {
                    setSuspensionData({
                      ...suspensionData,
                      suspensionDate: moment(e._d).format("DD/MM/YYYY"),
                    });
                  }}
                  closeOnSelect={true}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label>
              <span className="text-danger">*</span> Reason
            </Form.Label>
            <Form.Control
              as="textarea"
              name="reason"
              value={suspensionData.reason}
              onChange={(e) => {
                setSuspensionData({
                  ...suspensionData,
                  [e.target.name]: e.target.value,
                });
              }}
              rows={4}
              placeholder="..."
              required
            />
          </Form.Group>

          <div className="submit-section">
            <button className="btn btn-primary submit-btn">Submit</button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
