import React from "react";
import { useState } from "react";
import Overview from "./Overview";
import GoalManagement from "./GoalManagement";
import ContiniousFeedback from "./ContiniousFeedback";

const PerformanceNavTab = () => {
  const [state, setState] = useState({
    applications: [],
    activeView: "overview",
    job: {},
  });

  const { job, activeView, applications } = state;

  const handleToggle = (view) => {
    setState((el) => ({ ...el, activeView: view }));
  };

  const headers = [
    {
      name: "Overview",
      statusName: "overview",
    },
    {
      name: "Goal Management",
      statusName: "management",
    },
    {
      name: "Continuous Feedback",
      statusName: "feedback",
    },
  ];

  return (
    <div className="">
      <div className="performance-navtab">
        <div className="d-flex align-items-center gap-lg">
          {headers.map((el) => (
            <div
              className={`navtab ${
                activeView === el.statusName ? "active_navtab" : "navtab"
              }`}
              onClick={() => handleToggle(el.statusName)}
            >
              {el.name}
            </div>
          ))}
        </div>
      </div>
      <br />
      <br></br>
      <br></br>
      {activeView === "overview" && <Overview applications={applications} />}
      {activeView === "management" && (
        <GoalManagement applications={applications} />
      )}
      {activeView === "feedback" && (
        <ContiniousFeedback applications={applications} />
      )}
    </div>
  );
};

export default PerformanceNavTab;
