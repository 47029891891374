import React, { useState, useEffect } from "react";

const PercentCounter = () => {
  const [count, setCount] = useState(0);
  const targetNumber = 65;

  useEffect(() => {
    const duration = 2000; // Animation duration in milliseconds
    const increment = targetNumber / (duration / 10); // Increment per interval

    const interval = setInterval(() => {
      setCount((prevCount) => {
        const nextCount = prevCount + increment;
        if (nextCount >= targetNumber) {
          clearInterval(interval); // Stop interval when target is reached
          return targetNumber; // Ensure exact target number
        }
        return nextCount;
      });
    }, 10); // Update every 10ms

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <div>
      <h1 id="count-number" className="">
        {Math.round(count)}%
      </h1>
    </div>
  );
};

export default PercentCounter;
