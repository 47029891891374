import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import currency from "currency.js";
import moment from "moment";
import Select from "react-select";

import { URLS } from "../urls";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";

import RsDateRangePicker from "../RsDateRangePicker";
import { useCookie } from "../../utils";
import { toast } from "react-toastify";
import ApplyPermissionModal from "./ApplyPermisssionModal.jsx";
import { Form } from "react-bootstrap";
import ModalLoader from "../ModalLoader";

const Permission = () => {
  const branch_id = useCookie("branch_id");

  const [loading, setLoading] = useState(false);
  const [showPermission, setShowPermission] = useState([]);
  const [openApplyPermission, setOpenApplyPermission] = useState(false);
  const [sortData, setSortData] = useState({
    startDate: "",
    endDate: "",
    userID: "",
  });

  const [allEmployee, setAllEmployee] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");

  const [allPermission, setAllPermission] = useState([]);

  const getPermission = ({ stDt, eDt, UId }) => {
    setLoading(true);
    const token = "dummy token";
    fetch(
      `${URLS.backendpermmission}/by-branch/${branch_id}?userID=${UId}&startDate=${stDt}&endDate=${eDt}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        setShowPermission(result?.permission);
        setAllPermission(result?.permission);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast("Unable to get TimeSheet", {
          type: "error",
        });
      });
  };

  const getEmployees = async () => {
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let emp = result.employees.map((em) => ({
          label: `${
            em.personalInfo.name.first ? em.personalInfo.name.first : ""
          } ${em.personalInfo.name.middle ? em.personalInfo.name.middle : ""} ${
            em.personalInfo.name.last ? em.personalInfo.name.last : ""
          }`,
          value: em._id,
        }));

        emp = [{ label: "All", value: "" }, ...emp];
        setAllEmployee(emp);
      });
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const filterByDateRange = (event, picker) => {
    setSortData({
      ...sortData,
      startDate: picker.startDate.format("yyyy-MM-DD"),
      endDate: picker.endDate.format("yyyy-MM-DD"),
    });
    picker.element.val(
      picker.startDate.format("yyyy-MM-DD") +
        " - " +
        picker.endDate.format("yyyy-MM-DD")
    );
  };

  const clearDateRange = (event, picker) => {
    setSortData({
      ...sortData,
      startDate: "",
      endDate: "",
    });

    picker.element.val("");
  };

  useEffect(() => {
    const { startDate, endDate, userID } = sortData;

    setTimeout(() => {
      getPermission({ stDt: startDate, eDt: endDate, UId: userID });
    }, 1000);
  }, [sortData]);

  const changeByUserId = (sta) => {
    setSortData({
      ...sortData,
      userID: sta,
    });
  };

  const searchEmployee = (id) => {
    if (id === "") {
      setAllPermission(showPermission);
    } else {
      const emp = showPermission?.filter((el) => el?.userID?._id === id);

      setAllPermission(emp);
    }
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="d-flex justify-content-between align-item-baseline mb-4 mt-2">
              <div className="d-flex align-item-baseline">
                <h4 className="page-title">Permission</h4>

                <div className="mb-2 mx-3 w-100">
                  <RsDateRangePicker
                    placement="bottomEnd"
                    onClean={(event, picker) => clearDateRange(event, picker)}
                    onOk={(event, picker) => filterByDateRange(event, picker)}
                  />
                </div>
              </div>

              <div className="mx-3 w-50">
                <Form.Group className="mb-3">
                  <Select
                    closeMenuOnSelect={true}
                    isSearchable
                    required
                    classNamePrefix="form-control"
                    value={allEmployee?.find(
                      (el) => el.value === selectedEmployee
                    )}
                    onChange={(selected) => {
                      setSelectedEmployee(selected.value);
                      searchEmployee(selected.value);
                      // changeByUserId(selected.value);
                    }}
                    options={allEmployee}
                  />
                </Form.Group>
              </div>

              <div className="d-flex align-item-baseline">
                <div className="w-100">
                  <button
                    className="btn add-btn"
                    onClick={() => setOpenApplyPermission(true)}
                  >
                    <i className="fa fa-plus"></i> Apply
                  </button>
                </div>
              </div>
            </div>
            {/*<!-- /Page Title -->*/}

            {/*<!-- Leave Statistics -->*/}
            <div></div>
            {/*<!-- /Leave Statistics -->*/}

            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <table className="table table-striped custom-table mb-0 datatable">
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Staff Name</th>
                        <th>Staff ID</th>
                        <th>Permit</th>
                        <th>Status</th>
                        <th>For Date</th>
                        <th>Description</th>
                        <th>Permit type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allPermission?.length > 0
                        ? allPermission?.map((permit, i) => {
                            return (
                              <tr key={i}>
                                <td>{i + 1}</td>

                                <td>
                                  {`${
                                    permit?.mode === "Single"
                                      ? permit?.userID?.personalInfo?.name
                                          ?.first
                                      : "All"
                                  } ${
                                    permit?.mode === "Single"
                                      ? permit?.userID?.personalInfo?.name?.last
                                      : "Employee"
                                  }`}
                                </td>

                                <td className="text-center">
                                  {permit?.mode === "Single"
                                    ? permit?.userID?.userId
                                    : "-- --"}
                                </td>

                                <td>{permit?.mode}</td>
                                <td
                                  className={`text-center fw-bold ${
                                    permit?.status === "Approved"
                                      ? "text-success"
                                      : "text-primary"
                                  }`}
                                >
                                  {permit.status}
                                </td>

                                <td>
                                  {`${moment(permit?.startDate)?.format(
                                    "MM/DD/YYYY"
                                  )} - ${moment(permit?.endDate)?.format(
                                    "MM/DD/YYYY"
                                  )}`}
                                </td>

                                <td>{permit?.description}</td>

                                <td>{permit?.type}</td>
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Page Content -->*/}

          {/*<!-- Add Leave Modal -->*/}
          {openApplyPermission && (
            <ApplyPermissionModal
              setOpenApplyPermission={setOpenApplyPermission}
              getPermission={getPermission}
            />
          )}
          {/*<!-- /Add Leave Modal -->*/}

          {/*<!-- Edit Leave Modal -->*/}
          {/* <EditLeaveModal leaveTypes={leaveTypes} editLeave={selectedLeave} /> */}
          {/*<!-- /Edit Leave Modal -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
      <ModalLoader show={loading} />
    </body>
  );
};

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Permission));
