import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import { URLS } from "../urls";
import moment from "moment";
import fileCheck from "../../assets/img/file-check-02.svg";
import caretLeft from "../../assets/img/CaretLeft.svg";
import PerformanceNavTab from "./PerformanceNavTab";
import "../../assets/scss/job.css";
import "@fontsource/open-sauce-sans";
import { useCookie } from "../../utils";

const Performance = (props) => {
  const branch_id = useCookie("branch_id");

  const [state, setState] = useState({
    employeesClone: [],
    employees: [],
    projects: {},
    tasks: {},
    completedTasks: {},
    completionRate: {},
    isSidebarVisible: false, // Sidebar visibility state
  });

  const componentWillMount = ({ branch_id }) => {
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.employees.forEach((employee) => {
          fetchProjectsByEmployeeId(employee._id);
          fetchTasksByEmployeeId(employee._id);
        });
        setState((el) => ({
          ...el,
          employees: result.employees,
          employeesClone: result.employees,
        }));
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      componentWillMount({ branch_id });
    }

    return () => {
      isMounted = false;
    };
  }, [branch_id]);

  const fetchProjectsByEmployeeId = (employeeId) => {
    const token = "dummy token";
    fetch(`${URLS.backendProjects}/getProjectsByEmployeeId/${employeeId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let { projects } = state;
        projects[employeeId] = result.projects.length;
        setState((el) => ({ ...el, projects }));
      });
  };

  const fetchTasksByEmployeeId = (employeeId) => {
    const token = "dummy token";
    fetch(`${URLS.backendTasks}/tasks/${employeeId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let { tasks, completedTasks, completionRate } = state;
        tasks[employeeId] = result.tasks.length;
        const completed = result.tasks.filter(
          (task) => task.status === "COMPLETE"
        );
        let delay = 0;
        if (completed.length) {
          completed.forEach((item) => {
            const diff = moment(item.completeDate, "DD/MM/YYYY").diff(
              moment(item.dueDate, "DD/MM/YYYY"),
              "days"
            );
            if (diff > 0) delay += diff;
          });
        }
        completedTasks[employeeId] = completed.length;
        completionRate[employeeId] = completed.length
          ? (completed.length / (completed.length + delay * 0.1)) * 100
          : 0;
        setState((el) => ({ ...el, tasks, completedTasks, completionRate }));
      });
  };

  const searchFilterName = (e) => {
    e.preventDefault();
    const value = e.target.value.trim().toLowerCase();
    let { employees, employeesClone } = state;

    employees = value
      ? employeesClone.filter((employee) => {
          const name =
            `${employee.personalInfo.name.first} ${employee.personalInfo.name.middle} ${employee.personalInfo.name.last}`.toLowerCase();
          return name.includes(value);
        })
      : employeesClone;

    setState((el) => ({ ...el, employees }));
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <div>
          <div className="top_header  mt-1x bg-white w-100 p-2">
            <Topbar />
            <div className="content container-fluid p-3 px-5">
              <div className="content_header_wrapper d-flex align-items-center justify-content-between">
                <div className="header_wrapper">
                  <div
                    className="d-flex align-items-center justify-content-center p-2 rounded-sm blue-pale"
                    onClick={() => props.history.goBack()}
                  >
                    <img src={caretLeft} alt="Toggle Sidebar" className="" />
                  </div>
                  <div className="d-flex align-items-center justify-content-center p-2 rounded-sm blue-pale-static">
                    <img src={fileCheck} alt="File Check" className="" />
                  </div>
                  <div>
                    <h3 className="other_det_1 pb-0">
                      Performance & Goal Management
                    </h3>
                    <p className="grey-xl pb-0">
                      Employees Performance Details
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {isSidebarVisible && <Sidebar />} */}
        </div>

        {/* Page Wrapper */}
        <div className="performance-body">
          {/* Page Content */}
          <div className="performance-wrapper p-4">
            <div className="peformance-content">
              <div>
                <PerformanceNavTab />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
};

export default Performance;
