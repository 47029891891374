import React, { useEffect, useState } from "react";

import cake from "../../assets/img/Cake.svg";
import envelope from "../../assets/img/Envelope-gray.svg";
import phone from "../../assets/img/Phone-gray.svg";
import calender from "../../assets/img/CalendarBlank.svg";
import { calculateTimeDifference } from "../../utils";
import { URLS } from "../urls";
import { Link } from "react-router-dom/cjs/react-router-dom";

const SideCard = ({ applications, job }) => {
  const [date, setDate] = useState({ day: "", month: "", year: "" });

  useEffect(() => {
    const date = applications?.applyDate?.split("/");
    const day = date[0];
    const month = date[1];
    const year = date[2];

    setDate({
      day,
      month,
      year,
    });
  }, [applications.applyDate]);

  const changeDateFormat = (date) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    date = date.split("-");
    return date[0] + " " + months[parseInt(date[1]) - 1] + " " + date[2];
  };

  return (
    <div className="applicant_details_right rounded border bg-white p-4">
      <div className="d-flex gap-sm align-items-center">
        <img
          src={"/assets/img/profiles/avatar-03.jpg"}
          alt="applicant's"
          className="details_img"
        />
        <div className=" gap-xs flex-column d-flex">
          <h4 className="other_det_1 pb-0">{applications.name ?? "..."}</h4>
          <p className="pb-0 secondary-text">{job.Position ?? "..."}</p>
          <button
            className="shortlisted"
            href="#"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            {applications.status ?? "..."}
          </button>
        </div>
      </div>
      <main className="rounded w-100 d-flex flex-column mt-4 p-3">
        <div className="d-flex w-100 border-bottom justify-content-between align-items-center">
          <p className=" pb-0 other_det_xs mb-2 ">Applied Jobs</p>
          <p className=" pb-0 grey-xs mb-2 ">
            {" "}
            {calculateTimeDifference({
              day: date.day,
              month: date.month,
              year: date.year,
            })}
          </p>
        </div>

        <div className="d-flex w-100 mt-2 justify-content-between align-items-center">
          <p className=" pb-0 other_det_sm ">{job.department ?? "..."}</p>
          <p className=" pb-0 grey-xs  ">
            {job.jobType ?? "..."} .{" "}
            {calculateTimeDifference({
              day: date.day,
              month: date.month,
              year: date.year,
            })}
          </p>
        </div>
      </main>
      <div className="d-flex gap-md flex-column mt-4">
        <div className=" d-flex gap-sm ">
          <div className="">
            <img src={envelope} alt="" className="svg-w-sm" />
          </div>
          <div>
            <p className="pb-0 grey-xl">Email</p>
            <p className="pb-0 other_det_md">{applications.email ?? "..."}</p>
          </div>
        </div>
        <div className=" d-flex gap-sm ">
          <div className="">
            <img src={phone} alt="" className="svg-w-sm" />
          </div>
          <div>
            <p className="pb-0 grey-xl">Phone</p>
            <p className="pb-0 other_det_md">{applications.phone ?? "..."}</p>
          </div>
        </div>
        <div className=" d-flex gap-sm ">
          <div className="">
            <img src={cake} alt="" className="svg-w-sm" />
          </div>
          <div>
            <p className="pb-0 grey-xl">Birth date</p>
            <p className="pb-0 other_det_md">
              {applications.birthDate
                ? changeDateFormat(applications.birthDate)
                : "..."}
            </p>
          </div>
        </div>
        <button
          type="button"
          className="hover-text btn mt-3 btn-outline-primary w-100 gap-sm p-2 rounded d-flex align-items-center justify-content-center"
        >
          <img src={calender} alt="" className="svg-w-sm" />{" "}
          <Link to={`${URLS.ScheduleInterview}`}>
            <p className="pb-0">Schedule Interview</p>{" "}
          </Link>
        </button>
      </div>
    </div>
  );
};

export default SideCard;
