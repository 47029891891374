import React, { useState } from "react";
import eyeIcon from "../../assets/img/eye.svg";
import calenderplus from "../../assets/img/calendar-plus.svg";
import { Close, Edit, UploadFileRounded, UploadRounded } from "@mui/icons-material";
import { Modal } from "reactstrap";
import trash from "../../assets/img/trash.svg";
import plusblue from "../../assets/img/blue-plus.svg";
import user2 from "../../assets/img/user-2.png";
import ProgressBar from "./ProgressBar";

const MenuPopover = () => {
  const [modal, setModal] = useState(false);
  const [modalReview, setModalReview] = useState(false);
  const [Captureminutes, setCaptureminutes] = useState(false);

  const toggleModal = () => setModal(!modal);
  const toggleReviewGoal = () => setModalReview(!modalReview);
  const toggleCaptureMinutes = () => setCaptureminutes(!Captureminutes);
  
  return (
    <div>
      <div className="dots-menu">
        <div className="d-flex gap-md menu-content" onClick={toggleReviewGoal}>
          <img src={eyeIcon} width={16} alt="" />
          <p>Review Goal</p>
        </div>
        <div className="d-flex gap-md menu-content" onClick={toggleModal}>
          <img src={calenderplus} width={16} alt="" />
          <p>Create a Meeting</p>
        </div>
      </div>
      {/* Modal Component */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <div className="tw-modal-sm tw-rounded-lg">
          <div className="tw-modal-header">
            <div className="">
              <h1>New Meeting</h1>
              <p>Create a new meeting</p>
            </div>
            <button className="" onClick={toggleModal}>
              <Close />
            </button>
          </div>
          <div className="tw-modal-sm-body">
            <div className="tw-form-group">
              <label className="tw-form-labels">Meeting Date:</label>
              <input
                className="tw-form border"
                type="date"
                name="meeting-date"
                placeholder="Type your goal name"
                // value={application.email}
                // onChange={this.updateApplicationText.bind(this)}
                required
              />
            </div>
            <div className="tw-form-group w-100 d-flex flex-col gap-md">
              <label htmlFor="topics" className="tw-form-labels mb-2">
                Meeting Topics:
              </label>
              <div className="tw-topics w-100 d-flex flex-col gap-md">
                <div className="d-flex w-100 align-items-center gap-sm justify-content-between">
                  <p>. Review Q3 Goals and Evaluation</p>
                  <button>
                    <img src={trash} width={17} alt="" />
                  </button>
                </div>
                <div className="d-flex w-100 align-items-center gap-md justify-content-between">
                  <p>. Review Q3 Goals and Evaluation</p>
                  <button>
                    <img src={trash} width={17} alt="" />
                  </button>
                </div>
              </div>
              <div className="add-topics mt-2">
                <button className="tw-btn-transparent">
                  <img src={plusblue} width={17} alt="" />
                  <p>Add More Topics</p>
                </button>
              </div>
            </div>
            <div className="tw-form-group">
              <label className="tw-form-labels">Frequency</label>
              <select
                className="tw-form border"
                name="frequency"
                // value={application.email}
                // onChange={this.updateApplicationText.bind(this)}
                required
              >
                <option selected value="Head of marketing">
                  Weekly
                </option>
                <option value="HR Manager">Monthly</option>
                <option value="HR Manager">Every 2 Monthls</option>
                <option value="HR Manager">Every 3 Monthls</option>
                <option value="HR Manager">Every 4 Monthls</option>
                <option value="HR Manager">Every 5 Monthls</option>
                <option value="HR Manager">Every 6 Monthls</option>
                <option value="Marketing employee">Anually</option>
              </select>
            </div>
            <div className="tw-form-group">
              <label className="tw-form-labels">Meeting Notes:</label>
              <textarea
                className="tw-form border"
                name="instructions"
                rows="4"
                style={{ height: 120 }}
                placeholder="Write some notes about the meeting"
                // value={application.email}
                // onChange={this.updateApplicationText.bind(this)}
                required
              ></textarea>
            </div>
            <div className="d-flex w-100 align-items-center gap-md justify-content-between">
              <button onClick={toggleCaptureMinutes}
                className="px-4 py-2 border w-100 d-flex align-items-center gap-md justify-content-center tw-rounded-lg tw-text-primary tw-view-all"
                style={{ fontWeight: 600 }}
              >
                <Edit /> Capture Minutes
              </button>
              <button 
                className="px-4 py-2 border w-100 d-flex align-items-center gap-md justify-content-center tw-rounded-lg tw-text-primary tw-view-all"
                style={{ fontWeight: 600 }}
              > <UploadRounded />
                Upload Minutes
              </button>
            </div>
            <div className="tw-line"></div>
            <button type="submit" className="tw-btn w-100">
              Schedule Meeting
            </button>
          </div>
        </div>
      </Modal>
      {/* Modal Component */}

      {/*Review Goal Modal Component */}
      <Modal isOpen={modalReview} toggle={toggleReviewGoal}>
        <div className="tw-modal-sm tw-rounded-lg">
          <div className="tw-modal-header">
            <div className="">
              <h1>Review Goal</h1>
              <p>Review an existing goal </p>
            </div>
            <button className="" onClick={toggleReviewGoal}>
              <Close />
            </button>
          </div>
          <div className="tw-modal-sm-body">
            <div className="tw-form-group">
              <h2 className="tw-text-md">Reach 70K Revenue in Q3</h2>
              <p className="my-3 tw-text-light">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="tw-form-group">
              <div className="tw-form-label">Goal Assigned for:</div>
              <div className="d-flex align-items-center gap-md">
                <img
                  src={user2}
                  width={42}
                  height={42}
                  style={{ objectFit: "cover" }}
                  alt=""
                />
                <div className="d-flex flex-col">
                  <h2 className="tw-text-md-1">John Doe</h2>
                  <p className="tw-text-lighter">Finance</p>
                </div>
              </div>
            </div>
            <div className="tw-form-group">
              <label className="tw-form-label">Goal Progress</label>
              <ProgressBar />
            </div>
            <div className="tw-form-group">
              <label htmlFor="" className="tw-form-label">
                Notes:
              </label>
              <div className="tw-notes">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat
              </div>
            </div>
            <div className="tw-line"></div>
            <div className="w-100 d-flex align-items-center gap-md">
              <button type="submit" className="tw-btn-outline w-100">
                Edit Goal
              </button>
              <button type="submit" className="tw-btn w-100">
                Approve Goal
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Modal Component */}
      
      {/*Capture minutes Component */}
      <Modal isOpen={Captureminutes} toggle={toggleCaptureMinutes}>
        <div className="tw-modal-sm tw-rounded-lg">
          <div className="tw-modal-header">
            <div className="">
              <h1>Capture Minutes</h1>
              <p>Capture a minute during a minutes</p>
            </div>
            <button className="" onClick={toggleCaptureMinutes}>
              <Close />
            </button>
          </div>
          <div className="tw-modal-sm-body">
            
          <div className="tw-form-group">
              <label className="tw-form-labels">Write minutes</label>
              <textarea
                className="tw-form border"
                name="minute"
                rows="4"
                style={{ height: 120 }}
                placeholder="Write a minute"
                // value={application.email}
                // onChange={this.updateApplicationText.bind(this)}
                required
              ></textarea>
            </div>
           
            <div className="tw-line"></div>
            <div className="w-100 d-flex align-items-center gap-md">
              <button type="submit" className=" tw-btn w-100">
                Save
              </button>
              
            </div>
          </div>
        </div>
      </Modal>
      {/* Modal Component */}
    </div>
  );
};

export default MenuPopover;
