import React, { useEffect, useState } from "react";
import { fetchActionsUtil, useCookie } from "../../utils";
import { URLS } from "../urls";
import ModalLoader from "../ModalLoader";
import currency from "currency.js";
import { Dropdown } from "react-bootstrap";
import moment from "moment";

const AllowanceHistory = ({ eId }) => {
  const token = useCookie("token");

  const [state, setState] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const allowanceData = fetchActionsUtil(
        `${URLS.backendOvertime}/getOvertimeByEmployeeId/${eId}`,
        "GET",
        token
      );

      const [allowanceResult] = await Promise.all([allowanceData]);

      setState(allowanceResult.overtimes);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            {state?.length > 0 ? (
              <table className="table">
                <thead>
                  <tr className="table-dark">
                    <th></th>
                    <th>Overtime</th>
                    <th className="text-center">From</th>
                    <th className="text-center">To</th>
                    <th>Days</th>
                    <th>Job Done</th>
                    <th>Amount</th>
                    <th className="text-center">Status</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {state.map((overtime) => {
                    return (
                      <>
                        <tr className="table-primary">
                          <td></td>
                          <td>{overtime?.allowance?.name}</td>

                          <td></td>
                          <td></td>
                          <td>
                            {overtime.hours > 1
                              ? overtime.hours + " days"
                              : overtime.hours + " day"}
                          </td>
                          <td></td>
                          <td>
                            {currency(overtime?.total, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td className="text-center">
                            {overtime.status === "Paid" ? (
                              <p className="btn btn-white btn-sm btn-rounded">
                                <span className="fa fa-dot-circle-o text-success"></span>{" "}
                                Paid
                              </p>
                            ) : (
                              <Dropdown className="action-label">
                                <Dropdown.Toggle
                                  className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                  variant="btn-white"
                                >
                                  {overtime.status === "Sent for Approval" ? (
                                    <i className="fa fa-dot-circle-o text-purple"></i>
                                  ) : overtime.status === "Pending" ? (
                                    <i className="fa fa-dot-circle-o text-info"></i>
                                  ) : overtime.status === "Approved" ? (
                                    <i className="fa fa-dot-circle-o text-success"></i>
                                  ) : (
                                    <i className="fa fa-dot-circle-o text-danger"></i>
                                  )}
                                  {overtime.status}
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                ></Dropdown.Menu>
                              </Dropdown>
                            )}
                          </td>
                          <td className="text-right"></td>
                        </tr>
                        {overtime?.details?.map((el) => (
                          <tr className="table-light">
                            <td></td>
                            <td>{el?.name}</td>
                            <td>
                              {moment(el?.overtimeDate).format("DD MMM, YYYY")}
                            </td>
                            <td>
                              {el?.overtimeDateTo
                                ? moment(el?.overtimeDateTo).format(
                                    "DD MMM, YYYY"
                                  )
                                : moment(el?.overtimeDate).format(
                                    "DD MMM, YYYY"
                                  )}
                            </td>
                            <td>
                              {el?.days
                                ? el?.days > 1
                                  ? el?.days + " days"
                                  : 1 + " day"
                                : 1 + " day"}
                            </td>
                            <td>{el?.description}</td>
                            <td>
                              {currency(el?.amount, {
                                symbol: "",
                              }).format()}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="d-flex justify-content-center my-4">
                <div>
                  <h2>No allowance yet</h2>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ModalLoader show={isLoading} />
    </div>
  );
};

export default AllowanceHistory;
