import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiDownload } from "@mdi/js";
import { URLS } from "../urls";

const DisplayTable = ({ applications = [], dropDownSelect }) => {
  const [state, setState] = useState([]);

  useEffect(() => {
    setState(applications);
  }, [applications]);

  const changeDateFormat = (date) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    date = date.split("/");
    return date[0] + " " + months[parseInt(date[1]) - 1] + " " + date[2];
  };

  const statusClass = (status) => {
    let stats = "pending";
    if (status === "shortlisted") {
      stats = "shortlisted";
    } else if (status === "rejected") {
      stats = "rejected";
    }
    return stats;
  };

  function downloadFromURL(resume = []) {
    resume.map((el) => {
      const image = el?.unique;
      const url = image ? URLS.backendStatic + "/" + image : "";
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = url.split("/").pop(); // Extract filename from URL
      link.click(); // Trigger the download
      return true;
    });
  }

  const buttonFunction = ({ theAction, el }) => {
    return (
      <button className="dropdown-item" onClick={theAction ? theAction : null}>
        <i className={el?.icon}></i>
        {el?.name}
      </button>
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            responsive
            className="table border rounded table-striped custom-table mb-0 datatable"
          >
            <thead className="table__head w-100">
              <tr>
                <th className="other_det_xl">Name</th>
                {/* <th className="other_det_xl">Role</th> */}
                <th className="other_det_xl">Email</th>
                <th className="other_det_xl">Phone</th>
                <th className="other_det_xl">Application Date</th>
                <th className="other_det_xl">Status</th>
                <th className="other_det_xl">Resume</th>
                <th className="text-right other_det_xl">Actions</th>
              </tr>
            </thead>
            <tbody>
              {state.length > 0
                ? state.map((application, index) => (
                    <tr key={index}>
                      <td className="grey-sm text-nowrap">
                        <div className="d-flex align-items-center">
                          <img
                            src={"/assets/img/profiles/avatar-03.jpg"}
                            alt="pic"
                            className="mr-2 rounded-image"
                          />
                          <div>
                            <Link
                              to={`${URLS.applicantProfile}/${application._id}`}
                              className="d-flex align-items-center"
                            >
                              <h4 className="other_det_xs pb-0">
                                {application.name}
                              </h4>
                            </Link>

                            <p className="pb-0 grey-xs">({application._id})</p>
                          </div>
                        </div>
                      </td>
                      {/* <td className="grey-sm text-nowrap">
                        <p className="pb-0">Ui/UX Designer</p>
                      </td> */}
                      <td className="grey-sm text-nowrap">
                        {application.email}
                      </td>
                      <td className="grey-sm text-nowrap">
                        {application.phone}
                      </td>
                      <td className="grey-sm text-nowrap">
                        {changeDateFormat(application.applyDate)}
                      </td>
                      <td className="text-center">
                        <span
                          className={statusClass(application.status)}
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {application.status === "New"
                            ? "Pending"
                            : application.status}
                        </span>
                      </td>
                      <td>
                        <span
                          className="btn btn-sm btn-primary p-cursor"
                          onClick={() => downloadFromURL(application?.resume)}
                        >
                          <Icon path={mdiDownload} size={1} color="#fff" />
                        </span>
                      </td>
                      <td className="text-right">
                        <div className="dropdown dropdown-action">
                          <span
                            className="action-icon dropdown-toggle p-cursor"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="material-icons">more_vert</i>
                          </span>
                          <div className="dropdown-menu dropdown-menu-right">
                            {dropDownSelect?.map((el) => (
                              <>
                                {el?.action
                                  ? buttonFunction({
                                      theAction: () =>
                                        el?.action({
                                          _id: application._id,
                                          status: el?.status,
                                        }),
                                      el,
                                    })
                                  : buttonFunction({ el })}
                              </>
                            ))}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default DisplayTable;
