import React from "react";
import { URLS } from "../urls";
import { Document, Page } from "react-pdf";
import { calculateTimeDifference } from "../../utils";

const Documents = ({ applications }) => {
  const date = applications?.applyDate.split("/");
  const day = date[0];
  const month = date[1];
  const year = date[2];

  const DisplayDocuments = ({ resume }) => {
    const image = resume?.unique;
    const profileImage = image ? URLS.backendStatic + "/" + image : "";

    return (
      <div className="document_content flex-column d-flex overflow-hidden rounded border">
        <a href={profileImage} target="_blank" rel="noopener noreferrer">
          <Document
            file={profileImage}
            noData={
              <div
                style={{
                  width: "110px",
                  height: "120px",
                  border: "1px solid #bebebe",
                }}
              />
            }
          >
            <Page width="260" height="270" pageNumber={1} />
          </Document>
        </a>
        <div className="document_details border-top d-flex align-items-center p-4 justify-content-center">
          <div className=" w-100 d-flex align-items-center justify-content-between">
            <div>
              <p className=" pb-0 other_det_md">Resume Application</p>
              <p className="grey-xl pb-0">
                {calculateTimeDifference({ day, month, year })}
              </p>
            </div>
            <div>
              <span className="action-icon" aria-expanded="false">
                <i className="material-icons p-cursor">more_vert</i>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="documents px-4">
      <div className="document_wrapper w-100 flex flex-wrap gap-md">
        {applications?.resume?.length > 0 ? (
          applications?.resume?.map((el) => <DisplayDocuments resume={el} />)
        ) : (
          <div>No Document</div>
        )}
      </div>
    </div>
  );
};

export default Documents;
