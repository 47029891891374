import React, { useState } from "react";
import { URLS } from "../urls";
import { Link } from "react-router-dom";
import { EditEmployeeSalaryModal } from "./EditEmployeeSalaryModal";
import { AddTerminationModal } from "../termination/AddTerminationModal";
import { Dropdown } from "react-bootstrap";
import { MessageIcon, PhoneIcon } from "../icons";

const Employee = (props) => {
  const [state, setState] = useState({
    openEditSalary: false,
    openTermination: false,
  });

  const dropsItems = [
    {
      action: () => {
        props.openEdit();
        props.onEdit(_id, userId);
      },
      icon: <i className="fa fa-pencil m-r-5"></i>,
      name: "Edit",
    },
    {
      action: () => {
        props.setOpenDelete(true);
        props.onDelete(_id);
      },
      icon: <i className="fa fa-trash-o m-r-5"></i>,
      name: "Delete",
    },
    {
      action: () => {
        setState((el) => ({ ...el, openEditSalary: true }));
      },
      icon: <i className="fa fa-money m-r-5"></i>,
      name: "Edit Salary",
    },
    {
      action: () => {
        setState((el) => ({ ...el, openTermination: true }));
      },
      icon: <i className="fa fa-minus-circle m-r-5"></i>,
      name: "Terminate",
    },
  ];

  const DropDowns = ({ action, icon, name }) => {
    return (
      <Dropdown.Item
        as="div"
        className="p-cursor"
        onClick={() => {
          action();
        }}
      >
        {icon} {name}
      </Dropdown.Item>
    );
  };

  const {
    Staff_ID,
    jobInfo,
    personalInfo,
    _id,
    userId,
    emergencyInfo,
    hasResigned,
  } = props?.employee;
  const { openEditSalary, openTermination } = state;

  const href = `${URLS.profile}/${_id}`;

  const fullName =
    (personalInfo?.name?.first ?? "") +
    " " +
    (personalInfo?.name?.middle ?? "") +
    " " +
    (personalInfo?.name?.last ?? "");

  const displayImage = emergencyInfo && emergencyInfo.image;

  const profileImage = displayImage
    ? URLS.backendStatic + "/" + displayImage
    : "/assets/img/profiles/avatar-03.jpg";
  return (
    <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3 mb-3">
      <div className="new-employee-card">
        <div className="d-flex justify-content-between align-items-baseline">
          <div className="">
            <Link to={href} className="avatar">
              <img src={profileImage} alt={fullName} />
            </Link>
          </div>

          <div className="d-flex justify-content-between align-items-baseline">
            <div className="">
              <p className="card-active">Active</p>
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="" bsPrefix="">
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu
                popperConfig={{
                  strategy: "fixed",
                }}
                renderOnMount
              >
                {dropsItems?.map((el) => (
                  <DropDowns action={el.action} icon={el.icon} name={el.name} />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <h4 className="user-name m-t-10 mb-0 text-ellipsis">
          <Link className="text-dark" to={href}>
            {fullName}
          </Link>
          {"  "}
          <small className="text-muted small">(#{Staff_ID})</small>
        </h4>
        <div className="text-muted">
          <p>{jobInfo?.grade?.name}</p>
        </div>
        {hasResigned && <div className="text-danger">Resigned</div>}

        <div className="d-flex justify-content-between align-items-baseline my-3">
          <div>
            <p className="text-muted">Department</p>
            <p>{jobInfo?.department?.name}</p>
          </div>
          <div>
            <p className="text-muted">Hired Date</p>
            <p>{jobInfo?.joinDate}</p>
          </div>
        </div>
        <div>
          <div className="d-flex align-items-baseline">
            <p className="mr-3 mb-2">
              <MessageIcon />{" "}
            </p>
            <p>{jobInfo?.email}</p>
          </div>
          <div className="d-flex align-items-baseline">
            <p className="mr-3">
              <PhoneIcon />
            </p>
            <p>{personalInfo?.phone?.home}</p>
          </div>
        </div>
      </div>
      {openEditSalary && (
        <EditEmployeeSalaryModal
          show={openEditSalary}
          handleClose={() =>
            setState((el) => ({ ...el, openEditSalary: false }))
          }
          user_id={_id}
          name={fullName}
          jobgradeid={jobInfo?.grade?._id}
        />
      )}

      {openTermination && (
        <AddTerminationModal
          show={openTermination}
          handleClose={() =>
            setState((el) => ({ ...el, openTermination: false }))
          }
          name={fullName}
          userId={userId}
        />
      )}
    </div>
  );
};

export default Employee;
