/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { URLS } from "../urls";
import { Modal } from "react-bootstrap";
import { fetchActionsUtil } from "../../utils";
import { toast } from "react-toastify";

const DeleteModal = (props) => {
  const { _id, jobTitle } = props.deleteJob;

  const deleteJobConfirm = async () => {
    try {
      const token = "dummy token";

      await fetchActionsUtil(`${URLS.backendJobs}/delete/${_id}`, "GET", token);

      props.fetchData();
      props.setOpenDeleteJob(false);

      toast.success("Deleted Successfully");
    } catch (error) {
      console.log(error);

      toast.error("Error Occurred!!!");
    }
  };

  const deleteJobCancel = () => {
    props.setOpenDeleteJob(false);
  };

  return (
    <Modal
      className="custom-modal"
      show={props.OpenDeleteJob}
      centered
      onHide={() => {
        props.setOpenDeleteJob(false);
      }}
      size="sm"
    >
      <div className="modal-content">
        <div className="modal-body">
          <div className="form-header">
            <h3>Delete {jobTitle}</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div className="modal-btn delete-action">
            <div className="row">
              <div className="col-6">
                <button
                  className="btn btn-primary continue-btn"
                  onClick={deleteJobConfirm}
                >
                  Delete
                </button>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-primary cancel-btn"
                  onClick={deleteJobCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
