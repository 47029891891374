import React from "react";

const Profile = ({ applications }) => {
  return (
    <div>
      <div className="px-4 mb-4">
        <h3 className="pb-0">Personal Information</h3>
      </div>
      <div className="mb-4 px-4">
        <div>
          <p className="pb-0 grey-xl">Full Name</p>
          <p className="pb-0 other_det_md">{applications.name ?? "..."}</p>
        </div>
      </div>
      <div className="mb-4 px-4">
        <div>
          <p className="pb-0 grey-xl">Address</p>
          <p className="pb-0 other_det_md">{applications.Address ?? "..."}</p>
        </div>
      </div>
      <div className="mb-4 px-4">
        <div>
          <p className="pb-0 grey-xl">Gender</p>
          <p className="pb-0 other_det_md">{applications.gender ?? "..."}</p>
        </div>
      </div>
      <div className="px-4 mb-4">
        <div className="border-bottom"></div>
      </div>
      <div className="px-4 mb-4">
        <h3 className="pb-0">Career Information</h3>
      </div>
      <div className="px-4 mb-4">
        <p className="pb-0 other_det_xs">{applications.message ?? "..."}</p>
      </div>
    </div>
  );
};

export default Profile;
