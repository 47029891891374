/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import TopBar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import AddJobModal from "./AddJobModal";
import EditJobModal from "./EditJobModal";
import {
  copyText,
  fetchActionsUtil,
  getDomainOrigin,
  useCookie,
} from "../../utils";
import "../../assets/scss/job.css";
import plus from "../../assets/img/Plus.svg";
import User from "../../assets/img/User.svg";
import JobSideBar from "../jobsidebar/JobSideBar";
import UnderlineExample from "./JobNavTab";
import Table from "react-bootstrap/Table";
import { mdiCalendarBlankOutline, mdiMapMarkerOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { toast } from "react-toastify";
import ModalLoader from "../ModalLoader";
import DeleteModal from "./DeleteModal";
import { VancancyDetails } from "./VancancyDetails";
import BarChart from "./BarChart";
import { RecentApplications } from "./RecentApplications";
import "@fontsource/open-sauce-sans";
import EmailMessage from "./emailMessage";

const Jobs = () => {
  const branch_id = useCookie("branch_id");

  const [OpenAddJob, setOpenAddJob] = useState(false);
  const [OpenEditJob, setOpenEditJob] = useState(false);
  const [OpenDeleteJob, setOpenDeleteJob] = useState(false);

  const [openEmail, setIsOpenEmail] = useState(false);
  const [emailJobId, setEmailJobId] = useState("");

  const [state, setState] = useState({
    isLoading: false,
    jobs: [],
    departments: [],
    editJob: {
      _id: "",
      jobTitle: "",
      department: "",
      location: "",
      vacancies: 0,
      applications: 0,
      views: 0,
      experience: "",
      salaryFrom: "",
      salaryTo: "",
      jobType: "Full Time",
      status: "Open",
      startDate: "",
      expiredDate: "",
      description: "",
      branch_id,
    },
    deleteJob: null,
    searches: {},
    jobType: "all",
    sideBars: [],
    returnStatics: {},
    recentApplications: [],
  });

  const changeDateFormat = (date) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    date = date.split("/");
    return date[0] + " " + months[parseInt(date[1]) - 1] + " " + date[2];
  };
  const updateEditJob = (job) => {
    setState((el) => ({
      ...el,
      editJob: job,
    }));
  };
  const updateDeleteJob = (j) => {
    setState((el) => ({
      ...el,
      deleteJob: { _id: j._id, jobTitle: j.jobTitle },
    }));
  };

  const fetchData = async () => {
    try {
      setState((el) => ({
        ...el,
        isLoading: true,
      }));

      const token = "dummy token";

      const departmentData = fetchActionsUtil(
        `${URLS.backendDepartments}/get/${branch_id}`,
        "GET",
        token
      );

      const jobData = fetchActionsUtil(
        `${URLS.backendJobs}/get/${branch_id}`,
        "GET",
        token
      );

      const sideBarData = await fetchActionsUtil(
        `${URLS.backendJobs}/get-sideBars/${branch_id}`
      );

      const dataStatics = await fetchActionsUtil(
        `${URLS.backendJobs}/get-statics/${branch_id}`
      );

      const recentData = await fetchActionsUtil(
        `${URLS.backendApplications}/get-recent/${branch_id}`
      );

      const [
        departmentResult,
        jobResult,
        sideBarResult,
        returnStatics,
        recentResult,
      ] = await Promise.all([
        departmentData,
        jobData,
        sideBarData,
        dataStatics,
        recentData,
      ]);

      setState((el) => ({
        ...el,
        departments: departmentResult.departments,
        jobs: jobResult.jobs,
        sideBars: sideBarResult.sideBars,
        returnStatics,
        recentApplications: recentResult?.recentApplications,
      }));
    } catch (error) {
      console.log(error);
      toast.error("Fetching Error !!!");
    } finally {
      setState((el) => ({ ...el, isLoading: false }));
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [branch_id]);

  const searchJobs = ({ searches, jobType }) => {
    const token = "dummy token";
    fetch(
      `${URLS.backendJobs}/get/${branch_id}?search=${searches}&jobType=${jobType}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          jobs: result.jobs,
        }));
      });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setTimeout(() => {
        searchJobs({
          searches: JSON.stringify(state.searches),
          jobType: state.jobType,
        });
      }, 1000);
    }

    return () => {
      isMounted = false;
    };
  }, [state.searches, state.jobType]);

  const getSearches = (data) => {
    const states = state.searches;

    let theKey = Object.keys(data);
    let theValue = Object.values(data);

    theKey = theKey[0];
    theValue = theValue[0];

    const select = states[theKey];

    if (select) {
      let newData = {};
      const findIndex = select?.findIndex((el) => el === theValue);
      if (findIndex >= 0) {
        newData = select?.filter((el) => el !== theValue);
        data = { [theKey]: newData };
      } else {
        newData = select?.concat(theValue);
        data = { [theKey]: newData };
      }
    } else {
      data = { [theKey]: [theValue] };
    }
    setState((el) => ({ ...el, searches: { ...states, ...data } }));
  };

  const openJobEmail = ({ _id }) => {
    setIsOpenEmail(true);
    setEmailJobId(_id);
  };

  const changeTable = (jobs) => {
    return jobs?.length
      ? jobs?.map((job) => {
          return (
            <tr>
              <td className="text-nowrap">
                <Link
                  className="grey-sm text-nowrap"
                  to={`${URLS.jobView}/${job._id}`}
                >
                  {job.jobTitle}
                </Link>
              </td>
              <td className="grey-sm">{job.department}</td>
              <td className="text-nowrap">
                <div className=" d-flex align-items-center">
                  <Icon
                    path={mdiCalendarBlankOutline}
                    size={0.7}
                    color="#00000"
                  />
                  <p className=" ml-2 pb-0">
                    {" "}
                    {changeDateFormat(job.startDate)}
                  </p>
                </div>
              </td>
              <td className="text-nowrap grey-sm">
                <div className=" d-flex align-items-center">
                  <Icon
                    path={mdiCalendarBlankOutline}
                    size={0.7}
                    color="#00000"
                  />
                  <p className=" ml-2 pb-0">
                    {" "}
                    {changeDateFormat(job.expiredDate)}
                  </p>
                </div>
              </td>
              <td className="text-nowrap grey-sm">
                <div className="d-flex align-items-center">
                  <Icon path={mdiMapMarkerOutline} size={0.7} color="#00000" />
                  <p className=" pb-0 ml-2"> {job.location}</p>
                </div>
              </td>
              <td className="text-center">
                {job.jobType === "Full Time" ? (
                  <a className="dropdown-item shortlisted" href="#">
                    {" "}
                    Full Time
                  </a>
                ) : job.jobType === "Part Time" ? (
                  <a className="dropdown-item open-active" href="#">
                    {" "}
                    Part Time
                  </a>
                ) : job.jobType === "Internship" ? (
                  <a className="dropdown-item rejected" href="#">
                    {" "}
                    Internship
                  </a>
                ) : job.jobType === "Temporary" ? (
                  <a className="dropdown-item rejected" href="#">
                    Temporary
                  </a>
                ) : job.jobType === "Remote" ? (
                  <a className="dropdown-item rejected" href="#">
                    {" "}
                    Remote
                  </a>
                ) : (
                  <a className="dropdown-item rejected" href="#">
                    {" "}
                    Other
                  </a>
                )}
              </td>
              <td className="text-center grey-sm">
                {job.status === "Open" ? (
                  <a className="dropdown-item open-active" href="#">
                    {" "}
                    Active
                  </a>
                ) : job.status === "Closed" ? (
                  <a className="dropdown-item rejected" href="#">
                    {" "}
                    Closed
                  </a>
                ) : (
                  <a className="dropdown-item cancelled" href="#">
                    {" "}
                    Cancelled
                  </a>
                )}
              </td>
              <td>
                <Link
                  to={`${URLS.jobApplicants}/${job._id}`}
                  className="text-nowrap grey-sm"
                >
                  <div className="d-flex align-items-center">
                    <img src={User} alt="plus-svg" className="reload-svg" />{" "}
                    <p className="ml-2 pb-0"> {job.applications} Candidates</p>
                  </div>
                </Link>
              </td>
              <td className="text-right">
                <div className="dropdown dropdown-action">
                  <span
                    className="action-icon dropdown-toggle p-cursor"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="material-icons">more_vert</i>
                  </span>
                  <div className="dropdown-menu dropdown-menu-right">
                    <span
                      className="dropdown-item p-cursor"
                      onClick={() => {
                        updateEditJob(job);
                        setOpenEditJob(true);
                      }}
                    >
                      <i className="fa fa-pencil m-r-5"></i> Edit
                    </span>
                    <span
                      className="dropdown-item p-cursor"
                      onClick={() => {
                        hideAJob(job._id, "Closed");
                      }}
                    >
                      <i className="fa fa-pencil m-r-5"></i> Close
                    </span>
                    <span
                      className="dropdown-item p-cursor"
                      onClick={() => {
                        updateDeleteJob(job);
                        setOpenDeleteJob(true);
                      }}
                    >
                      <i className="fa fa-trash-o m-r-5"></i> Delete
                    </span>
                    <span
                      className="dropdown-item p-cursor"
                      onClick={() => {
                        copyText({
                          text: `${getDomainOrigin()}/job-view/${job._id}`,
                        });
                      }}
                    >
                      <i className="fa fa-copy m-r-5"></i> Copy Link
                    </span>
                    <span
                      className="dropdown-item p-cursor"
                      onClick={() => {
                        openJobEmail({
                          _id: job._id,
                        });
                      }}
                    >
                      <i className="fa fa-envelope m-r-5"></i> Create Email
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          );
        })
      : "";
  };

  const changeJobType = (data) => {
    setState((el) => ({
      ...el,
      jobType: data,
    }));
  };

  const hideAJob = async (id, status) => {
    const token = "dummy token";
    try {
      setState((el) => ({
        ...el,
        isLoading: true,
      }));

      await fetchActionsUtil(
        `${URLS.backendJobs}/status/${id}`,
        "POST",
        token,
        { status }
      );

      fetchData();
      toast.success("Job Hidden");
    } catch (error) {
      console.log(error);
      toast.error("Error !!!");
    } finally {
      setState((el) => ({
        ...el,
        isLoading: false,
      }));
    }
  };

  const {
    jobs,
    departments,
    editJob,
    searches,
    jobType,
    isLoading,
    sideBars,
    deleteJob,
    returnStatics,
    recentApplications,
  } = state;

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <TopBar />
        <Sidebar />
        <div className="page-wrapper relat">
          <div className="add_vacancy  mt d-flex border bg-white align-items-center justify-content-between">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <div className="d-flex flex-column container ">
                <h3 className="mt-1">Jobs/Vacancies</h3>
                <p className="pb-0 secondary-text">
                  Job Applications and Vacancies
                </p>
              </div>
              <div className="mx-4">
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    copyText({
                      text: `${getDomainOrigin()}/job-list?q=${branch_id}`,
                    });
                  }}
                >
                  <i className="fa fa-copy m-r-5"></i> Copy Link
                </button>
              </div>

              <button
                className="btn btn-primary"
                onClick={() => setOpenAddJob(true)}
              >
                <img src={plus} alt="plus-svg" /> Add Vacancy
              </button>
            </div>
          </div>

          <div className=" w-full container mt-3 ">
            <div className="d-flex layout-gap w-100 flex-row ">
              <div className="d-flex layout-gap flex-col flex-grow-1">
                {returnStatics ? (
                  <VancancyDetails data={returnStatics} />
                ) : null}
                <div className="">
                  <BarChart />
                </div>
              </div>
              <div className="">
                <RecentApplications data={recentApplications} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="container">
          <div className=" d-flex">
            <div className="sidebar__jobs"></div>
          </div>
        </div>
      </div>
      <div className="page-wrapper vacancy_wrapper">
        <div div className="container border rounded vacancy__list bg-white">
          <div className="">
            <div className="content">
              <div className="row border-bottom">
                <div className="col-md-9 col-sm-8">
                  <UnderlineExample
                    changeJobType={changeJobType}
                    type={jobType}
                  />
                </div>
                <div className="col-md-3 col-sm-4 text-right m-b-10"></div>
              </div>

              <div>
                <div className="d-flex mt-4">
                  <div className="w__12">
                    {sideBars.length > 0 ? (
                      <JobSideBar
                        getSearches={getSearches}
                        searches={searches}
                        sideBars={sideBars}
                      />
                    ) : null}
                  </div>
                  <div className="ml-4 ">
                    <div className="rounded">
                      <Table
                        responsive
                        className="table-striped border custom-table mb-0 datatable"
                      >
                        <thead className="table__head w-100">
                          <tr className="w-100 text-nowrap">
                            <th className=" d-flex align-items-center justify-content-between">
                              <p className="pb-0 other_det_xl ">Name</p>
                            </th>
                            <th className="other_det_xl">Department</th>
                            <th className="other_det_xl">Start Date</th>
                            <th className="other_det_xl">Expire Date</th>
                            <th className="other_det_xl">Location</th>
                            <th className="text-center other_det_xl">
                              Job Type
                            </th>
                            <th className="text-center other_det_xl">Status</th>
                            <th className="other_det_xl">Applicants</th>
                            <th className="opacity-0"></th>
                          </tr>
                        </thead>
                        <tbody>{changeTable(jobs)}</tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="notification-popup hide">
            <p>
              <span class="task"></span>
              <span class="notification-text"></span>
            </p>
          </div>
        </div>
      </div>

      <div className="sidebar-overlay" data-reff=""></div>

      <ModalLoader show={isLoading} />

      {OpenAddJob && (
        <AddJobModal
          departments={departments}
          setOpenAddJob={setOpenAddJob}
          OpenAddJob={OpenAddJob}
          fetchData={fetchData}
        />
      )}

      {OpenEditJob && (
        <EditJobModal
          departments={departments}
          editJob={editJob}
          OpenEditJob={OpenEditJob}
          setOpenEditJob={setOpenEditJob}
          fetchData={fetchData}
        />
      )}

      {OpenDeleteJob && (
        <DeleteModal
          setOpenDeleteJob={setOpenDeleteJob}
          OpenDeleteJob={OpenDeleteJob}
          fetchData={fetchData}
          deleteJob={deleteJob}
        />
      )}

      {openEmail && (
        <EmailMessage
          openEmail={openEmail}
          setIsOpenEmail={setIsOpenEmail}
          _id={emailJobId}
        />
      )}
    </body>
  );
};

export default Jobs;
