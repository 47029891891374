import React from "react";
import AddEmployeeHMOModal from "../employees/AddEmployeeHMOModal";
import AddLifeInsuranceModal from "../employees/AddLifeInsuranceModal";
import { Col, Form, Row } from "react-bootstrap";
import { Document, Page } from "react-pdf";

const EmergencyContactModal = ({
  saveEmergencyInfo,
  emergencyInfo,
  updateEmergencyInfoNameFirst,
  updateEmergencyInfoNameLast,
  updateEmergencyInfoNameMiddle,
  updateEmergencyInfoAddressApartment,
  updateEmergencyInfoAddressStreet,
  updateEmergencyInfoAddressCity,
  updateEmergencyInfoAddressState,
  updateEmergencyInfoAddressZip,
  updateEmergencyInfoPhonePrimary,
  updateEmergencyInfoPhoneSecondary,
  updateEmergencyInfoRelation,
  updateEmergencyInfoGuarantorTitle,
  updateEmergencyInfoGuarantorName,
  updateEmergencyInfoGuarantorPhone,
  updateEmergencyInfoGuarantorAddress,
  updateEmergencyInfoRefereeTitle,
  updateEmergencyInfoRefereeName,
  updateEmergencyInfoRefereePhone,
  updateEmergencyInfoRefereeAddress,
  updateEmergencyInfoEmployeeHMO,
  employeeHMOs,
  updateEmergencyInfoLifeInsurance,
  lifeInsurances,
  displayImage,
  emergencyRef,
  updateEmergencyInfoImage,
  displayResume,
  onDocumentLoadSuccess,
  pdfPageNumber,
  resumeRef,
  updateEmergencyInfoResume,
  updateEmployeeHMOsList,
  state,
  changeShowEmployeeHMOState,
  updateLifeInsurancesList,
  changeShowLifeInsuranceState,
}) => {
  return (
    <div
      id="emergency_contact_modal"
      className="modal custom-modal fade"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Emergency Information</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={saveEmergencyInfo}>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Full Name
                </Form.Label>
                <Col sm={10}>
                  <Row>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="First name"
                        value={emergencyInfo?.name?.first}
                        onChange={updateEmergencyInfoNameFirst}
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="Last name"
                        value={emergencyInfo?.name?.last}
                        onChange={updateEmergencyInfoNameLast}
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="Middle Initial"
                        value={emergencyInfo?.name?.middle}
                        onChange={updateEmergencyInfoNameMiddle}
                      />
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Address
                </Form.Label>
                <Col sm={10}>
                  <Form.Row>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="Apartment/Unit #"
                        value={emergencyInfo?.address?.apartment}
                        onChange={updateEmergencyInfoAddressApartment}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={8}>
                      <Form.Control
                        placeholder="Street Address"
                        value={emergencyInfo?.address?.street}
                        onChange={updateEmergencyInfoAddressStreet}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="City"
                        value={emergencyInfo?.address?.city}
                        onChange={updateEmergencyInfoAddressCity}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="State"
                        value={emergencyInfo?.address?.state}
                        onChange={updateEmergencyInfoAddressState}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="Zip code"
                        value={emergencyInfo?.address?.zip}
                        onChange={updateEmergencyInfoAddressZip}
                      />
                    </Form.Group>
                  </Form.Row>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Home Phone
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    value={emergencyInfo?.phone?.primary}
                    onChange={updateEmergencyInfoPhonePrimary}
                  />
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Alternate Phone
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    value={emergencyInfo?.phone?.secondary}
                    onChange={updateEmergencyInfoPhoneSecondary}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Relationship
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    value={emergencyInfo?.relation}
                    onChange={updateEmergencyInfoRelation}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Guarantor
                </Form.Label>
                <Col sm={10}>
                  <Form.Row>
                    <Form.Group as={Col} sm={2}>
                      <Form.Control
                        placeholder="Title"
                        value={emergencyInfo?.guarantor?.title}
                        onChange={updateEmergencyInfoGuarantorTitle}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={5}>
                      <Form.Control
                        placeholder="Guarantor Name"
                        value={emergencyInfo?.guarantor?.name}
                        onChange={updateEmergencyInfoGuarantorName}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={5}>
                      <Form.Control
                        placeholder="Guarantor Phone"
                        value={emergencyInfo?.guarantor?.phone}
                        onChange={updateEmergencyInfoGuarantorPhone}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={12}>
                      <Form.Control
                        placeholder="Guarantor Address"
                        value={emergencyInfo?.guarantor?.address}
                        onChange={updateEmergencyInfoGuarantorAddress}
                      />
                    </Form.Group>
                  </Form.Row>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Referee
                </Form.Label>
                <Col sm={10}>
                  <Form.Row>
                    <Form.Group as={Col} sm={2}>
                      <Form.Control
                        placeholder="Title"
                        value={emergencyInfo?.referee?.title}
                        onChange={updateEmergencyInfoRefereeTitle}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={5}>
                      <Form.Control
                        placeholder="Referee Name"
                        value={emergencyInfo?.referee?.name}
                        onChange={updateEmergencyInfoRefereeName}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={5}>
                      <Form.Control
                        placeholder="Referee Phone"
                        value={emergencyInfo?.referee?.phone}
                        onChange={updateEmergencyInfoRefereePhone}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={12}>
                      <Form.Control
                        placeholder="Referee Address"
                        value={emergencyInfo?.referee?.address}
                        onChange={updateEmergencyInfoRefereeAddress}
                      />
                    </Form.Group>
                  </Form.Row>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Employee HMO
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as="select"
                    value={emergencyInfo?.employeeHMO}
                    onChange={updateEmergencyInfoEmployeeHMO}
                  >
                    <option>Select</option>
                    <option value="add_employeeHMO">Add New</option>
                    <option disabled>__________________</option>
                    {employeeHMOs.map((location) => {
                      return (
                        <option value={location._id}>{location.name}</option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Group Life Insurance
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as="select"
                    value={emergencyInfo?.lifeInsurance}
                    onChange={updateEmergencyInfoLifeInsurance}
                  >
                    <option>Select</option>
                    <option value="add_lifeInsurance">Add New</option>
                    <option disabled>__________________</option>
                    {lifeInsurances.map((lifeInsurance) => {
                      return (
                        <option value={lifeInsurance._id}>
                          {lifeInsurance.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Upload Employee Photo
                </Form.Label>
                <Col sm={10}>
                  <div className="file-upload-div">
                    <div className="file-preview">
                      <img
                        style={{ height: "120px", width: "110px" }}
                        src={displayImage}
                        alt="CV"
                      />
                    </div>

                    <div className="upload-btn-wrapper">
                      <button className="custom-upload-btn">Browse</button>
                      <input
                        type="file"
                        label="Upload File"
                        ref={emergencyRef}
                        onChange={updateEmergencyInfoImage}
                      />
                    </div>
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Upload Employee Resume
                </Form.Label>
                <Col sm={10}>
                  <div className="file-upload-div">
                    <div className="file-preview">
                      <Document
                        file={displayResume}
                        onLoadSuccess={onDocumentLoadSuccess}
                        noData={
                          <div
                            style={{
                              width: "110px",
                              height: "120px",
                              border: "1px solid #bebebe",
                            }}
                          ></div>
                        }
                      >
                        <Page
                          pageNumber={pdfPageNumber}
                          height="120"
                          width="120"
                          className="change-pdf-size"
                        />
                      </Document>
                    </div>

                    <div className="upload-btn-wrapper">
                      <button className="custom-upload-btn">Browse</button>
                      <input
                        type="file"
                        label="Upload File"
                        ref={resumeRef}
                        onChange={updateEmergencyInfoResume}
                      />
                    </div>
                  </div>
                </Col>
              </Form.Group>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  data-toggle="modal"
                  data-target="#emergency_contact_modal"
                >
                  Save
                </button>
              </div>
            </Form>
            <AddEmployeeHMOModal
              updateEmployeeHMOsList={updateEmployeeHMOsList}
              isShow={state.show_add_employeeHMO}
              changeShowEmployeeHMOState={changeShowEmployeeHMOState}
            />
            <AddLifeInsuranceModal
              updateLifeInsurancesList={updateLifeInsurancesList}
              isShow={state.show_add_lifeInsurance}
              changeShowLifeInsuranceState={changeShowLifeInsuranceState}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmergencyContactModal;
