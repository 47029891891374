import React, { useEffect, useState } from "react";
import freelacing from "../../assets/img/Freelance.svg";
import employee from "../../assets/img/Employee.svg";
import internship from "../../assets/img/Internship.svg";

export const VancancyDetails = ({ data }) => {
  const [state, setState] = useState([]);

  useEffect(() => {
    const items = [
      {
        icon: employee,
        quantity: data?.vacancies,
        type: "vacancies",
        desc: "Open Position",
      },
      {
        icon: employee,
        quantity: data?.applications,
        type: "Applicants",
        desc: "Total Applicants",
      },
      {
        icon: freelacing,
        quantity: data?.OfferedEmployee,
        type: "Employees",
        desc: "Offered",
      },
      {
        icon: internship,
        quantity: data?.OnboardingEmployee,
        type: "Employee",
        desc: "Onboarding",
      },
    ];
    setState(items);
  }, [data]);

  const DisplayStatics = ({ icon, quantity, type, desc }) => {
    return (
      <div className="grid_box border">
        <div className="icon_box mb-3">
          <div className="">
            <span>
              <img src={icon} alt="employee" className="" />
            </span>
          </div>
        </div>
        <div className="  flex-col">
          <h3 className="pb-0 other_det_2">
            {quantity} {type}
          </h3>
          <p className="grey font-weight-normal pb-0">{desc}</p>
        </div>
      </div>
    );
  };

  return (
    <div className=" grid_wrapper vacancy_details grid-5">
      {state.length > 0
        ? state.map((el) => (
            <DisplayStatics
              icon={el?.icon}
              quantity={el?.quantity}
              type={el?.type}
              desc={el?.desc}
            />
          ))
        : null}
    </div>
  );
};
