import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, ModalBody } from "reactstrap";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import Select from "react-select";
import ModalLoader from "../ModalLoader";
import { toast } from "react-toastify";
import { URLS } from "../urls";
import { useCookie } from "../../utils";
import Cookies from "universal-cookie";
import moment from "moment";

function NewTimeSheet(props) {
  const branch_id = useCookie("branch_id");
  const date = new Date();
  const [loading, setLoading] = useState(false);
  const [timesheets, setTimesheets] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  //----
  const [showUploadModalToEdit, setShowUploadModalToEdit] = useState(false);
  const [selectedTimesheetToEdit, setSelectedTimesheetToEdit] = useState(null);

  const [timeSheetToDelete, setTimeSheetToDelete] = useState();
  const [payrollDate, setPayrollDate] = useState({
    payrollYear: date.getFullYear(),
  });
  useEffect(() => {
    getTimeSheet();
    return () => {};
  }, []);

  const getTimeSheet = () => {
    setLoading(true);
    const token = "dummy token";
    fetch(
      `${URLS.backendPayroll}/get-timesheets/${branch_id}?payrollYear=${payrollDate.payrollYear}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setTimesheets(result?.timesheets);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast("Unable to get TimeSheet", {
          type: "error",
        });
      });
  };

  const deleteTimeSheet = (data) => {
    setTimeSheetToDelete();
    setLoading(true);
    const token = "dummy token";
    fetch(`${URLS.backendPayroll}/delete-time-sheet`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          toast("Successs", {
            type: "success",
          });
          getTimeSheet();
        } else {
          throw Error();
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast("Unable to delete", {
          type: "error",
        });
      });
  };

  const search = () => {
    getTimeSheet();
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Timesheets</BreadcrumbItem>
                </Breadcrumb>
                {/* <h4 className="page-title">Employee</h4> */}
              </div>
              <div className="col-auto text-right float-right ml-auto m-b-30">
                <button
                  className="btn add-btn"
                  onClick={() => setShowUploadModal(true)}
                >
                  <i className="fa fa-plus"></i>Upload Timesheet
                </button>
              </div>
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}
            <div className="p-3">
              <div className="form-inline ml-3  mb-3">
                <label>Year</label>
                <input
                  type="number"
                  style={{ width: "100px" }}
                  className="form-control mx-2"
                  value={payrollDate.payrollYear}
                  onChange={(e) =>
                    setPayrollDate({
                      ...payrollDate,
                      payrollYear: e.target.value,
                    })
                  }
                />
                <Button onClick={() => search()}>Search</Button>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    {/*<!-- JobGradeHistory Table -->*/}
                    {
                      <table className="table table-striped custom-table mb-0 datatable">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Uploaded By </th>
                            <th>Month - Year </th>
                            <th>File </th>
                            <th>Date Created</th>
                            <th className="text-right">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {timesheets &&
                            timesheets.map((timesheet, index) => {
                              const { employee } = timesheet;
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <h2 className="table-avatar blue-link">
                                      <Link
                                        to={`profile/${employee?._id}`}
                                        className="avatar"
                                      >
                                        <img
                                          alt=""
                                          src="assets/img/profiles/avatar-02.jpg"
                                        />
                                      </Link>
                                      <Link to={`profile/${employee?._id}`}>
                                        {employee?.personalInfo.name.first +
                                          " " +
                                          employee?.personalInfo.name.last}
                                      </Link>
                                    </h2>
                                  </td>

                                  <td>
                                    {timesheet?.payrollMonth} -{" "}
                                    {timesheet?.payrollYear}
                                  </td>

                                  <td>
                                    <a
                                      href={`${URLS.backendPayroll}/timesheets-file/${timesheet?._id}/${timesheet?.filename}`}
                                      target="_blank"
                                      type="download"
                                      title="Download"
                                    >
                                      {timesheet?.filename}
                                    </a>
                                  </td>

                                  <td>
                                    {moment(timesheet?.createdAt).format(
                                      "DD MMM, YYYY"
                                    )}
                                  </td>
                                  <td className="text-right">
                                    <div className="dropdown dropdown-action p-cursor">
                                      <div
                                        className="action-icon dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="material-icons">
                                          more_vert
                                        </i>
                                      </div>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <div
                                          className="dropdown-item"
                                          onClick={() => {
                                            setSelectedTimesheetToEdit({
                                              ...timesheet,
                                            });
                                            setShowUploadModalToEdit(true);
                                          }}
                                        >
                                          <i className="fa fa-pencil m-r-5"></i>{" "}
                                          Edit
                                        </div>
                                        <div
                                          className="dropdown-item"
                                          onClick={() => {
                                            setTimeSheetToDelete(timesheet);
                                          }}
                                        >
                                          <i className="fa fa-trash-o m-r-5"></i>{" "}
                                          Delete
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    }

                    {/*<!-- /JobGradeHistory Table --> */}
                  </div>
                </div>
              </div>
            </div>

            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
      <ModalLoader show={loading} />
      {showUploadModal && (
        <UploadTimeSheetModal
          setShowUploadModal={setShowUploadModal}
          getTimeSheet={getTimeSheet}
        />
      )}
      {showUploadModalToEdit && selectedTimesheetToEdit ? (
        <UploadTimeSheetModal
          selectedTimesheetToEdit={selectedTimesheetToEdit}
          setShowUploadModal={setShowUploadModalToEdit}
          setSelectedTimesheetToEdit={setSelectedTimesheetToEdit}
          getTimeSheet={getTimeSheet}
        />
      ) : null}
      {timeSheetToDelete && (
        <DeleteTimeSheetModal
          setTimeSheetToDelete={setTimeSheetToDelete}
          deleteTimeSheet={deleteTimeSheet}
          timeSheetToDelete={timeSheetToDelete}
        />
      )}
    </body>
  );
}

function UploadTimeSheetModal({
  setShowUploadModal,
  selectedTimesheetToEdit,
  setSelectedTimesheetToEdit,
  getTimeSheet,
}) {
  const cookies = new Cookies();
  const userId = cookies.get("userId");
  const date = new Date();
  const branch_id = useCookie("branch_id");
  const defaultTimeSheet = {
    /* startDay: 1,
    startMonth: "Current Month",
    endDay: 30,
    endMonth: "Current Month", */
  };
  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: selectedTimesheetToEdit
      ? selectedTimesheetToEdit.payrollMonth
      : date.getMonth() + 1,
    payrollYear: selectedTimesheetToEdit
      ? selectedTimesheetToEdit.payrollYear
      : date.getFullYear(),
  });
  const [loading, setLoading] = useState(false);
  /*  const [settings, setTimeSheet] = useState({
    ...defaultTimeSheet,
  }); */

  const uploadTimeSheet = (e) => {
    e.preventDefault(e);
    setLoading(true);
    const formData = new FormData(e.target);
    const token = "dummy token";
    fetch(`${URLS.backendPayroll}/upload-time-sheet`, {
      method: "POST",
      headers: {
        /*   Accept: "application/json",
        "Content-Type": "application/json", */
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          toast("Successs", {
            type: "success",
          });
          if (getTimeSheet) getTimeSheet();
          if (setSelectedTimesheetToEdit) setSelectedTimesheetToEdit();
          setShowUploadModal(false);
        } else {
          throw Error();
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast("Unable to save", {
          type: "error",
        });
      });
  };

  return (
    <>
      <Modal
        className="custom-modal"
        show={true}
        centered
        onHide={() => {
          if (setSelectedTimesheetToEdit) setSelectedTimesheetToEdit();
          setShowUploadModal(false);
        }}
      >
        <Form onSubmit={(e) => uploadTimeSheet(e)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedTimesheetToEdit ? "Edit " : "Upload"} Timesheet
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input name="employeeId" type="hidden" value={userId} />
            <input name="branch_id" type="hidden" value={branch_id} />

            <div className="d-flex justify-content-between my-4">
              <div className="d-flex">
                <div className="form-inline">
                  <label>Month</label>
                  <select
                    className="form-control mx-2"
                    name="payrollMonth"
                    value={payrollDate.payrollMonth}
                    onChange={(e) =>
                      setPayrollDate({
                        payrollMonth: e.target.value,
                        payrollYear: payrollDate.payrollYear,
                      })
                    }
                  >
                    {[...Array(12)].map((e, index) => (
                      <option key={index}>{index + 1}</option>
                    ))}
                  </select>
                </div>
                <div className="form-inline ml-3">
                  <label>Year</label>
                  <input
                    type="number"
                    name="payrollYear"
                    style={{ width: "100px" }}
                    className="form-control mx-2"
                    value={payrollDate.payrollYear}
                    onChange={(e) =>
                      setPayrollDate({
                        payrollMonth: payrollDate.payrollMonth,
                        payrollYear: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            {selectedTimesheetToEdit?.filename && (
              <p>
                <b>Previous File: </b> {selectedTimesheetToEdit?.filename}
              </p>
            )}

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label></Form.Label>
              <Form.Control
                className="custom-file"
                type="file"
                required
                name="timesheet"
                accept=".xlsx"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">Upload</Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ModalLoader show={loading} />
    </>
  );
}

function DeleteTimeSheetModal({
  timeSheetToDelete,
  setTimeSheetToDelete,
  deleteTimeSheet,
}) {
  return (
    <Modal
      centered
      show={true}
      onHide={() => {
        setTimeSheetToDelete();
      }}
      className="custom-modal"
    >
      <Modal.Body>
        <div className="form-header">
          <h3>Delete Timesheet</h3>
          <p>Are you sure want to delete?</p>
        </div>
        <div className="modal-btn delete-action p-cursor">
          <div className="row">
            <div className="col-6">
              <div
                className="btn  continue-btn btn-danger "
                onClick={() => deleteTimeSheet(timeSheetToDelete)}
              >
                Delete
              </div>
            </div>
            <div className="col-6">
              <div
                data-dismiss="modal"
                className="btn btn-primary cancel-btn"
                onClick={() => {
                  setTimeSheetToDelete();
                }}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewTimeSheet));
