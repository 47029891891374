import React, { useState, useEffect } from "react";

const ProgressBar = ({ progressValue }) => {
  const [progress, setProgress] = useState(12); // Tracks current progress (0 to 20)

  useEffect(() => {
    if (progress < progressValue) {
      const timer = setInterval(() => {
        setProgress((prev) => {
          if (prev < progressValue) {
            return prev + 1; // Increment progress by 1
          } else {
            clearInterval(timer); // Clear interval when reaching the value
            return prev;
          }
        });
      }, 200); // Adjust speed as needed
      return () => clearInterval(timer); // Cleanup
    }
  }, [progressValue]);

  // Calculate progress bar width based on 20 being 100% width
  const progressWidth = `${(progress / 20) * 100}%`;

  return (
    <div className="tw-progress-bar d-flex gap-md">
      <div className="progress-wrapper">
        <div className="tw-progress" style={{ width: progressWidth }}></div>
      </div>
      <div className="progress-count d-flex align-items-center gap-md">
        <p className="date-text-md">{progress}</p> / <p className="date-text-md">20</p>
      </div>
    </div>
  );
};

export default ProgressBar;
