import React from "react";
import { Link } from "react-router-dom";

import DisplayImage from "../../assets/img/Display Picture.svg";
import envelope from "../../assets/img/EnvelopeSimple.svg";
import phone from "../../assets/img/Phone.svg";
import { URLS } from "../urls";

const DisplayCard = ({ application, status, dropDownSelect }) => {
  const changeDateFormat = (date) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    date = date.split("/");
    return date[0] + " " + months[parseInt(date[1]) - 1] + " " + date[2];
  };

  return (
    <div className=" chart_containe">
      <div className="Chart_box_pipeline ">
        <div className="pipelne_box mt-3 rounded w-100 border ">
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div>
              <div className="d-flex align-items-center">
                <img src={DisplayImage} alt="pic" className="mr-2" />
                <div>
                  <Link
                    to={`${URLS.applicantProfile}/${application._id}`}
                    className="d-flex align-items-center"
                  >
                    <h4 className="other_det_xs pb-0">{application.name}</h4>
                  </Link>
                  <p className="pb-0 grey-xs">({application._id})</p>
                </div>
              </div>
            </div>
            <div>
              <div className="dropdown dropdown-action">
                <span
                  className="action-icon dropdown-toggle p-cursor"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="material-icons">more_vert</i>
                </span>
                <div className="dropdown-menu dropdown-menu-right">
                  {dropDownSelect?.map((el) => (
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        el?.action
                          ? el?.action({
                              _id: application._id,
                              status: el?.status,
                            })
                          : {}
                      }
                    >
                      <i className={el?.icon}></i>
                      {el?.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div>
            <span
              className={`${status} mt-3`}
              data-toggle="dropdown"
              aria-expanded="false"
            >
              {status}
            </span>
            <div className=" mt-3 d-flex align-items-center justify-content-between w-100">
              {/* <div>
                <p className="pb-0 grey-xs">Department</p>
                <p className="pb-0 secondary-text">Marketing</p>
              </div> */}
              <div>
                <p className="pb-0 grey-xs">Application Date</p>
                <p className="pb-0 secondary-text">
                  {changeDateFormat(application.applyDate)}
                </p>
              </div>
            </div>
            <div className="mt-3 d-flex gap-sm align-items-center">
              <img src={envelope} alt="" />{" "}
              <p className="secondary-text"> {application.email}</p>
            </div>
            <div className="mt-3 gap-sm d-flex align-items-center">
              <img src={phone} alt="" />{" "}
              <p className="secondary-text"> {application.phone}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayCard;
