import React from "react";

const ExperienceModal = () => {
  return (
    <div id="experience_info" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Experience Information</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-scroll">
                <div className="card-box">
                  <h3 className="card-title">
                    Experience Information{" "}
                    <span className="delete-icon p-cursor">
                      <i className="fa fa-trash-o"></i>
                    </span>
                  </h3>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group form-focus">
                        <input
                          type="text"
                          className="form-control floating"
                          value="Digital Development Inc"
                        />
                        <label className="focus-label">Company Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-focus">
                        <input
                          type="text"
                          className="form-control floating"
                          value="United States"
                        />
                        <label className="focus-label">Location</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-focus">
                        <input
                          type="text"
                          className="form-control floating"
                          value="Web Developer"
                        />
                        <label className="focus-label">Job Position</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-focus">
                        <div className="cal-icon">
                          <input
                            type="text"
                            className="form-control floating datetimepicker"
                            value="01/07/2007"
                          />
                        </div>
                        <label className="focus-label">Period From</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-focus">
                        <div className="cal-icon">
                          <input
                            type="text"
                            className="form-control floating datetimepicker"
                            value="08/06/2018"
                          />
                        </div>
                        <label className="focus-label">Period To</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-box">
                  <h3 className="card-title">
                    Experience Informations{" "}
                    <span className="delete-icon p-cursor">
                      <i className="fa fa-trash-o"></i>
                    </span>
                  </h3>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group form-focus">
                        <input
                          type="text"
                          className="form-control floating"
                          value="Digital Devlopment Inc"
                        />
                        <label className="focus-label">Company Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-focus">
                        <input
                          type="text"
                          className="form-control floating"
                          value="United States"
                        />
                        <label className="focus-label">Location</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-focus">
                        <input
                          type="text"
                          className="form-control floating"
                          value="Web Developer"
                        />
                        <label className="focus-label">Job Position</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-focus">
                        <div className="cal-icon">
                          <input
                            type="text"
                            className="form-control floating datetimepicker"
                            value="01/07/2007"
                          />
                        </div>
                        <label className="focus-label">Period From</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-focus">
                        <div className="cal-icon">
                          <input
                            type="text"
                            className="form-control floating datetimepicker"
                            value="08/06/2018"
                          />
                        </div>
                        <label className="focus-label">Period To</label>
                      </div>
                    </div>
                  </div>
                  <div className="add-more">
                    <button className="btn btn-primary">
                      <i className="fa fa-plus-circle"></i> Add More
                    </button>
                  </div>
                </div>
              </div>
              <div className="submit-section">
                <button className="btn btn-primary submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceModal;
