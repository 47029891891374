import React from "react";
import { Modal, ModalHeader, ModalBody, Alert } from "reactstrap";
import { URLS } from "../urls";

const FoundEmployee = ({
  loader,
  foundModal,
  toggleFound,
  selectedJobCategory,
  submitChanges,
  found,
  setJobGradeChange,
  jobCategories,
}) => {
  return (
    <Modal isOpen={foundModal} toggle={toggleFound} centered={true}>
      <ModalHeader toggle={toggleFound}>
        Employees in {selectedJobCategory.name}
      </ModalHeader>
      <ModalBody>
        <Alert color="danger">
          The following employees are attached to {selectedJobCategory.name} job
          grade, change them before you delete
        </Alert>

        <form onSubmit={submitChanges}>
          <div class="table-responsive">
            <table class="table table-striped custom-table mb-0">
              <thead>
                <tr>
                  <th>Employees</th>
                  <th>Job Grade</th>
                </tr>
              </thead>
              <tbody>
                {found
                  ? found.map((employee, index) => {
                      const image = employee.image;
                      return (
                        <tr key={index}>
                          <td>
                            <h2 class="table-avatar">
                              <a
                                href={`${URLS.profile}/${employee._id}`}
                                target="blank"
                                className="avatar"
                              >
                                <img
                                  alt=""
                                  src={
                                    image
                                      ? URLS.backendStatic + "/" + image
                                      : "assets/img/profiles/avatar-09.jpg"
                                  }
                                />
                              </a>
                              <a
                                href={`${URLS.profile}/${employee._id}`}
                                target="blank"
                              >
                                {employee.name.first + " " + employee.name.last}
                              </a>
                            </h2>
                          </td>
                          <td className="text-right">
                            <select
                              className="form-control"
                              key={index}
                              onChange={(e) => setJobGradeChange(e)}
                              name={index}
                              value={found[index].jobGradeId}
                              required
                            >
                              <option value="" selected>
                                {selectedJobCategory.name}
                              </option>
                              {jobCategories?.length &&
                                jobCategories.map((job) => {
                                  if (job._id !== selectedJobCategory._id)
                                    return (
                                      <option value={job._id} key={job._id}>
                                        {job.name}
                                      </option>
                                    );
                                })}
                            </select>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>

            <button className="btn btn-primary" type="submit" disabled={loader}>
              {loader ? "Please wait" : "Save changes"}
            </button>
          </div>
        </form>
      </ModalBody>
      {/*<ModalFooter>
                  <Button color="secondary" onClick={toggleFound}>
                    Close
                  </Button>
                </ModalFooter>
                */}
    </Modal>
  );
};

export default FoundEmployee;
