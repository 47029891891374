import React from "react";

import plus from "../../assets/img/Plus.svg";
import { URLS } from "../urls";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Schedule = () => {
  return (
    <div className="schedule-interview px-4">
      <h4 className=" pb-0 schedule_heading mb-3">Interview List</h4>
      <div className="schedule_box d-flex flex-column gap-md">
        <div className="schedule_item w-100 justify-content-between align-items-center rounded border p-3">
          <div className="schedule_content align-items-center d-flex gap-md">
            <div className="shc_marker pb-0"></div>
            <div>
              <p className="grey-xl pb-0">Sunday 23rd, 07:30 - 08:30</p>
              <p className=" pb-0 other_det_md">1st Interview Session</p>
            </div>
          </div>
          <div>
            <span
              className="action-icon"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons">more_vert</i>
            </span>
          </div>
        </div>
      </div>
      <div className=" mt-4">
        <button className="btn-primary p-2 btn rounded d-flex align-items-center justify-content-center gap-md w-100">
          {" "}
          <img src={plus} alt="plus-svg" className="reload-svg" />{" "}
          <Link to={`${URLS.ScheduleInterview}`}>
            <p className="pb-0">Schedule Interview</p>{" "}
          </Link>
        </button>
      </div>
    </div>
  );
};

export default Schedule;
