import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

const DeductionHeadReport = ({
  deductions,
  activeTab,
  toggle,
  groupName,
  setIsGroup,
}) => {
  return (
    <>
      {deductions.length > 0 && (
        <Nav tabs className="flex-row">
          {deductions
            .filter((el) => !el.groupName)
            .map((deduction, index) => {
              return (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({
                      active: activeTab === deduction.slug,
                    })}
                    onClick={() => {
                      toggle(deduction.slug);
                      setIsGroup(false);
                    }}
                  >
                    <span className="text-capitalize p-cursor">
                      {deduction.name === "company pension"
                        ? "Employee pension contribution"
                        : deduction.name}
                    </span>
                  </NavLink>
                </NavItem>
              );
            })}

          {/*  */}
          {groupName?.map((deduction, index) => {
            return (
              <NavItem key={index}>
                <NavLink
                  className={classnames({
                    active: activeTab === deduction,
                  })}
                  onClick={() => {
                    toggle(deduction);
                    setIsGroup(true);
                  }}
                >
                  <span className="text-capitalize p-cursor">{deduction}</span>
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
      )}
    </>
  );
};

export default DeductionHeadReport;
