import React from "react";
import "../../assets/scss/job.css";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import plus from "../../assets/img/Plus.svg";
import calendersingle from "../../assets/img/Calendar Single.png";
import calender from "../../assets/img/calendar.png";
import { Calendar } from "antd";
import { ConfigProvider } from 'antd';
import Activities from "../dashboard/Activities";


{
  /* <Link to={`${URLS.dashboard}`}>Dashboard</Link> */
}

const ScheduleInterview = () => {
  const onPanelChange = (value, mode) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="">
        <Topbar />
        <Sidebar />
        <div className="page-wrapper">
          <div className="add_vacancy px-3 mt d-flex border bg-white align-items-center justify-content-between">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <div className="d-flex flex-column container ">
                <h3 className="mt-1">My Schedules</h3>
                <p className="pb-0 secondary-text">All schedule and events</p>
              </div>
              <div className="mx-4"></div>

              <a className="btn text-white add-btn">
                <img src={plus} alt="plus-svg" className="reload-svg" /> Add
                Events
              </a>
            </div>
          </div>
          <div className=" pt-5 px-5">
            <div className="d-flex justify-content-between gap-5">
             <Activities />
              <div className="flex-grow-1">
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#2463ae",
                    },
                  }}
                >
                  <Calendar onPanelChange={onPanelChange} />
                  {/* Your application components */}
                </ConfigProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleInterview;
