import React from "react";
import { Table } from "reactstrap";
import scrollup from "../../assets/img/scrollup.svg";
import france from "../../assets/img/flags/fr.png";
import english from "../../assets/img/flags/gb.png";
import dots from "../../assets/img/dots.svg";
import MenuPopover from "./Menu-Popover";

const GoalLibrary = () => {
  const [showmenu , setShowmenu] = React.useState(false);

  function toggleMenu() {
    setShowmenu(!showmenu);
  }
  return (
    <div>
      <Table responsive className="tw-table-striped w-100">
        <thead className="w-100">
          <tr className="w-100">
            <th className="">
              <p>Goal Name</p>
            </th>
            <th className="">
              <p>Language</p>
            </th>
            <th className="">
              <p>Instructions</p>
            </th>
            <th className="">
              <p> Start Date</p>
            </th>
            <th className="">
              <p> End Date</p>
            </th>
          </tr>
        </thead>
        <tbody className="position-relative">
        {showmenu && <MenuPopover />}
          <tr className="my-4">
            <td>Finish HR Ui</td>
            <td className="language-box">
              <img src={france} alt="" />
              <p>French</p>
            </td>
            <td>You have to finish before we close out the year</td>
            <td className="date-text">18 / 11 / 24</td>
            <td className="date-text">28 / 12 / 24</td>
            <td>
              <button onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
          <tr className="my-4">
            <td>Finish Invex Homepage</td>
            <td className="language-box">
              <img src={english} alt="" />
              <p>English</p>
            </td>
            <td>You have to finish before we close out the year</td>
            <td className="date-text">18 / 11 / 24</td>
            <td className="date-text">28 / 12 / 24</td>
            <td>
              <button onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
          <tr className="my-4">
            <td>Finish Invex Homepage</td>
            <td className="language-box">
              <img src={english} alt="" />
              <p>English</p>
            </td>
            <td>You have to finish before we close out the year</td>
            <td className="date-text">18 / 11 / 24</td>
            <td className="date-text">28 / 12 / 24</td>
            <td>
              <button onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
          <tr className="my-4">
            <td>Finish Invex Homepage</td>
            <td className="language-box">
              <img src={france} alt="" />
              <p>English</p>
            </td>
            <td>You have to finish before we close out the year</td>
            <td className="date-text">18 / 11 / 24</td>
            <td className="date-text">28 / 12 / 24</td>
            <td>
              <button onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
          <tr className="my-4">
            <td>Finish Invex Homepage</td>
            <td className="language-box">
              <img src={english} alt="" />
              <p>English</p>
            </td>
            <td>You have to finish before we close out the year</td>
            <td className="date-text">18 / 11 / 24</td>
            <td className="date-text">28 / 12 / 24</td>
            <td>
              <button onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
          <tr className="my-4">
            <td>Finish Invex Homepage</td>
            <td className="language-box">
              <img src={france} alt="" />
              <p>English</p>
            </td>
            <td>You have to finish before we close out the year</td>
            <td className="date-text">18 / 11 / 24</td>
            <td className="date-text">28 / 12 / 24</td>
            <td>
              <button onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
          <tr className="my-4">
            <td>Finish Invex Homepage</td>
            <td className="language-box">
              <img src={france} alt="" />
              <p>English</p>
            </td>
            <td>You have to finish before we close out the year</td>
            <td className="date-text">18 / 11 / 24</td>
            <td className="date-text">28 / 12 / 24</td>
            <td>
              <button onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
          <tr className="my-4">
            <td>Finish Invex Homepage</td>
            <td className="language-box">
              <img src={france} alt="" />
              <p>English</p>
            </td>
            <td>You have to finish before we close out the year</td>
            <td className="date-text">18 / 11 / 24</td>
            <td className="date-text">28 / 12 / 24</td>
            <td>
              <button onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
          <tr className="my-4">
            <td>Finish Invex Homepage</td>
            <td className="language-box">
              <img src={france} alt="" />
              <p>English</p>
            </td>
            <td>You have to finish before we close out the year</td>
            <td className="date-text">18 / 11 / 24</td>
            <td className="date-text">28 / 12 / 24</td>
            <td>
              <button onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default GoalLibrary;
