import React from "react";
import ReactPaginate from "react-paginate";
import { LeftArrowIcon, RightArrowIcon } from "./icons";

const paginationOptions = {
  previousLabel: <LeftArrowIcon />,
  nextLabel: <RightArrowIcon />,
  pageClassName: "page-item",
  pageLinkClassName: "page-link",
  previousClassName: "page-item",
  previousLinkClassName: "page-link",
  nextClassName: "page-item next",
  nextLinkClassName: "page-link",
  breakLabel: "...",
  breakClassName: "page-item border-none",
  breakLinkClassName: "page-link",
  containerClassName: "pagination d-flex",
  activeClassName: "active",
  hrefBuilder: (pageIndex) => {
    let url = new URL(document.location.href);
    let params = new URLSearchParams(url.search);

    params.set("page", pageIndex);
    url.search = params.toString();

    return url.toString();
  },
};

const selectData = [
  { name: "5 rows", value: "5" },
  { name: "10 rows", value: "10" },
  { name: "20 rows", value: "20" },
  { name: "30 rows", value: "30" },
  { name: "50 rows", value: "50" },
  { name: "100 rows", value: "100" },
  { name: "200 rows", value: "200" },
  { name: "300 rows", value: "300" },
  { name: "400 rows", value: "400" },
  { name: "500 rows", value: "500" },
  { name: "700 rows", value: "700" },
  { name: "1000 rows", value: "1000" },
];

const CustomPagination = ({
  limit = 0,
  page = 0,
  startIndex = 0,
  endIndex = 0,
  count = 0,
  dropDownFunction,
  setQueryParams,
}) => {
  return (
    <div className="d-flex justify-content-between px-3 align-items-center pagination">
      {dropDownFunction ? (
        <div className="pagination_left">
          <p className="m-0 p-0">Show</p>
          <select
            value={limit}
            name="limit"
            className="form-control"
            onChange={(e) => dropDownFunction(e.target.value)}
          >
            {selectData.map(({ name, value }) => (
              <option value={value}>{name}</option>
            ))}
          </select>
        </div>
      ) : (
        <div className="text-muted">
          <p className="m-0">
            Showing {startIndex + 1} to {endIndex <= count ? endIndex : count}{" "}
            of {count} entries
          </p>
        </div>
      )}

      <ReactPaginate
        {...paginationOptions}
        pageCount={Math.ceil(count / limit)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={0}
        onPageChange={({ selected }) => {
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          setQueryParams((el) => ({
            ...el,
            page: selected + 1,
          }));
        }}
        forcePage={page - 1}
      />
    </div>
  );
};

export default CustomPagination;
