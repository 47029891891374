import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import AddLeaveModal from "./AddLeaveModal";
import Leave from "./Leave";
import { Form } from "react-bootstrap";
import { LeaveAllowance } from "./AddLeaveAllowance";
import { branch_id } from "../../utils";
import { EditLeaveAllowance } from "./EditLeaveAllowance";
import { toast } from "react-toastify";
import { SalarySetting } from "../create-salary/CreateSalary";
class LeaveSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addButtonText: "Submit",
      editButtonText: "Submit",
      leaves: [],
      rsvLeavesSet: {},
      showMoreAssignees: {},
      showMoreAssigneesAllowance: {},
      editLeaveFields: {},
      employees: [],
      deleteLeave: null,
      newCustomPolicy: {
        leaveId: null,
        name: "",
        days: 0,
        assignees: [],
      },
      editCustomPolicy: {
        leaveId: null,
        name: "",
        days: 0,
        assignees: [],
      },
      deleteCustomPolicy: null,
      employeesSet: {},
      employeesLeft: [],
      employeesRight: [],
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
      departments: [],
      jobGrades: [],
      assigneesGroup: {
        department: "",
        grade: "",
      },
      leaveId: "",
      allowance: [],
      mainEmployes: [],
      slug: "",
      assignees: [],
      leaveName: "",
    };
  }
  componentDidMount() {
    const token = "dummy token";
    fetch(`${URLS.backendLeaves}/get?branch_id=${branch_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.leaves.length) {
          let showMoreAssignees = {},
            editLeaveFields = {},
            rsvLeavesSet = {};
          result.leaves.forEach((element) => {
            editLeaveFields[element._id] = {
              days: false,
              carry: false,
              earned: false,
            };
            rsvLeavesSet[element._id] = JSON.parse(JSON.stringify(element));
            if (element.customPolicies.length) {
              showMoreAssignees[element._id] = {};
              element.customPolicies.forEach((customPolicy) => {
                showMoreAssignees[element._id][customPolicy._id] = false;
              });
            } else {
              showMoreAssignees[element._id] = null;
            }
          });
          console.log(result);
          this.setState({
            rsvLeavesSet: rsvLeavesSet,
            leaves: result.leaves,
            showMoreAssignees: showMoreAssignees,
            editLeaveFields: editLeaveFields,
          });
        }
      });

    fetch(
      `${URLS.backendEmployees}/getCustomPolicyInfo?branch_id=${branch_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.employees.length) {
          let employeesSet = {};
          result.employees.forEach((element) => {
            employeesSet[element._id] = element;
          });
          this.setState({
            employees: result.employees,
            employeesSet: employeesSet,
            employeesLeft: result.employees,
          });
        }
      });

    fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          mainEmployes: result.employees,
        });
      });
    fetch(`${URLS.backendDepartments}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          departments: result.departments,
        });
      });
    fetch(`${URLS.backendJobGrades}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          jobGrades: result.jobGrades,
        });
      });

    fetch(`${URLS.backendAllowance}/leave/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        // const map = result.allowance?.map((el) => ({ [el.leaveId]: el }));
        // console.log(map);
        let showMoreAssigneesAllowance = {};
        if (result?.allowance?.length > 0) {
          result.allowance.forEach((allow) => {
            showMoreAssigneesAllowance[allow._id] = false;
          });
        } else {
          showMoreAssigneesAllowance = null;
        }
        this.setState({
          allowance: result.allowance,
          showMoreAssigneesAllowance,
        });
      });
  }
  updateLeavesList = (leave) => {
    let { leaves, showMoreAssignees, editLeaveFields, rsvLeavesSet } =
      this.state;
    const id = leave && leave._id;
    let isExists = false;
    for (let index = leaves.length - 1; index >= 0; --index) {
      if (leaves[index]._id === id) {
        leaves.splice(index, 1);
        leaves.splice(index, 0, leave);
        rsvLeavesSet[leave._id] = JSON.parse(JSON.stringify(leave));
        isExists = true;
        break;
      }
    }
    if (isExists) {
      this.setState({ leaves: leaves, rsvLeavesSet });
    } else {
      showMoreAssignees[leave._id] = null;
      editLeaveFields[leave._id] = { days: false, carry: false, earned: false };
      rsvLeavesSet[leave._id] = JSON.parse(JSON.stringify(leave));
      this.setState({
        leaves: leaves.concat(leave),
        showMoreAssignees,
        editLeaveFields,
        rsvLeavesSet,
      });
    }
  };
  changeLiveStatus = (leave) => (event) => {
    leave.isActive = !leave.isActive;
    const token = "dummy token";
    fetch(`${URLS.backendLeaves}/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ leave }),
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          let updatedLeave = result.leave;
          updatedLeave.customPolicies = leave.customPolicies;
          this.updateLeavesList(updatedLeave);
        });
      } else {
        res.json().then((responseJson) => {
          console.log(responseJson);
          alert("Error occurred, Check the form again.");
        });
      }
    });
  };

  reloadPage = () => {
    window.location.reload();
  };

  updateDeleteLeave = (leave) => (evt) => {
    this.setState({
      deleteLeave: leave._id,
    });
    window.$("#delete_leave").modal("toggle");
  };
  confirmDeleteLeave = (evt) => {
    const { deleteLeave } = this.state;
    console.log("deleteLeave", deleteLeave);
    const token = "dummy token";
    fetch(`${URLS.backendLeaves}/delete/${deleteLeave}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          window.$("#delete_leave").modal("toggle");
          this.removeLeaveFromList(result.leave);
        });
        if (this.reloadPage) {
          this.reloadPage();
        }
      } else {
        res.json().then((responseJson) => {
          console.log(responseJson);
          alert("Error occurred, Check the form again.");
        });
      }
    });
  };
  removeLeaveFromList(leave) {
    let { leaves, rsvLeavesSet } = this.state;
    const id = leave && leave._id;
    for (let index = leaves.length - 1; index >= 0; --index) {
      if (leaves[index]._id === id) {
        leaves.splice(index, 1);
        delete rsvLeavesSet[leave._id];
        break;
      }
    }
    this.setState({ leaves: leaves, rsvLeavesSet });
  }
  updateCustomPolicySelectedLeave = (leave) => (evt) => {
    const { newCustomPolicy, employees } = this.state;
    newCustomPolicy.leaveId = leave._id;
    this.setState({
      newCustomPolicy,
      employeesLeft: employees,
      employeesRight: [],
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  updateNewCustomPolicyField = (evt) => {
    const { newCustomPolicy } = this.state;
    newCustomPolicy[evt.target.name] = evt.target.value;
    this.setState({
      newCustomPolicy,
    });
  };
  updateCustomPoliciesOfLeave(customPolicy) {
    let { leaves, rsvLeavesSet } = this.state;
    const leavesLength = leaves.length;
    for (let leaveIndex = 0; leaveIndex < leavesLength; ++leaveIndex) {
      let leave = leaves[leaveIndex];
      if (leave._id === customPolicy.leaveId) {
        let customPolicies = leave.customPolicies;
        const customPoliciesLength = customPolicies.length;
        let isExistCustomPolicy = false;
        for (
          let customPoliciesIndex = 0;
          customPoliciesIndex < customPoliciesLength;
          customPoliciesIndex++
        ) {
          let customPolicy_ = customPolicies[customPoliciesIndex];
          if (customPolicy_._id === customPolicy._id) {
            customPolicies.splice(customPoliciesIndex, 1);
            customPolicies.splice(customPoliciesIndex, 0, customPolicy);
            isExistCustomPolicy = true;
            break;
          }
        }
        if (!isExistCustomPolicy) {
          customPolicies.push(customPolicy);
        }
        leave.customPolicies = customPolicies;
        leaves.splice(leaveIndex, 1);
        leaves.splice(leaveIndex, 0, leave);
        rsvLeavesSet[leave._id] = JSON.parse(JSON.stringify(leave));
        break;
      }
    }
    this.setState({
      leaves,
      rsvLeavesSet,
    });
  }
  updateCustomPoliciesOfLeaveOnDelete(customPolicy) {
    let { leaves, rsvLeavesSet } = this.state;
    const leavesLength = leaves.length;
    for (let leaveIndex = 0; leaveIndex < leavesLength; ++leaveIndex) {
      let leave = leaves[leaveIndex];
      if (leave._id === customPolicy.leaveId) {
        let customPolicies = leave.customPolicies;
        const customPoliciesLength = customPolicies.length;
        for (
          let customPoliciesIndex = 0;
          customPoliciesIndex < customPoliciesLength;
          customPoliciesIndex++
        ) {
          let customPolicy_ = customPolicies[customPoliciesIndex];
          if (customPolicy_._id === customPolicy._id) {
            customPolicies.splice(customPoliciesIndex, 1);
            break;
          }
        }
        leave.customPolicies = customPolicies;
        leaves.splice(leaveIndex, 1);
        leaves.splice(leaveIndex, 0, leave);
        rsvLeavesSet[leave._id] = JSON.parse(JSON.stringify(leave));
        break;
      }
    }
    this.setState({
      leaves,
      rsvLeavesSet,
    });
  }
  saveNewCustomPolicy = (evt) => {
    evt.preventDefault();
    this.setState({ addButtonText: "Submitting ..." });
    const token = "dummy token";
    const { newCustomPolicy, employeesRight, assigneesGroup } = this.state;
    newCustomPolicy.assignees = employeesRight;
    newCustomPolicy.department = assigneesGroup.department;
    newCustomPolicy.grade = assigneesGroup.grade;
    fetch(`${URLS.backendLeaveCustomPolicies}/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ customPolicy: newCustomPolicy }),
    })
      .then((res) => res.json())
      .then((result) => {
        let { showMoreAssignees } = this.state;
        showMoreAssignees[result.customPolicy.leaveId] = {};
        showMoreAssignees[result.customPolicy.leaveId][
          result.customPolicy._id
        ] = false;
        this.updateCustomPoliciesOfLeave(result.customPolicy);
        window.$("#add_custom_policy").modal("toggle");
        this.setState({
          addButtonText: "Submit",
          newCustomPolicy: {
            leaveId: null,
            name: "",
            days: 0,
            assignees: [],
            showMoreAssignees,
          },
        });
      });
  };
  updateEditCustomPolicy = (lcp) => (evt) => {
    let { employees } = this.state;
    let employeesLeft = employees.filter(
      (ar) => !lcp.assignees.find((rm) => rm._id === ar._id)
    );
    let employeesRight = lcp.assignees;
    this.setState({
      editCustomPolicy: lcp,
      employeesLeft: employeesLeft,
      employeesRight: employeesRight,
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  updateEditCustomPolicyField = (evt) => {
    const { editCustomPolicy } = this.state;
    editCustomPolicy[evt.target.name] = evt.target.value;
    this.setState({
      editCustomPolicy,
    });
  };
  saveEditCustomPolicy = (evt) => {
    evt.preventDefault();
    this.setState({ editButtonText: "Submitting ..." });
    const token = "dummy token";
    const { editCustomPolicy, employeesRight, assigneesGroup } = this.state;
    editCustomPolicy.assignees = employeesRight;
    editCustomPolicy.department = assigneesGroup.department;
    editCustomPolicy.grade = assigneesGroup.grade;
    fetch(`${URLS.backendLeaveCustomPolicies}/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ customPolicy: editCustomPolicy }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("COCO", result);
        window.$("#edit_custom_policy").modal("toggle");
        this.setState({ editButtonText: "Submit" });
      });
  };
  updateDeleteCustomPolicy = (lcp) => (evt) => {
    this.setState({
      deleteCustomPolicy: lcp._id,
    });
  };
  confirmDeleteCustomPolicy = (evt) => {
    const { deleteCustomPolicy } = this.state;
    const token = "dummy token";
    fetch(`${URLS.backendLeaveCustomPolicies}/delete/${deleteCustomPolicy}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          this.updateCustomPoliciesOfLeaveOnDelete(result.customPolicy);
          window.$("#delete_custom_policy").modal("toggle");
        });

        if (this.reloadPage) {
          this.reloadPage();
        }
      } else {
        res.json().then((responseJson) => {
          console.log(responseJson);
          alert("Error occurred, Check the form again.");
        });
      }
    });
  };
  updateSelectedEmployeesLeft = (evt) => {
    const { employeesSet } = this.state;
    var options = evt.target.options;
    var selectedEmployeesLeft = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        var value = options[i].value;
        var employee = employeesSet[value];
        selectedEmployeesLeft.push(employee);
      }
    }
    this.setState({
      selectedEmployeesLeft: selectedEmployeesLeft,
    });
  };
  updateSelectedEmployeesRight = (evt) => {
    const { employeesSet } = this.state;
    var options = evt.target.options;
    var selectedEmployeesRight = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        var value = options[i].value;
        var employee = employeesSet[value];
        selectedEmployeesRight.push(employee);
      }
    }
    this.setState({
      selectedEmployeesRight: selectedEmployeesRight,
    });
  };
  customleaveShiftToRightAll = (evt) => {
    const { employeesLeft, employeesRight } = this.state;
    this.setState({
      employeesRight: employeesRight.concat(employeesLeft),
      employeesLeft: [],
    });
  };
  shiftLeftSelectedToRight = (evt) => {
    let { employeesLeft, employeesRight, selectedEmployeesLeft } = this.state;
    let employeesLeftTemp = employeesLeft.filter(
      (ar) => !selectedEmployeesLeft.find((rm) => rm._id === ar._id)
    );
    var elements = document.getElementById("customleave_select_from").options;
    for (var i = 0; i < elements.length; i++) {
      elements[i].selected = false;
    }
    this.setState({
      employeesRight: employeesRight.concat(selectedEmployeesLeft),
      employeesLeft: employeesLeftTemp,
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  shiftRightSelectedToLeft = (evt) => {
    let { employeesLeft, employeesRight, selectedEmployeesRight } = this.state;
    let employeesRightTemp = employeesRight.filter(
      (ar) => !selectedEmployeesRight.find((rm) => rm._id === ar._id)
    );
    var elements = document.getElementById("customleave_select_to").options;
    for (var i = 0; i < elements.length; i++) {
      elements[i].selected = false;
    }
    this.setState({
      employeesRight: employeesRightTemp,
      employeesLeft: employeesLeft.concat(selectedEmployeesRight),
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  shiftLeftSelectedToRightEdit = (evt) => {
    let { employeesLeft, employeesRight, selectedEmployeesLeft } = this.state;
    let employeesLeftTemp = employeesLeft.filter(
      (ar) => !selectedEmployeesLeft.find((rm) => rm._id === ar._id)
    );
    var elements = document.getElementById(
      "edit_customleave_select_from"
    ).options;
    for (var i = 0; i < elements.length; i++) {
      elements[i].selected = false;
    }
    this.setState({
      employeesRight: employeesRight.concat(selectedEmployeesLeft),
      employeesLeft: employeesLeftTemp,
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  shiftRightSelectedToLeftEdit = (evt) => {
    let { employeesLeft, employeesRight, selectedEmployeesRight } = this.state;
    let employeesRightTemp = employeesRight.filter(
      (ar) => !selectedEmployeesRight.find((rm) => rm._id === ar._id)
    );
    var elements = document.getElementById(
      "edit_customleave_select_to"
    ).options;
    for (var i = 0; i < elements.length; i++) {
      elements[i].selected = false;
    }
    this.setState({
      employeesRight: employeesRightTemp,
      employeesLeft: employeesLeft.concat(selectedEmployeesRight),
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  customleaveShiftToLeftAll = (evt) => {
    const { employeesRight, employeesLeft } = this.state;
    this.setState({
      employeesLeft: employeesLeft.concat(employeesRight),
      employeesRight: [],
    });
  };
  updateSearchFilterDepartment = (evt) => {
    const { assigneesGroup, employees } = this.state;
    assigneesGroup.department = evt.target.value;
    // assigneesGroup.grade = '';
    let employeesRight = [];
    if (assigneesGroup.department && assigneesGroup.grade) {
      employeesRight = employees.filter(
        (emp) => emp.department === assigneesGroup.department
      );
      employeesRight = employeesRight.filter(
        (emp) => emp.grade === assigneesGroup.grade
      );
    } else if (assigneesGroup.department && !assigneesGroup.grade) {
      employeesRight = employees.filter(
        (emp) => emp.department === assigneesGroup.department
      );
    } else if (!assigneesGroup.department && assigneesGroup.grade) {
      employeesRight = employees.filter(
        (emp) => emp.grade === assigneesGroup.grade
      );
    }
    let employeesLeft = employees.filter(
      (ar) => !employeesRight.find((rm) => rm._id === ar._id)
    );
    this.setState({
      assigneesGroup,
      employeesLeft: employeesLeft,
      employeesRight: employeesRight,
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  updateSearchFilterJobGrade = (evt) => {
    const { assigneesGroup, employees } = this.state;
    assigneesGroup.grade = evt.target.value;
    // assigneesGroup.department = '';
    let employeesRight = [];
    if (assigneesGroup.department && assigneesGroup.grade) {
      employeesRight = employees.filter(
        (emp) => emp.department === assigneesGroup.department
      );
      employeesRight = employeesRight.filter(
        (emp) => emp.grade === assigneesGroup.grade
      );
    } else if (assigneesGroup.department && !assigneesGroup.grade) {
      employeesRight = employees.filter(
        (emp) => emp.department === assigneesGroup.department
      );
    } else if (!assigneesGroup.department && assigneesGroup.grade) {
      employeesRight = employees.filter(
        (emp) => emp.grade === assigneesGroup.grade
      );
    }
    // let  employeesRight = employees.filter(emp => emp.grade===assigneesGroup.grade)
    let employeesLeft = employees.filter(
      (ar) => !employeesRight.find((rm) => rm._id === ar._id)
    );
    this.setState({
      assigneesGroup,
      employeesLeft: employeesLeft,
      employeesRight: employeesRight,
      selectedEmployeesLeft: [],
      selectedEmployeesRight: [],
    });
  };
  showAllAssignees = (leaveId, customPolicyId) => (evt) => {
    const { showMoreAssignees } = this.state;
    showMoreAssignees[leaveId][customPolicyId] = true;
    this.setState({
      showMoreAssignees,
    });
  };
  showLessAssignees = (leaveId, customPolicyId) => (evt) => {
    const { showMoreAssignees } = this.state;
    showMoreAssignees[leaveId][customPolicyId] = false;
    this.setState({
      showMoreAssignees,
    });
  };

  showAllAssigneesAllowance = (allowanceId) => (evt) => {
    const { showMoreAssigneesAllowance } = this.state;
    showMoreAssigneesAllowance[allowanceId] = true;
    this.setState({
      showMoreAssigneesAllowance,
    });
  };
  showLessAssigneesAllowance = (allowanceId) => (evt) => {
    const { showMoreAssigneesAllowance } = this.state;
    showMoreAssigneesAllowance[allowanceId] = false;
    this.setState({
      showMoreAssigneesAllowance,
    });
  };

  usersStructures = (assignee) => {
    return (
      <div
        style={{
          display: "inline-block",
          textAlign: "center",
          marginRight: "10px",
        }}
      >
        <div
          style={{
            display: "inline",
          }}
        >
          <a href="Javascript:void(0);" className="avatar">
            <img
              alt=""
              src={
                assignee?.emergencyInfo?.image
                  ? URLS.backendStatic + "/" + assignee?.emergencyInfo?.image
                  : "assets/img/profiles/avatar-02.jpg"
              }
            />
          </a>
        </div>
        <div>
          <a href="Javascript:void(0);">
            {assignee?.personalInfo?.name?.first +
              " " +
              assignee?.personalInfo?.name?.last}
          </a>
        </div>
      </div>
    );
  };

  editLeaveDays = (leave) => (evt) => {
    const { editLeaveFields } = this.state;
    editLeaveFields[leave._id]["days"] = true;
    this.setState({
      editLeaveFields,
    });
  };
  updateLeaveDays = (leave) => (evt) => {
    const { leaves } = this.state;
    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].days = evt.target.value;
        break;
      }
    }
    this.setState({ leaves });
  };
  cancelSaveLeaveDays = (leave) => (evt) => {
    const { editLeaveFields, leaves, rsvLeavesSet } = this.state;
    editLeaveFields[leave._id]["days"] = false;
    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].days = rsvLeavesSet[leave._id].days;
        break;
      }
    }
    this.setState({ leaves, editLeaveFields });
  };
  saveLeaveDays = (leave, fieldType) => (evt) => {
    const { editLeaveFields } = this.state;
    const token = "dummy token";
    fetch(`${URLS.backendLeaves}/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ leave }),
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          let updatedLeave = result.leave;
          updatedLeave.customPolicies = leave.customPolicies;
          this.updateLeavesList(updatedLeave);
          editLeaveFields[leave._id][fieldType] = false;
          this.setState({ editLeaveFields });
        });
      } else {
        res.json().then((responseJson) => {
          console.log(responseJson);
          alert("Error occurred, Check the form again.");
        });
      }
    });
  };
  editLeaveCarry = (leave) => (evt) => {
    const { editLeaveFields } = this.state;
    editLeaveFields[leave._id]["carry"] = true;
    this.setState({
      editLeaveFields,
    });
  };
  updateLeaveCarryNo = (leave) => (evt) => {
    const { leaves } = this.state;
    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].carry = false;
        leaves[i].carryMax = 0;
        break;
      }
    }
    this.setState({ leaves });
  };
  updateLeaveCarryYes = (leave) => (evt) => {
    const { leaves } = this.state;
    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].carry = true;
        break;
      }
    }
    this.setState({ leaves });
  };
  updateLeaveCarryMax = (leave) => (evt) => {
    const { leaves } = this.state;
    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].carryMax = evt.target.value;
        break;
      }
    }
    this.setState({ leaves });
  };
  cancelSaveLeaveCarry = (leave) => (evt) => {
    const { editLeaveFields, leaves, rsvLeavesSet } = this.state;
    editLeaveFields[leave._id]["carry"] = false;
    for (let i = 0; i < leaves.length; i++) {
      if (leaves[i]._id === leave._id) {
        leaves[i].carry = rsvLeavesSet[leave._id].carry;
        leaves[i].carryMax = rsvLeavesSet[leave._id].carryMax;
        break;
      }
    }
    this.setState({ leaves, editLeaveFields });
  };

  getallowanceUsers = (DeductType, selectedDeducts) => {
    const { mainEmployes } = this.state;
    let users = [];
    if (DeductType === "Job Grade") {
      users = mainEmployes.filter((el) =>
        selectedDeducts.includes(el?.jobInfo?.grade?._id)
      );
    } else if (DeductType === "Employees") {
      users = mainEmployes.filter((el) => selectedDeducts.includes(el?.userId));
    } else if (DeductType === "Contract") {
      users = mainEmployes.filter((el) =>
        selectedDeducts.includes(el?.jobInfo?.contractType)
      );
    } else if (DeductType === "Permanent") {
      users = mainEmployes.filter(
        (el) => !selectedDeducts.includes(el?.jobInfo?.contractType)
      );
    }

    return users;
  };

  deleteAllowance = async () => {
    try {
      const { slug } = this.state;
      const token = "dummy token";
      const res = await fetch(`${URLS.backendAllowance}/delete/${slug}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      const data = await res.json();
      if (!res.ok) {
        window.$("#delete_allowance").modal("toggle");

        throw Error(data);
      } else {
        toast("Settings deleted", {
          type: "success",
        });
        window.$("#delete_allowance").modal("toggle");
        if (this.reloadPage) {
          this.reloadPage();
        }
      }
    } catch (err) {
      console.log(err);
      toast("Unable to get settings, please try again", {
        type: "error",
      });
      window.$("#delete_allowance").modal("toggle");
    }
  };

  render() {
    const {
      assigneesGroup,
      departments,
      jobGrades,
      leaves,
      showMoreAssignees,
      editLeaveFields,
      employeesLeft,
      employeesRight,
      selectedEmployeesLeft,
      selectedEmployeesRight,
      newCustomPolicy,
      addButtonText,
      editCustomPolicy,
      editButtonText,
      leaveId,
      allowance,
      slug,
      showMoreAssigneesAllowance,
    } = this.state;
    // console.log("AS", leaves);
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Leave Settings</BreadcrumbItem>
                  </Breadcrumb>
                  {/* <h4 className="page-title">Leave Settings</h4> */}
                </div>
                <div className="col-auto text-right float-right ml-auto m-b-30">
                  <button
                    className="btn add-btn"
                    data-toggle="modal"
                    data-target="#add_leave"
                  >
                    <i className="fa fa-plus"></i>Add Leave
                  </button>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              <div className="row">
                <div className="col-md-12">
                  {leaves.length
                    ? leaves.map((leave, idx) => {
                        // return <Leave leave={leave}/>
                        return (
                          <>
                            <div
                              className="card-box leave-box"
                              id={"leave_custom" + idx}
                            >
                              <div className="h3 card-title with-switch">
                                {leave.name}
                                {leave.forGender === "FEMALE" ? (
                                  <span class="subtitle">
                                    Assigned to female only
                                  </span>
                                ) : leave.forGender === "MALE" ? (
                                  <span class="subtitle">
                                    Assigned to male only
                                  </span>
                                ) : (
                                  ""
                                )}
                                <div className="onoffswitch">
                                  <input
                                    type="checkbox"
                                    name="onoffswitch"
                                    className="onoffswitch-checkbox"
                                    id={"switch_custom" + idx}
                                    checked={leave.isActive}
                                    onChange={this.changeLiveStatus(leave)}
                                  />
                                  <label
                                    className="onoffswitch-label"
                                    for={"switch_custom" + idx}
                                  >
                                    <span className="onoffswitch-inner"></span>
                                    <span className="onoffswitch-switch"></span>
                                  </label>
                                </div>
                                <button
                                  className="btn btn-danger leave-delete-btn"
                                  type="button"
                                  onClick={this.updateDeleteLeave(leave)}
                                  disabled={leave.isActive ? true : false}
                                >
                                  Delete
                                </button>
                              </div>
                              <div className="leave-item">
                                <div className="leave-row">
                                  <div className="leave-left">
                                    <div className="input-box">
                                      <div className="form-group">
                                        <label>Days</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          disabled={
                                            !editLeaveFields[leave._id]["days"]
                                          }
                                          value={leave.days}
                                          onChange={this.updateLeaveDays(leave)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="leave-right-custom">
                                    <button
                                      hidden={
                                        editLeaveFields[leave._id]["days"]
                                      }
                                      className="leave-edit-btn-custom"
                                      disabled={leave.isActive ? true : false}
                                      onClick={this.editLeaveDays(leave)}
                                    >
                                      Edit
                                    </button>
                                    <button
                                      hidden={
                                        !editLeaveFields[leave._id]["days"]
                                      }
                                      className="btn btn-white "
                                      onClick={this.cancelSaveLeaveDays(leave)}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      hidden={
                                        !editLeaveFields[leave._id]["days"]
                                      }
                                      className="btn btn-primary leave-save-btn"
                                      type="submit"
                                      onClick={this.saveLeaveDays(
                                        leave,
                                        "days"
                                      )}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                                <div className="leave-row">
                                  <div className="leave-left">
                                    <div className="input-box">
                                      <label className="d-block">
                                        Carry forward
                                      </label>
                                      <div className="leave-inline-form">
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="carryForward"
                                            id={"carry_no_" + idx}
                                            value="option1"
                                            disabled={
                                              !editLeaveFields[leave._id][
                                                "carry"
                                              ]
                                            }
                                            checked={!leave.carry}
                                            onChange={this.updateLeaveCarryNo(
                                              leave
                                            )}
                                          />
                                          <label
                                            className="form-check-label"
                                            for={"carry_no_" + idx}
                                          >
                                            No
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="carryForward"
                                            id={"carry_yes_" + idx}
                                            value="option2"
                                            disabled={
                                              !editLeaveFields[leave._id][
                                                "carry"
                                              ]
                                            }
                                            checked={leave.carry}
                                            onChange={this.updateLeaveCarryYes(
                                              leave
                                            )}
                                          />
                                          <label
                                            className="form-check-label"
                                            for={"carry_yes_" + idx}
                                          >
                                            Yes
                                          </label>
                                        </div>
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Max
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            className="form-control"
                                            disabled={
                                              !editLeaveFields[leave._id][
                                                "carry"
                                              ] || !leave.carry
                                            }
                                            value={leave.carryMax}
                                            onChange={this.updateLeaveCarryMax(
                                              leave
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="leave-right-custom">
                                    <button
                                      hidden={
                                        editLeaveFields[leave._id]["carry"]
                                      }
                                      className="leave-edit-btn-custom"
                                      disabled={leave.isActive ? true : false}
                                      onClick={this.editLeaveCarry(leave)}
                                    >
                                      Edit
                                    </button>
                                    <button
                                      hidden={
                                        !editLeaveFields[leave._id]["carry"]
                                      }
                                      className="btn btn-white "
                                      onClick={this.cancelSaveLeaveCarry(leave)}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      hidden={
                                        !editLeaveFields[leave._id]["carry"]
                                      }
                                      className="btn btn-primary leave-save-btn"
                                      type="submit"
                                      onClick={this.saveLeaveDays(
                                        leave,
                                        "carry"
                                      )}
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>

                                <div className="leave-row">
                                  <div className="leave-left">
                                    <div className="input-box">
                                      <label className="d-block">
                                        Earned leave
                                      </label>
                                      <div className="leave-inline-form">
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptions"
                                            id={"inlineRadio1" + idx}
                                            value="option1"
                                            disabled
                                            checked={!leave.earned}
                                          />
                                          <label
                                            className="form-check-label"
                                            for={"inlineRadio1" + idx}
                                          >
                                            No
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptions"
                                            id={"inlineRadio2" + idx}
                                            value="option2"
                                            disabled
                                            checked={leave.earned}
                                          />
                                          <label
                                            className="form-check-label"
                                            for={"inlineRadio2" + idx}
                                          >
                                            Yes
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="leave-right-custom">
                                    <button
                                      className="leave-edit-btn-custom"
                                      disabled={leave.isActive ? true : false}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="custom-policy">
                                <div className="leave-header">
                                  <div className="title">
                                    <b>Custom policy</b>
                                  </div>
                                  <div>
                                    <button
                                      className="btn btn-sm add-btn"
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#add_custom_policy"
                                      onClick={this.updateCustomPolicySelectedLeave(
                                        leave
                                      )}
                                      disabled={leave.isActive ? true : false}
                                    >
                                      <i className="fa fa-plus"></i> Add custom
                                      policy
                                    </button>
                                  </div>
                                </div>
                                <div className="table-responsive">
                                  <table className="table table-hover table-nowrap leave-table">
                                    <thead>
                                      <tr>
                                        <th className="l-name">Name</th>
                                        <th className="l-days">Days</th>
                                        <th className="l-assignee">Assignee</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {leave.customPolicies &&
                                      leave.customPolicies.length
                                        ? leave.customPolicies.map((lcp) => {
                                            return (
                                              <tr>
                                                <td>{lcp.name}</td>
                                                <td>{lcp.days}</td>
                                                {lcp["assignees"] &&
                                                lcp["assignees"].length ? (
                                                  lcp.assignees.length < 3 ? (
                                                    <td>
                                                      {lcp.assignees.map(
                                                        (assignee) => {
                                                          return (
                                                            <div
                                                              style={{
                                                                display:
                                                                  "inline-block",
                                                                textAlign:
                                                                  "center",
                                                                marginRight:
                                                                  "10px",
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "inline",
                                                                }}
                                                              >
                                                                <a
                                                                  href="Javascript:void(0);"
                                                                  className="avatar"
                                                                >
                                                                  <img
                                                                    alt=""
                                                                    src={
                                                                      assignee.image
                                                                        ? URLS.backendStatic +
                                                                          "/" +
                                                                          assignee.image
                                                                        : "assets/img/profiles/avatar-02.jpg"
                                                                    }
                                                                  />
                                                                </a>
                                                              </div>
                                                              <div>
                                                                <a href="Javascript:void(0);">
                                                                  {
                                                                    assignee.name
                                                                  }
                                                                </a>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </td>
                                                  ) : showMoreAssignees[
                                                      leave._id
                                                    ][lcp._id] ? (
                                                    <td
                                                      style={{
                                                        whiteSpace: "normal",
                                                      }}
                                                    >
                                                      {lcp.assignees.map(
                                                        (assignee) => {
                                                          return (
                                                            <div
                                                              style={{
                                                                display:
                                                                  "inline-block",
                                                                textAlign:
                                                                  "center",
                                                                marginRight:
                                                                  "10px",
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "inline",
                                                                }}
                                                              >
                                                                <a
                                                                  href="Javascript:void(0);"
                                                                  className="avatar"
                                                                >
                                                                  <img
                                                                    alt=""
                                                                    src={
                                                                      assignee.image
                                                                        ? URLS.backendStatic +
                                                                          "/" +
                                                                          assignee.image
                                                                        : "assets/img/profiles/avatar-02.jpg"
                                                                    }
                                                                  />
                                                                </a>
                                                              </div>
                                                              <div>
                                                                <a href="Javascript:void(0);">
                                                                  {
                                                                    assignee.name
                                                                  }
                                                                </a>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                      <div
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          textAlign: "center",
                                                          marginRight: "10px",
                                                          verticalAlign:
                                                            "inherit",
                                                        }}
                                                        onClick={this.showLessAssignees(
                                                          leave._id,
                                                          lcp._id
                                                        )}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "inline",
                                                          }}
                                                        >
                                                          <a
                                                            href="Javascript:void(0);"
                                                            className="avatar"
                                                            style={{
                                                              backgroundColor:
                                                                "#eaeaea",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color: "#000",
                                                                fontSize:
                                                                  "11px",
                                                              }}
                                                            >
                                                              Less
                                                            </span>
                                                          </a>
                                                        </div>
                                                        <div>
                                                          {/* <a href="Javascript:void(0);" style={{color:'#00ff00'}}>Less</a> */}
                                                        </div>
                                                      </div>
                                                    </td>
                                                  ) : (
                                                    <td>
                                                      <div
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          textAlign: "center",
                                                          marginRight: "10px",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "inline",
                                                          }}
                                                        >
                                                          <a
                                                            href="Javascript:void(0);"
                                                            className="avatar"
                                                          >
                                                            <img
                                                              alt=""
                                                              src={
                                                                lcp.assignees[0]
                                                                  .image
                                                                  ? URLS.backendStatic +
                                                                    "/" +
                                                                    lcp
                                                                      .assignees[0]
                                                                      .image
                                                                  : "assets/img/profiles/avatar-02.jpg"
                                                              }
                                                            />
                                                          </a>
                                                        </div>
                                                        <div>
                                                          <a href="Javascript:void(0);">
                                                            {
                                                              lcp.assignees[0]
                                                                .name
                                                            }
                                                          </a>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          textAlign: "center",
                                                          marginRight: "10px",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "inline",
                                                          }}
                                                        >
                                                          <a
                                                            href="Javascript:void(0);"
                                                            className="avatar"
                                                          >
                                                            <img
                                                              alt=""
                                                              src={
                                                                lcp.assignees[1]
                                                                  .image
                                                                  ? URLS.backendStatic +
                                                                    "/" +
                                                                    lcp
                                                                      .assignees[1]
                                                                      .image
                                                                  : "assets/img/profiles/avatar-02.jpg"
                                                              }
                                                            />
                                                          </a>
                                                        </div>
                                                        <div>
                                                          <a href="Javascript:void(0);">
                                                            {
                                                              lcp.assignees[1]
                                                                .name
                                                            }
                                                          </a>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          textAlign: "center",
                                                          marginRight: "10px",
                                                          verticalAlign:
                                                            "inherit",
                                                        }}
                                                        onClick={this.showAllAssignees(
                                                          leave._id,
                                                          lcp._id
                                                        )}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "inline",
                                                          }}
                                                        >
                                                          <a
                                                            href="Javascript:void(0);"
                                                            className="avatar"
                                                            style={{
                                                              backgroundColor:
                                                                "#ff5e3a",
                                                            }}
                                                          >
                                                            <span>
                                                              +
                                                              {lcp.assignees
                                                                .length - 2}
                                                            </span>
                                                          </a>
                                                        </div>
                                                        <div>
                                                          {/* <a href="Javascript:void(0);" style={{color:'#ff5e3a'}}>More</a> */}
                                                        </div>
                                                      </div>
                                                    </td>
                                                  )
                                                ) : (
                                                  <td></td>
                                                )}
                                                <td className="text-right">
                                                  <div className="dropdown dropdown-action">
                                                    <a
                                                      aria-expanded="false"
                                                      data-toggle="dropdown"
                                                      className="action-icon dropdown-toggle"
                                                      href="Javascript:void(0);"
                                                    >
                                                      <i className="material-icons">
                                                        more_vert
                                                      </i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                      <a
                                                        href="Javascript:void(0);"
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#edit_custom_policy"
                                                        onClick={this.updateEditCustomPolicy(
                                                          lcp
                                                        )}
                                                      >
                                                        <i className="fa fa-pencil m-r-5"></i>{" "}
                                                        Edit
                                                      </a>
                                                      <a
                                                        href="Javascript:void(0);"
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#add_leave_allowance"
                                                        onClick={() =>
                                                          this.setState({
                                                            leaveId: leave._id,
                                                            assignees:
                                                              lcp.assignees,
                                                            leaveName: lcp.name,
                                                          })
                                                        }
                                                      >
                                                        <i className="fa fa-plus m-r-5"></i>{" "}
                                                        Add Allowance
                                                      </a>

                                                      <a
                                                        href="Javascript:void(0);"
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#delete_custom_policy"
                                                        onClick={this.updateDeleteCustomPolicy(
                                                          lcp
                                                        )}
                                                      >
                                                        <i className="fa fa-trash-o m-r-5"></i>{" "}
                                                        Delete
                                                      </a>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        : ""}
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              {/* // Allowance */}
                              <div className="custom-policy mt-4">
                                <div className="leave-header">
                                  <div className="title">
                                    <b>Leave Allownace</b>
                                  </div>
                                </div>
                                <div className="table-responsive">
                                  <table className="table table-hover table-nowrap leave-table">
                                    <thead>
                                      <tr>
                                        <th className="">Name</th>
                                        <th className="">Payment Type</th>
                                        <th className="l-assignee">Assignee</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {allowance
                                        .filter(
                                          (el) => el?.leaveId === leave?._id
                                        )
                                        .map((al) => {
                                          const users = this.getallowanceUsers(
                                            al["DeductType"],
                                            al["selectedDeducts"]
                                          );

                                          return (
                                            <tr>
                                              <td>{al?.name}</td>
                                              <td>
                                                {al?.paymentType} (
                                                {al?.percentage}){" "}
                                                <SalarySetting
                                                  setting={al}
                                                  placement="top"
                                                />
                                              </td>
                                              {al["DeductType"] === "All" ? (
                                                <td>All Employee</td>
                                              ) : (
                                                <>
                                                  {users.length < 3 ? (
                                                    <td>
                                                      {users?.map(
                                                        (assignee) => {
                                                          return this.usersStructures(
                                                            assignee
                                                          );
                                                        }
                                                      )}
                                                    </td>
                                                  ) : showMoreAssigneesAllowance[
                                                      al?._id
                                                    ] ? (
                                                    <td
                                                      style={{
                                                        whiteSpace: "normal",
                                                      }}
                                                    >
                                                      {users?.map(
                                                        (assignee) => {
                                                          return this.usersStructures(
                                                            assignee
                                                          );
                                                        }
                                                      )}
                                                      <div
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          textAlign: "center",
                                                          marginRight: "10px",
                                                          verticalAlign:
                                                            "inherit",
                                                        }}
                                                        onClick={this.showLessAssigneesAllowance(
                                                          al?._id
                                                        )}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "inline",
                                                          }}
                                                        >
                                                          <a
                                                            href="Javascript:void(0);"
                                                            className="avatar"
                                                            style={{
                                                              backgroundColor:
                                                                "#eaeaea",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color: "#000",
                                                                fontSize:
                                                                  "11px",
                                                              }}
                                                            >
                                                              Less
                                                            </span>
                                                          </a>
                                                        </div>
                                                        <div>
                                                          {/* <a href="Javascript:void(0);" style={{color:'#00ff00'}}>Less</a> */}
                                                        </div>
                                                      </div>
                                                    </td>
                                                  ) : (
                                                    <td>
                                                      <div
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          textAlign: "center",
                                                          marginRight: "10px",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "inline",
                                                          }}
                                                        >
                                                          <a
                                                            href="Javascript:void(0);"
                                                            className="avatar"
                                                          >
                                                            <img
                                                              alt=""
                                                              src={
                                                                users[0]
                                                                  ?.emergencyInfo
                                                                  ?.image
                                                                  ? URLS.backendStatic +
                                                                    "/" +
                                                                    users[0]
                                                                      ?.emergencyInfo
                                                                      ?.image
                                                                  : "assets/img/profiles/avatar-02.jpg"
                                                              }
                                                            />
                                                          </a>
                                                        </div>
                                                        <div>
                                                          <a href="Javascript:void(0);">
                                                            {users[0]
                                                              ?.personalInfo
                                                              ?.name?.first +
                                                              users[0]
                                                                ?.personalInfo
                                                                ?.name?.last}
                                                          </a>
                                                        </div>
                                                      </div>

                                                      <div
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          textAlign: "center",
                                                          marginRight: "10px",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "inline",
                                                          }}
                                                        >
                                                          <a
                                                            href="Javascript:void(0);"
                                                            className="avatar"
                                                          >
                                                            <img
                                                              alt=""
                                                              src={
                                                                users[1]
                                                                  ?.emergencyInfo
                                                                  ?.image
                                                                  ? URLS.backendStatic +
                                                                    "/" +
                                                                    users[1]
                                                                      ?.emergencyInfo
                                                                      ?.image
                                                                  : "assets/img/profiles/avatar-02.jpg"
                                                              }
                                                            />
                                                          </a>
                                                        </div>
                                                        <div>
                                                          <a href="Javascript:void(0);">
                                                            {users[1]
                                                              ?.personalInfo
                                                              ?.name?.first +
                                                              users[1]
                                                                ?.personalInfo
                                                                ?.name?.last}
                                                          </a>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          textAlign: "center",
                                                          marginRight: "10px",
                                                          verticalAlign:
                                                            "inherit",
                                                        }}
                                                        onClick={this.showAllAssigneesAllowance(
                                                          al._id
                                                        )}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "inline",
                                                          }}
                                                        >
                                                          <a
                                                            href="Javascript:void(0);"
                                                            className="avatar"
                                                            style={{
                                                              backgroundColor:
                                                                "#ff5e3a",
                                                            }}
                                                          >
                                                            <span>
                                                              +
                                                              {users.length - 2}
                                                            </span>
                                                          </a>
                                                        </div>
                                                        <div>
                                                          {/* <a href="Javascript:void(0);" style={{color:'#ff5e3a'}}>More</a> */}
                                                        </div>
                                                      </div>
                                                    </td>
                                                  )}
                                                </>
                                              )}
                                              <td className="text-right">
                                                <div className="dropdown dropdown-action">
                                                  <a
                                                    aria-expanded="false"
                                                    data-toggle="dropdown"
                                                    className="action-icon dropdown-toggle"
                                                    href="Javascript:void(0);"
                                                  >
                                                    <i className="material-icons">
                                                      more_vert
                                                    </i>
                                                  </a>
                                                  <div className="dropdown-menu dropdown-menu-right">
                                                    <a
                                                      href="Javascript:void(0);"
                                                      className="dropdown-item"
                                                      data-toggle="modal"
                                                      data-target="#edit_allowance"
                                                      onClick={() =>
                                                        this.setState({
                                                          slug: al?.slug,
                                                        })
                                                      }
                                                    >
                                                      <i className="fa fa-pencil m-r-5"></i>{" "}
                                                      Edit
                                                    </a>
                                                    <a
                                                      href="Javascript:void(0);"
                                                      className="dropdown-item"
                                                      data-toggle="modal"
                                                      data-target="#delete_allowance"
                                                      onClick={() =>
                                                        this.setState({
                                                          slug: al?.slug,
                                                        })
                                                      }
                                                    >
                                                      <i className="fa fa-trash-o m-r-5"></i>{" "}
                                                      Delete
                                                    </a>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}

            {/*<!-- Add Custom Policy Modal -->*/}
            <div
              id="add_custom_policy"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add Custom Policy</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={this.saveNewCustomPolicy}>
                      <div className="form-group">
                        <label>
                          Policy Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          value={newCustomPolicy.name}
                          onChange={this.updateNewCustomPolicyField.bind(this)}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Days <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          name="days"
                          className="form-control"
                          value={newCustomPolicy.days}
                          onChange={this.updateNewCustomPolicyField.bind(this)}
                        />
                      </div>
                      <div className="form-group leave-duallist">
                        <label>Add employee</label>
                        {/*<!-- Search Filter -->*/}
                        <div className="row filter-row">
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group form-focus">
                              <Form.Control
                                as="select"
                                value={assigneesGroup.department}
                                onChange={this.updateSearchFilterDepartment}
                              >
                                <option value="">Select Department</option>
                                {departments.length
                                  ? departments.map((department) => {
                                      return (
                                        <option value={department._id}>
                                          {department.name}
                                        </option>
                                      );
                                    })
                                  : null}
                              </Form.Control>
                              <label className="focus-label">Department</label>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group form-focus">
                              <Form.Control
                                as="select"
                                value={assigneesGroup.grade}
                                onChange={this.updateSearchFilterJobGrade}
                              >
                                <option value="">Select Job Grade</option>
                                {jobGrades.length
                                  ? jobGrades.map((jobGrade) => {
                                      return (
                                        <option value={jobGrade._id}>
                                          {jobGrade.name}
                                        </option>
                                      );
                                    })
                                  : null}
                              </Form.Control>
                              <label className="focus-label">Job Grade</label>
                            </div>
                          </div>
                        </div>
                        {/*<!-- /Search Filter -->*/}

                        <div className="row">
                          <div className="col-lg-5 col-sm-5">
                            <select
                              name="customleave_from"
                              id="customleave_select_from"
                              className="form-control"
                              size="5"
                              multiple="multiple"
                              onChange={this.updateSelectedEmployeesLeft.bind(
                                this
                              )}
                            >
                              {employeesLeft.length
                                ? employeesLeft.map((emp) => {
                                    return (
                                      <option value={emp._id}>
                                        {emp.name}
                                      </option>
                                    );
                                  })
                                : null}
                            </select>
                          </div>
                          <div className="multiselect-controls col-lg-2 col-sm-2">
                            <button
                              type="button"
                              id="customleave_select_rightAll"
                              className="btn btn-block btn-white"
                              onClick={this.customleaveShiftToRightAll.bind(
                                this
                              )}
                              disabled={employeesLeft.length ? false : true}
                            >
                              <i className="fa fa-forward"></i>
                            </button>
                            <button
                              type="button"
                              id="customleave_select_rightSelected"
                              className="btn btn-block btn-white"
                              onClick={this.shiftLeftSelectedToRight.bind(this)}
                              disabled={
                                selectedEmployeesLeft.length ? false : true
                              }
                            >
                              <i className="fa fa-chevron-right"></i>
                            </button>
                            <button
                              type="button"
                              id="customleave_select_leftSelected"
                              className="btn btn-block btn-white"
                              onClick={this.shiftRightSelectedToLeft.bind(this)}
                              disabled={
                                selectedEmployeesRight.length ? false : true
                              }
                            >
                              <i className="fa fa-chevron-left"></i>
                            </button>
                            <button
                              type="button"
                              id="customleave_select_leftAll"
                              className="btn btn-block btn-white"
                              onClick={this.customleaveShiftToLeftAll.bind(
                                this
                              )}
                              disabled={employeesRight.length ? false : true}
                            >
                              <i className="fa fa-backward"></i>
                            </button>
                          </div>
                          <div className="col-lg-5 col-sm-5">
                            <select
                              name="customleave_to"
                              id="customleave_select_to"
                              className="form-control"
                              size="8"
                              multiple="multiple"
                              onChange={this.updateSelectedEmployeesRight.bind(
                                this
                              )}
                            >
                              {employeesRight.length
                                ? employeesRight.map((emp) => {
                                    return (
                                      <option value={emp._id}>
                                        {emp.name}
                                      </option>
                                    );
                                  })
                                : null}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">
                          {addButtonText}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Add Custom Policy Modal -->*/}

            {/*<!-- Edit Custom Policy Modal -->*/}
            <div
              id="edit_custom_policy"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Edit Custom Policy</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={this.saveEditCustomPolicy}>
                      <div className="form-group">
                        <label>
                          Policy Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={editCustomPolicy.name}
                          onChange={this.updateEditCustomPolicyField.bind(this)}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Days <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="days"
                          value={editCustomPolicy.days}
                          onChange={this.updateEditCustomPolicyField.bind(this)}
                        />
                      </div>
                      <div className="form-group leave-duallist">
                        <label>Add employee</label>

                        {/*<!-- Search Filter -->*/}
                        <div className="row filter-row">
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group form-focus">
                              <Form.Control
                                as="select"
                                value={assigneesGroup.department}
                                onChange={this.updateSearchFilterDepartment}
                              >
                                <option value="">Select Department</option>
                                {departments.length
                                  ? departments.map((department) => {
                                      return (
                                        <option value={department._id}>
                                          {department.name}
                                        </option>
                                      );
                                    })
                                  : null}
                              </Form.Control>
                              <label className="focus-label">Department</label>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-4">
                            <div className="form-group form-focus">
                              <Form.Control
                                as="select"
                                value={assigneesGroup.grade}
                                onChange={this.updateSearchFilterJobGrade}
                              >
                                <option value="">Select Job Grade</option>
                                {jobGrades.length
                                  ? jobGrades.map((jobGrade) => {
                                      return (
                                        <option value={jobGrade._id}>
                                          {jobGrade.name}
                                        </option>
                                      );
                                    })
                                  : null}
                              </Form.Control>
                              <label className="focus-label">Job Grade</label>
                            </div>
                          </div>
                        </div>
                        {/*<!-- /Search Filter -->*/}

                        <div className="row">
                          <div className="col-lg-5 col-sm-5">
                            <select
                              name="edit_customleave_from"
                              id="edit_customleave_select_from"
                              className="form-control"
                              size="5"
                              multiple="multiple"
                              onChange={this.updateSelectedEmployeesLeft.bind(
                                this
                              )}
                            >
                              {employeesLeft.length
                                ? employeesLeft.map((emp) => {
                                    return (
                                      <option value={emp._id}>
                                        {emp.name}
                                      </option>
                                    );
                                  })
                                : null}
                            </select>
                          </div>
                          <div className="multiselect-controls col-lg-2 col-sm-2">
                            <button
                              type="button"
                              id="edit_customleave_select_rightAll"
                              className="btn btn-block btn-white"
                              onClick={this.customleaveShiftToRightAll.bind(
                                this
                              )}
                              disabled={employeesLeft.length ? false : true}
                            >
                              <i className="fa fa-forward"></i>
                            </button>
                            <button
                              type="button"
                              id="edit_customleave_select_rightSelected"
                              className="btn btn-block btn-white"
                              onClick={this.shiftLeftSelectedToRightEdit.bind(
                                this
                              )}
                              disabled={
                                selectedEmployeesLeft.length ? false : true
                              }
                            >
                              <i className="fa fa-chevron-right"></i>
                            </button>
                            <button
                              type="button"
                              id="edit_customleave_select_leftSelected"
                              className="btn btn-block btn-white"
                              onClick={this.shiftRightSelectedToLeftEdit.bind(
                                this
                              )}
                              disabled={
                                selectedEmployeesRight.length ? false : true
                              }
                            >
                              <i className="fa fa-chevron-left"></i>
                            </button>
                            <button
                              type="button"
                              id="edit_customleave_select_leftAll"
                              className="btn btn-block btn-white"
                              onClick={this.customleaveShiftToLeftAll.bind(
                                this
                              )}
                              disabled={employeesRight.length ? false : true}
                            >
                              <i className="fa fa-backward"></i>
                            </button>
                          </div>
                          <div className="col-lg-5 col-sm-5">
                            <select
                              name="customleave_to"
                              id="edit_customleave_select_to"
                              className="form-control"
                              size="8"
                              multiple="multiple"
                              onChange={this.updateSelectedEmployeesRight.bind(
                                this
                              )}
                            >
                              {employeesRight.length
                                ? employeesRight.map((emp) => {
                                    return (
                                      <option value={emp._id}>
                                        {emp.name}
                                      </option>
                                    );
                                  })
                                : null}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">
                          {editButtonText}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Edit Custom Policy Modal -->*/}

            {/*<!-- Delete Custom Policy Modal -->*/}
            <div
              className="modal custom-modal fade"
              id="delete_custom_policy"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Custom Policy</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="Javascript:void(0);"
                            className="btn btn-primary continue-btn"
                            onClick={this.confirmDeleteCustomPolicy.bind(this)}
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="Javascript:void(0);"
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Delete Custom Policy Modal -->*/}
            {/*<!-- /Add Leave Modal -->*/}
            <AddLeaveModal
              updateLeavesList={this.updateLeavesList.bind(this)}
              reloadPage={this.reloadPage}
            />
            {/*<!-- /Add Leave Modal -->*/}

            {/*<!-- /Leave Allowance Modal -->*/}
            <LeaveAllowance
              leaveId={leaveId}
              assignees={this.state.assignees}
              leaveName={this.state.leaveName}
              reloadPage={this.reloadPage}
            />
            {/*<!-- /Leave Allowance Modal-->*/}

            <EditLeaveAllowance slug={slug} reloadPage={this.reloadPage} />

            {/*<!-- Delete Leave Modal -->*/}
            <div
              className="modal custom-modal fade"
              id="delete_leave"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Leave</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="Javascript:void(0);"
                            className="btn btn-primary continue-btn"
                            onClick={this.confirmDeleteLeave}
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="Javascript:void(0);"
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                            onClick={this.cancelDeleteLeave}
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Delete Leave Modal -->*/}

            {/* Delete Allowance */}
            <div
              className="modal custom-modal fade"
              id="delete_allowance"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Allowance</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="Javascript:void(0);"
                            className="btn btn-primary continue-btn"
                            onClick={() => this.deleteAllowance()}
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="Javascript:void(0);"
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                            onClick={this.cancelDeleteLeave}
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeaveSettings));
