import React, { Fragment, useState, useEffect } from "react";
import { URLS } from "../urls";
import moment from "moment";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { dateWithNowTime, useCookie } from "../../utils";
import RsDateRangePicker from "../RsDateRangePicker";

export default function ApplyPermissionModal({
  setOpenApplyPermission,
  getPermission,
}) {
  const branch_id = useCookie("branch_id");

  const [state, setState] = useState({
    buttonText: "Submit",
    isWaiting: false,
    userId: "",
    applyDate: moment(new Date()).format("DD/MM/YYYY"),
    staff: "",
    // departments: [],
    users: [],
    comment: "",
    description: "",
    employee: {},
    employees: [],
    startDate: moment(new Date()).format("MM/DD/YYYY"),
    endDate: moment(new Date()).format("MM/DD/YYYY"),
    type: "",
    mode: "",
  });

  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    getDepartments();
    getEmployees();
  }, []);

  const getDepartments = async () => {
    // await waitFor(5000);
    if (process.env.REACT_APP_HAS_INVEX === "true") {
      let response = await fetch(`${URLS.mainSiteBackend}/users/departments`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      });

      if (!response.ok) {
        response = await response.json();

        throw new Error(response.message);
      }

      const { data } = await response.json();

      // setState({
      //   ...state,
      //   departments: ,
      // });
      setDepartments(data?.departments);
    }
  };

  const getEmployees = async () => {
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const emp = result.employees.map((em) => ({
          label: `${
            em.personalInfo.name.first ? em.personalInfo.name.first : ""
          } ${em.personalInfo.name.middle ? em.personalInfo.name.middle : ""} ${
            em.personalInfo.name.last ? em.personalInfo.name.last : ""
          }`,
          value: JSON.stringify({
            id: em._id,
            name: `${
              em.personalInfo.name.first ? em.personalInfo.name.first : ""
            } ${
              em.personalInfo.name.middle ? em.personalInfo.name.middle : ""
            } ${em.personalInfo.name.last ? em.personalInfo.name.last : ""}`,
          }),
        }));
        setState({
          ...state,
          employees: emp,
        });
      });
  };

  const getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${URLS.mainSiteBackend}/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    setState({
      ...state,
      users: data?.users,
    });
  };

  const onChangeInputs = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const filterByDateRange = (event, picker) => {
    setState({
      ...state,
      startDate: picker.startDate.format("MM/DD/YYYY"),
      endDate: picker.endDate.format("MM/DD/YYYY"),
    });
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
        " - " +
        picker.endDate.format("MM/DD/YYYY")
    );
  };

  const clearDateRange = (event, picker) => {
    setState({
      ...state,
      startDate: "",
      endDate: "",
    });

    picker.element.val("");
  };

  const submit = (e) => {
    e.preventDefault();
    setState({ ...state, buttonText: "Submitting ...", isWaiting: true });
    const {
      applyDate,
      description,
      comment,
      staff,
      employee,
      startDate,
      endDate,
      type,
      mode,
    } = state;

    let employeeName = "";
    let employeeId = "";
    if (mode === "Single") {
      if (Object.entries(employee).length < 1) {
        return toast.error("Please select an employee", {
          position: "top-right",
        });
      }

      employeeName = JSON.parse(employee).name;
      employeeId = JSON.parse(employee).id;
    } else {
      employeeName = "All Employee";
      employeeId = "63d915394ed13751846d7a29";
    }

    const requsion = {
      title: `${employeeName}'s Permission Request`,
      description,
      type: "Permission",
      requestto: staff,
      amount: 0,
      status: "Pending",
      regdate: moment(new Date()).format("MM/DD/YYYY"),
      requestby: staff,
      vendor: "",
      comment,
      filledFormData: "",
      loanDetails: JSON.stringify({
        employeeName,
        employeeId,
        startDate,
        endDate,
        type,
      }),
    };

    const formData = new FormData();
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(requsion)) {
      formData.append(key, value);
    }
    // console.log(requsion);
    const token = "dummy token";

    if (process.env.REACT_APP_HAS_INVEX === "true") {
      fetch(`${URLS.mainSiteBackend}/requisition/create`, {
        method: "POST",
        // credentials: "include",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      }).then((res) => {
        if (res.ok) {
          res.json().then((result) => {
            // console.log(result);
            fetch(`${URLS.backendpermmission}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
              },
              body: JSON.stringify({
                applyDate,
                status: "Pending",
                userID: employeeId,
                staff,
                requestid: result?.data?.requisition?.requestid,
                description,
                comment,
                branch_id,
                // endDate: moment(endDate, "MM/DD/YYYY"),
                // startDate: moment(startDate, "MM/DD/YYYY"),
                endDate: dateWithNowTime(endDate, "MM/DD/YYYY"),
                startDate: dateWithNowTime(startDate, "MM/DD/YYYY"),
                type,
                mode,
              }),
            }).then((el) => {
              if (el.ok) {
                el.json().then((response) => {
                  // console.log(response);
                  getPermission({ stDt: "", eDt: "", UId: "" });
                  toast("Permission Sucessfully Applied.", {
                    type: "success",
                    position: "top-right",
                  });
                  setState({
                    buttonText: "Submit",
                    isWaiting: false,
                    staff: "",
                    departments: [],
                    users: [],
                    comment: "",
                    description: "",
                    userId: "",
                    employee: {},
                    employees: [],
                    startDate: "",
                    endDate: "",
                    type: "",
                    mode: "",
                  });

                  setOpenApplyPermission(false);
                });
              } else {
                el.json().then((json) => {
                  console.log(json);

                  toast("Error occurred, Check the form again.", {
                    type: "error",
                    position: "top-right",
                  });
                  setState({
                    ...state,
                    buttonText: "Submit",
                    isWaiting: false,
                  });
                });
              }
            });
          });
        } else {
          res.json().then((responseJson) => {
            console.log(responseJson);
            toast("Error occurred, Check the form again.", {
              type: "error",
              position: "top-right",
            });
            setState({ ...state, buttonText: "Submit", isWaiting: false });
          });
        }
      });
    } else {
      return toast("Error occurred, Connect InvexERP.", {
        type: "error",
        position: "top-right",
      });
    }
    // });
  };

  return (
    <Modal
      centered
      show={true}
      onHide={() => {
        setOpenApplyPermission(false);
      }}
      className="custom-modal"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5>Apply For Permission</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <form onSubmit={submit}>
              <div>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Permission Mode <span className="text-danger">*</span> :
                  </Form.Label>
                  <select
                    className="form-control"
                    value={state?.mode}
                    name="mode"
                    onChange={onChangeInputs}
                  >
                    <option value=""></option>
                    <option value="General">General</option>
                    <option value="Single">Single</option>
                  </select>
                </Form.Group>
              </div>

              <div className="row">
                {state?.mode === "Single" && (
                  <Form.Group
                    className={`mb-3 ${
                      state?.mode === "Single" ? "col-md-6" : ""
                    }`}
                  >
                    <Form.Label>
                      Employee <span className="text-danger">*</span> :
                    </Form.Label>

                    <Select
                      closeMenuOnSelect={true}
                      isSearchable
                      required
                      classNamePrefix="form-control"
                      value={state?.employees?.find(
                        (el) => el.value === state?.employee?.name
                      )}
                      onChange={(selected) => {
                        setState({ ...state, employee: selected.value });
                      }}
                      options={state?.employees}
                    />
                  </Form.Group>
                )}

                <Form.Group
                  className={`mb-3 ${
                    state?.mode === "Single" ? "col-md-6" : "col-12"
                  }`}
                >
                  <Form.Label>
                    Choose Date <span className="text-danger">*</span>
                  </Form.Label>

                  <RsDateRangePicker
                    placement="topEnd"
                    onClean={(event, picker) => clearDateRange(event, picker)}
                    onOk={(event, picker) => filterByDateRange(event, picker)}
                  />
                </Form.Group>
              </div>

              <Form.Group className="mb-3">
                <Form.Label>
                  Description <span className="text-danger">*</span> :
                </Form.Label>
                <label className="border p-0 rounded pb-2 message-area d-block">
                  <Form.Control
                    name="description"
                    value={state?.description}
                    onChange={onChangeInputs}
                    as="textarea"
                    className="w-100 border-0 p-3"
                    placeholder="Enter your detail description"
                    rows={4}
                    required
                  />
                </label>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Permission Type <span className="text-danger">*</span> :
                </Form.Label>
                <select
                  className="form-control"
                  value={state?.type}
                  name="type"
                  onChange={onChangeInputs}
                >
                  <option value=""></option>
                  <option value="NoSignOut">No SignOut</option>
                  <option value="Lateness">Lateness</option>
                  <option value="Absent">Absent</option>
                  <option value="EarlyClosure">Early Closure</option>
                </select>
              </Form.Group>

              <div className="d-flex align-items-center my-4">
                <h4 className="pr-2">Send Request To</h4>{" "}
                <hr className="flex-grow-1 m-0" />
              </div>

              <div className="row gap-3">
                <Form.Group className="col mb-3">
                  <Form.Label>
                    Department <span className="text-danger">*</span> :
                  </Form.Label>
                  <select
                    className="form-control"
                    onChange={(e) => getUsersInDepartments(e.target.value)}
                    required
                  >
                    <option value="">Select Recipient's Department</option>
                    {departments &&
                      departments.map((el, index) => (
                        <option key={index} value={el.Department}>
                          {el.Department}
                        </option>
                      ))}
                  </select>
                </Form.Group>

                {state?.users.length > 0 && (
                  <Form.Group className="col mb-3">
                    <Form.Label>
                      Recipient <span className="text-danger">*</span> :
                    </Form.Label>
                    <select
                      name="staff"
                      className="form-control"
                      value={state?.staff}
                      onChange={onChangeInputs}
                      required
                    >
                      <option value="">Select Recipient </option>
                      {state?.users.map((el, index) => (
                        <Fragment key={index}>
                          <option value={el.Staff_ID}>{el.Name}</option>
                        </Fragment>
                      ))}
                    </select>
                  </Form.Group>
                )}
              </div>

              <Form.Group className="mb-3">
                <Form.Label>Comment :</Form.Label>
                <label className="border p-0 rounded pb-2 message-area d-block">
                  <Form.Control
                    name="comment"
                    value={state?.comment}
                    onChange={onChangeInputs}
                    as="textarea"
                    className="w-100 border-0 p-3"
                    placeholder="Enter a comment"
                    rows={4}
                  />
                </label>
              </Form.Group>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  disabled={state?.isWaiting}
                >
                  {state?.buttonText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
