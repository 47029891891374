import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import { fetchActionsUtil } from "../../utils";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import ModalLoader from "../ModalLoader";

const EmailMessage = ({ openEmail, setIsOpenEmail, _id }) => {
  const [state, setState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const token = "dummy token";

  const fetchMessage = async () => {
    try {
      setIsLoading(true);

      const {
        job: { emailMessage },
      } = await fetchActionsUtil(`${URLS.backendJobs}/${_id}`, "GET", token);

      setState(emailMessage);
    } catch (error) {
      toast.error("Error fetching email");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isEffect = true;

    if (isEffect) {
      fetchMessage();
    }
    return () => {
      isEffect = false;
    };
  }, []);

  const saveEmail = async () => {
    try {
      setIsLoading(true);

      await fetchActionsUtil(`${URLS.backendJobs}/email`, "POST", token, {
        message: state,
        _id,
      });

      toast.success("Email saved successfully");
    } catch (error) {
      toast.error("Error saving email");
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsOpenEmail(false);
    }
  };

  return (
    <div>
      <Modal
        className="custom-modal"
        show={openEmail}
        centered
        onHide={() => setIsOpenEmail(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Job Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*  */}
          <Form.Group className="mb-3 pb-1">
            <Form.Label className="mb-1">
              Create a mass email for candidate of this job
            </Form.Label>
            <ReactQuill
              theme="snow"
              value={state}
              onChange={(text) => {
                setState(text);
              }}
            />
          </Form.Group>
          {/*  */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            onClick={saveEmail}
            variant="primary"
            size="lg"
            className="text-white px-3 "
          >
            Save Email
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalLoader show={isLoading} />
    </div>
  );
};

export default EmailMessage;
