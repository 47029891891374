import React from "react";
import { Modal } from "react-bootstrap";

const DeleteEmployee = ({ handleDeleteEmployee, show, handleClose }) => {
  return (
    <Modal show={show} onHide={() => handleClose(false)} centered>
      <Modal.Body className="text-center">
        <div className="form-header">
          <h3>Delete Employee</h3>
          <p>Are you sure want to delete?</p>
        </div>
        <div className="modal-btn delete-action">
          <div className="d-flex justify-content-center align-items-baseline">
            <div className="mx-4">
              <button className="btn btn-danger" onClick={handleDeleteEmployee}>
                Delete
              </button>
            </div>
            <div className="mx-4">
              <button
                data-dismiss="modal"
                className="btn btn-secondary"
                onClick={() => handleClose(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteEmployee;
