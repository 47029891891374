import React from "react";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";

const DisplayStatics = ({ icon, job, jobName, name, typeName }) => {
  const percent = (job[jobName] / job["vacancies"]) * 100;

  return (
    <div className="Chart_box job-chat border rounded d-flex align-items-center justify-content-center bg-white">
      <div className="Chart_box_wrapper">
        <div className="d-flex align-items-center">
          <img src={icon} alt="" />

          <div className=" ml-3">
            <h3 className="other_det_2 pb-0">
              {job[jobName]} {typeName}
            </h3>
            <p className="gray_color pb-0">{name}</p>
          </div>
        </div>
        <Progress
          type="circle"
          percent={Math.round(percent)}
          width={68}
          strokeWidth={14}
          status="default"
          theme={{
            default: {
              trailColor: "#E2E8F0",
              color: "#2463AE",
            },
          }}
          style={{ fontSize: "12px", fontWeight: "900", color: "#0F172A" }}
        />
      </div>
    </div>
  );
};

export default DisplayStatics;
