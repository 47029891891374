import React, { useEffect, useState } from "react";
import moment from "moment";
import { getQueryParams } from "../../utils";
import { Helmet } from "react-helmet";
import TopbarGuest from "../topbar/TopbarGuest";
import { URLS } from "../urls";
import "../../assets/scss/job.css";

import {
  mdiMagnify,
  mdiChevronDown,
  mdiViewGrid,
  mdiViewList,
  mdiClockOutline,
  mdiBriefcaseOutline,
  mdiMapMarkerOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import currency from "currency.js";
import ModalLoader from "../ModalLoader";
import JobSideBar from "../jobsidebar/JobSideBar";
import { toast } from "react-toastify";

const JobList = (props) => {
  const [activeState, setActiveState] = useState("viewGrid");

  const [state, setState] = useState({
    jobs: [],
    isLoading: false,
    ErrorMessage: null,
    searches: {},
    branchID: null,
    jobTitle: "",
  });

  const componentDidMount = () => {
    const { q } = getQueryParams();

    const token = "dummy token";

    if (q) {
      setState((el) => ({ ...el, isLoading: true, ErrorMessage: null }));
      fetch(`${URLS.backendJobs}/get/${q}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setState((el) => ({
            ...el,
            isLoading: false,
            jobs: result.jobs,
            branchID: q,
          }));
        })
        .catch((err) => {
          setState((el) => ({
            ...el,
            isLoading: false,
            jobs: [],
            ErrorMessage: "Error with the URL",
          }));
        });
    } else {
      setState((el) => ({
        ...el,
        jobs: [],
        ErrorMessage: "Error with the URL",
      }));
    }
  };

  const searchJobs = () => {
    const token = "dummy token";
    fetch(
      `${URLS.backendJobs}/get/${state.branchID}?jobTitle=${state.jobTitle}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setState((el) => ({
          ...el,
          jobs: result.jobs,
        }));
      });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (state.jobTitle) {
        setTimeout(() => {
          searchJobs();
        }, 1000);
      } else {
        componentDidMount();
      }
    }

    return () => {
      isMounted = false;
    };
  }, [state.jobTitle]);

  const getDateDiff = (date) => {
    let postedDate = moment(date, "DD/MM/YYYY");
    let today = moment(new Date(), "DD/MM/YYYY");
    let days = today.diff(postedDate, "days");
    if (days === 0) return "Today";
    else if (days === 1) return "Yesterday";
    else if (days < 7) return days + " days ago";
    else if (days < 14) return "One week ago";
    else if (days < 30) return parseInt(days / 7) + " weeks ago";
    else if (days < 60) return "One month ago";
    else return parseInt(days / 30) + " months ago";
  };

  const toggleActiveState = () => {
    setActiveState(activeState === "viewList" ? "viewGrid" : "viewList");
  };

  const getSearches = (data) => {
    const states = state.searches;

    let theKey = Object.keys(data);
    let theValue = Object.values(data);

    theKey = theKey[0];
    theValue = theValue[0];

    const select = states[theKey];

    if (select) {
      let newData = {};
      const findIndex = select?.findIndex((el) => el === theValue);
      if (findIndex >= 0) {
        newData = select?.filter((el) => el !== theValue);
        data = { [theKey]: newData };
      } else {
        newData = select?.concat(theValue);
        data = { [theKey]: newData };
      }
    } else {
      data = { [theKey]: [theValue] };
    }
    setState((el) => ({ ...el, searches: { ...states, ...data } }));
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setState((el) => ({ ...el, [name]: value }));
  };

  const checkAfterDate = ({ date }) => {
    return (
      new Date(moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")) >
      new Date(moment(new Date()).format("YYYY-MM-DD"))
    );
  };

  const onAction = (item) => {
    if (
      item?.vacancies === item?.applications ||
      item?.applications > item?.vacancies
    ) {
      toast.error("Vacancy is already filled");
    } else if (!checkAfterDate({ date: item?.expiredDate })) {
      toast.error("Job Expired");
    } else {
      if (item?.status === "Open") {
        window.location.href = `${URLS.jobView}/${item._id}`;
      }
    }
  };

  const { jobs, isLoading, ErrorMessage, searches, branchID } = state;
  return (
    <body className="w-100 job_body">
      {/* <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet> */}

      <div>
        {/* <TopbarGuest /> */}
        <section className="main__section_container p-3 d-flex">
          {branchID ? (
            <JobSideBar
              getSearches={getSearches}
              searches={searches}
              branchID={branchID}
            />
          ) : null}
          <div className="Center__Bar flex-grow-1 d-flex flex-column p-3 w-100">
            <h3>Job Listing</h3>
            <p className="mt-2">All Available job and vacancies</p>
            <div className="tools-wrapper d-flex align-items-center">
              <div className="search__wrapper d-flex align-items-center">
                <div className="input-box w-25 d-flex align-content-center">
                  <Icon path={mdiMagnify} size={1} color="gray" />
                  <input
                    placeholder="Search for jobs"
                    className="input_search"
                    value={state.jobTitle}
                    name="jobTitle"
                    onChange={(e) => onChangeInput(e)}
                  />
                </div>
                <button
                  className="search-btn d-flex align-items-center justify-content-center rounded"
                  onClick={searchJobs}
                >
                  <Icon path={mdiMagnify} size={1} color="white" />
                  Search
                </button>
              </div>
              <div className="tool_box">
                {/* <div className="sort-by d-flex align-items-center">
                  <p>Sort By : </p>
                  <p className="bold-text d-flex align-items-center">
                    Most Relevant
                    <Icon path={mdiChevronDown} size={1} color="#2463ae" />
                  </p>
                </div> */}
                <div className="line"></div>
                <div className="d-flex align-items-center layouts">
                  <div
                    className={`p-2 rounded mr-3 ${
                      activeState === "viewGrid" ? "active" : ""
                    }`}
                    onClick={toggleActiveState}
                  >
                    <Icon
                      path={mdiViewGrid}
                      size={1}
                      color={activeState === "viewGrid" ? "#2463ae" : "#9ca3af"}
                    />
                  </div>
                  <div
                    className={`p-2 rounded ${
                      activeState === "viewList" ? "active" : ""
                    }`}
                    onClick={toggleActiveState}
                  >
                    <Icon
                      path={mdiViewList}
                      size={1}
                      color={activeState === "viewList" ? "#2463ae" : "#9ca3af"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`mt-4 rounded ${
                activeState === "viewList" ? "list_grid" : "grid_conr"
              }`}
            >
              <>
                <ModalLoader show={isLoading} />

                {jobs?.length > 0 ? (
                  jobs?.map((job) => (
                    <div
                      className={` rounded ${
                        activeState === "viewList"
                          ? "list_layout"
                          : "grid_layout"
                      }`}
                      key={job._id}
                    >
                      <div className=" rounded content__box">
                        <div className=" content-1 ">
                          <h3>{job.jobTitle}</h3>
                          <div className="line-ip d-flex gap-2">
                            <span className="rounded p-1">{job.jobType}</span>
                            <p className=" dot">.</p>
                            <div className=" span_bar  gap-2">
                              <Icon
                                path={mdiClockOutline}
                                size={0.7}
                                color="#9ca3af"
                              />
                              <p> {getDateDiff(job.startDate)}</p>
                            </div>
                            <p className=" dot">.</p>
                            <div className=" span_bar d-flex  gap-2">
                              <Icon
                                path={mdiBriefcaseOutline}
                                size={0.7}
                                color="#9ca3af"
                              />
                              <p>
                                {currency(job.salaryFrom, {
                                  symbol: "",
                                }).format()}{" "}
                                -{" "}
                                {currency(job.salaryTo, {
                                  symbol: "",
                                }).format()}{" "}
                              </p>
                            </div>
                            <p className=" dot">.</p>
                            <div className=" span_bar d-flex  gap-2">
                              <Icon
                                path={mdiMapMarkerOutline}
                                size={0.7}
                                color="#9ca3af"
                              />
                              <p> {job.location}</p>
                            </div>
                          </div>
                        </div>
                        <div className=" content-2">
                          <button
                            className={`search-bt d-flex rounded ${
                              job.status === "Open" ? "" : "danger-btn"
                            }`}
                            onClick={() => onAction(job)}
                          >
                            {job.status === "Open" ? "Apply" : job.status}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="justify-content-center align-items-center">
                    <h4>{ErrorMessage}</h4>
                  </div>
                )}
              </>
            </div>
          </div>
        </section>
      </div>
    </body>
  );
};

export default JobList;
