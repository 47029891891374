import React, { useEffect, useState } from "react";
import { allMonths, fetchActionsUtil, useCookie } from "../../utils";
import { URLS } from "../urls";
import ModalLoader from "../ModalLoader";
import currency from "currency.js";
import { SummaryChartIconUp, SummaryCloseIcon } from "../icons";

const LoanHistory = ({ eId }) => {
  const token = useCookie("token");

  const [state, setState] = useState([]);
  const [total, setTotal] = useState({ AmountPaid: 0, TotalAmount: 0 });
  const [btnSummryActive, setBtnSummaryActive] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const loanData = fetchActionsUtil(
        `${URLS.backendLoan}/${eId}`,
        "GET",
        token
      );

      const totalData = fetchActionsUtil(
        `${URLS.backendLoan}/allTotalsByEmployee/${eId}`,
        "GET",
        token
      );

      const [loanResult, totalResult] = await Promise.all([
        loanData,
        totalData,
      ]);

      setState(loanResult.loan);
      setTotal({
        AmountPaid: totalResult?.AmountPaid || 0,
        TotalAmount: totalResult?.TotalAmount || 0,
      }); // set total amount paid and total amount
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const showDateInFormat = (date) => {
    date = date.split("/");
    return (
      ("0" + date[0]).slice(-2) + " " + allMonths[date[1] - 1] + " " + date[2]
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-between my-4">
        <div className="d-flex"></div>

        <div className="d-flex">
          <button
            onClick={() => setBtnSummaryActive(!btnSummryActive)}
            className="btn summaryBtn "
          >
            Show Summary
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="">
            {state?.length > 0 ? (
              <table className="table table-striped custom-table mb-0 datatable">
                <thead>
                  <tr>
                    <th>S/N</th>

                    <th>Title</th>
                    <th>Amount</th>
                    <th>RePaid</th>
                    <th>Balance</th>
                    <th>Deduct Amount</th>
                    <th>Status</th>
                    <th>Payment Status</th>
                    <th className="text-center">Term</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {state?.map((loan, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>

                        <td>{loan?.Title}</td>
                        <td>
                          {currency(loan?.Amount, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {loan?.paid
                            ? currency(loan?.paid, {
                                symbol: "",
                              }).format()
                            : 0}
                        </td>
                        <td>
                          {!loan?.paid
                            ? currency(loan?.Amount, {
                                symbol: "",
                              }).format()
                            : loan?.balance
                            ? currency(loan?.balance, {
                                symbol: "",
                              }).format()
                            : 0}
                        </td>
                        <td>
                          {loan?.payments?.length > 0
                            ? currency(loan?.payments[0], {
                                symbol: "",
                              }).format()
                            : 0}
                        </td>
                        <td
                          className={`fw-bold ${
                            loan.status === "Approved"
                              ? "text-success"
                              : loan.status === "Disapproved"
                              ? "text-danger"
                              : "text-pending"
                          }`}
                        >
                          {loan.status}
                        </td>
                        <td
                          className={`text-center fw-bold ${
                            loan?.paymentsStatus === "Paid"
                              ? "text-success"
                              : "text-primary"
                          }`}
                        >
                          {loan.paymentsStatus}
                        </td>
                        <td>
                          {loan?.paymentsStatus === "Paid" ? (
                            <div className="dropdown action-label p-cursor">
                              <div className="btn btn-white btn-sm btn-rounded dropdown-toggle px-2">
                                <i className="fa fa-dot-circle-o text-success mx-2 text-center"></i>

                                {loan?.motions && loan?.loanmotion !== "Resume"
                                  ? loan?.loanmotion
                                  : loan?.Term
                                  ? loan?.Term
                                  : "Pending"}
                              </div>
                            </div>
                          ) : (
                            loan.status === "Pending" && "Pending"
                          )}
                        </td>
                        <td>{showDateInFormat(loan?.applyDate)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="d-flex justify-content-center my-4">
                <div>
                  <h2>No loan yet</h2>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className={
          btnSummryActive
            ? `summaryNew border-top open`
            : `summaryNew border-top`
        }
      >
        <div className="row justify-content-between mt-4">
          <div className="col">
            <div className="my-3">
              <div className="d-flex gap-3 align-items-center mb-4">
                <h4 className="m-0">Loan Summary</h4>{" "}
                <hr className="flex-grow-1 m-0" />
                {btnSummryActive && (
                  <button
                    onClick={() =>
                      this.setState({
                        btnSummryActive: !btnSummryActive,
                      })
                    }
                    className="btn p-0"
                  >
                    <SummaryCloseIcon />
                  </button>
                )}
              </div>

              <div
                className="gridCont mb-3 three-col-grid"
                style={{ gridTemplateRows: "unset" }}
              >
                {/*  */}
                <div className="gridChild gridChildBorderLeftBlue">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(total?.TotalAmount, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Loan Amount</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>
                {/*  */}

                <div className="gridChild gridChildBorderLeftDarkerGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(total?.AmountPaid, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Amount Paid</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>
                {/*  */}
                <div className="gridChild gridChildBorderLeftOrange">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(total?.TotalAmount, {
                        symbol: "",
                      })
                        .subtract(total?.AmountPaid)
                        .format()}
                    </p>
                    <p className="gridChld2">Total Withstanding Amount</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalLoader show={isLoading} />
    </div>
  );
};

export default LoanHistory;
