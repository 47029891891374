import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import ApplyLeaveModal from "./ApplyLeaveModal";
import { URLS } from "../urls";
import { Form } from "react-bootstrap";
import ApproveLeaveModal from "./ApproveLeaveModal";
import DeclineLeaveModal from "./DeclineLeaveModal";
import EditLeaveModal from "./EditLeaveModal";
import DeleteLeaveModal from "./DeleteLeaveModal";
import { branch_id } from "../../utils";

const allMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

class Leaves extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appliedLeaves: [],
      employees: [],
      leaveTypes: [],
      leaveBalances: null,
      searchFilter: {},
      selectedLeave: null,
      loggedEmployee: null,
    };
  }
  componentDidMount() {
    const token = "dummy token";
    const cookies = new Cookies();
    const id = cookies.get("userId");

    fetch(`${URLS.backendApplyLeaves}/get/${branch_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          appliedLeaves: result.applyLeaves,
        });
      });

    fetch(`${URLS.backendEmployees}/getShortInfo/${branch_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          employees: result.employees,
        });
      });

    fetch(`${URLS.backendLeaves}/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          leaveTypes: result.leaves,
        });
      });

    fetch(`${URLS.backendEmployees}/getShortInfoByUserId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          loggedEmployee: result.employee,
        });
      });

    fetch(`${URLS.backendLeaveBalances}/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.leaveBalances) {
          let leaveBalancesTemp = {};
          result.leaveBalances.forEach((leaveType) => {
            leaveBalancesTemp[leaveType._id] = leaveType.leavesAvailable;
          });
          this.setState({
            leaveBalances: leaveBalancesTemp,
          });
        }
      });
  }
  updateAppliedLeavesList = (appliedLeave) => {
    let { appliedLeaves } = this.state;
    const id = appliedLeave && appliedLeave._id;
    let isExists = false;
    for (let index = appliedLeaves.length - 1; index >= 0; --index) {
      if (appliedLeaves[index]._id === id) {
        appliedLeaves.splice(index, 1);
        appliedLeaves.splice(index, 0, appliedLeave);
        isExists = true;
        break;
      }
    }
    if (isExists) {
      this.setState({ appliedLeaves: appliedLeaves });
    } else {
      this.setState({ appliedLeaves: [appliedLeave].concat(appliedLeaves) });
    }
  };
  removeLeaveFormAppliedLeavesList = (deletedLeave) => {
    let { appliedLeaves } = this.state;
    const id = deletedLeave && deletedLeave._id;
    for (let index = appliedLeaves.length - 1; index >= 0; --index) {
      if (appliedLeaves[index]._id === id) {
        appliedLeaves.splice(index, 1);
        break;
      }
    }
    this.setState({ appliedLeaves: appliedLeaves });
  };
  showDateInFormat = (date) => {
    date = date.split("/");
    return (
      ("0" + date[0]).slice(-2) + " " + allMonths[date[1] - 1] + " " + date[2]
    );
  };
  updateSelectedLeave = (appliedLeave) => (event) => {
    this.setState({
      selectedLeave: appliedLeave,
    });
  };
  // changeStatusToPending = (leave) => {
  //   const diff = moment(new Date(), "DD/MM/YYYY").diff(
  //     moment(leave.from, "DD/MM/YYYY"),
  //     "days"
  //   );
  //   if (diff > 0) {
  //     leave.status = "Pending";
  //     this.updateLeaveInDatabase(leave);
  //   }
  // };
  updateStatusAsDeclined = (leave) => (evt) => {
    leave.status = "Declined";
    this.updateLeaveInDatabase(leave);
  };
  updateLeaveInDatabase(leave) {
    const token = "dummy token";
    fetch(`${URLS.backendApplyLeaves}/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ applyLeave: leave }),
    }).then((res) => {
      if (res.ok) {
        res.json().then((result) => {
          console.log(result);
          this.updateAppliedLeavesList(result.applyLeave);
        });
      } else {
        res.json().then((responseJson) => {
          console.log(responseJson);
          alert("Error occurred, Check the form again.");
        });
      }
    });
  }
  updateSearchFilterLeaveType = (e) => {};
  updateSearchFilterLeaveStatus = (e) => {};

  calculateDays = (days) => {
    return days > 1 ? `${days} days ` : `${days} day`;
  };

  checkIfLeaveFinished = (date) => {
    let data;

    const check = moment(new Date()).isAfter(moment(date));

    if (!check) {
      data = (
        <p className="dropdown-item open-active" href="#">
          {" "}
          Active
        </p>
      );
    } else {
      data = (
        <p className="dropdown-item rejected" href="#">
          {" "}
          Finished
        </p>
      );
    }

    return data;
  };

  render() {
    const {
      appliedLeaves,
      employees,
      leaveTypes,
      searchFilter,
      selectedLeave,
      loggedEmployee,
      leaveBalances,
    } = this.state;
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-8 col-6">
                  <h4 className="page-title">Leaves</h4>
                </div>
                <div className="col-sm-4 col-6 text-right m-b-30">
                  <button
                    className="btn add-btn"
                    data-toggle="modal"
                    data-target="#add_leave"
                  >
                    <i className="fa fa-plus"></i> Apply Leave
                  </button>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              {/*<!-- Leave Statistics -->*/}
              <section className="stats">
                {/* <div className="col-md-3">
                  <div className="stats-info">
                    <h6>Today Presents</h6>
                    <h4>0</h4>
                  </div>
                </div> */}
                <div className="stat">
                  <div className="icon icon-1">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{0}</p>

                    <span className="dashboard-links">Today Presents</span>
                  </div>
                </div>

                {/* <div className="col-md-3">
                  <div className="stats-info">
                    <h6>Planned Leaves</h6>
                    <h4>
                      0 <span>Today</span>
                    </h4>
                  </div>
                </div> */}
                <div className="stat">
                  <div className="icon icon-3">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{0}</p>
                    <span className="dashboard-links">Planned Leaves</span>
                  </div>
                </div>

                {/* <div className="col-md-3">
                  <div className="stats-info">
                    <h6>Unplanned Leaves</h6>
                    <h4>
                      0 <span>Today</span>
                    </h4>
                  </div>
                </div> */}

                <div className="stat">
                  <div className="icon icon-2">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{0}</p>
                    <span className="dashboard-links">Unplanned Leaves</span>
                  </div>
                </div>

                {/* <div className="col-md-3">
                  <div className="stats-info">
                    <h6>Pending Requests</h6>
                    <h4>0</h4>
                  </div>
                </div> */}
                <div className="stat">
                  <div className="icon">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div className="details">
                    <p>{0}</p>
                    <span className="dashboard-links">Pending Requests</span>
                  </div>
                </div>
              </section>
              {/*<!-- /Leave Statistics -->*/}

              {/*<!-- Search Filter -->*/}
              <div className="row filter-row">
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <input type="text" className="form-control floating" />
                    <label className="focus-label">Employee Name</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  {/* <div className="form-group form-focus select-focus">
								<select className="select floating"> 
									<option> -- Select -- </option>
									<option>Casual Leave</option>
									<option>Medical Leave</option>
									<option>Loss of Pay</option>
								</select>
								<label className="focus-label">Leave Type</label>
							</div> */}
                  <div className="form-group form-focus">
                    <Form.Control
                      as="select"
                      value={searchFilter.leaveType}
                      onChange={this.updateSearchFilterLeaveType}
                    >
                      <option value="">--Select --</option>
                      {leaveTypes.length
                        ? leaveTypes.map((leaveType) => {
                            return (
                              <option value={leaveType.name}>
                                {leaveType.name}
                              </option>
                            );
                          })
                        : null}
                    </Form.Control>
                    <label className="focus-label">Leave Type</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <Form.Control
                      as="select"
                      value={searchFilter.leaveType}
                      onChange={this.updateSearchFilterLeaveStatus}
                    >
                      <option> -- Select -- </option>
                      <option> Pending </option>
                      <option> Approved </option>
                      <option> Rejected </option>
                    </Form.Control>
                    <label className="focus-label">Leave Status</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <div className="cal-icon">
                      <input
                        className="form-control floating datetimepicker"
                        type="text"
                      />
                    </div>
                    <label className="focus-label">From</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <div className="cal-icon">
                      <input
                        className="form-control floating datetimepicker"
                        type="text"
                      />
                    </div>
                    <label className="focus-label">To</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <button className="btn add-btn btn-block"> Search </button>
                </div>
              </div>
              {/*<!-- /Search Filter -->*/}

              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th>Employee</th>
                          <th>Leave Type</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Total no of days</th>
                          <th>No of days taken</th>
                          <th>No of days left</th>
                          <th>Active</th>
                          <th>Reason</th>
                          <th className="text-center">Status</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {appliedLeaves?.length
                          ? appliedLeaves.map((appliedLeave) => {
                              return (
                                <tr
                                  onClick={this.updateSelectedLeave(
                                    appliedLeave
                                  )}
                                >
                                  <td>
                                    <h2 className="table-avatar">
                                      <Link
                                        to={
                                          URLS.profile +
                                          "/" +
                                          appliedLeave?.employee?.id
                                        }
                                        className="avatar"
                                      >
                                        <img
                                          alt=""
                                          src={
                                            appliedLeave?.employee?.image
                                              ? URLS.backendStatic +
                                                "/" +
                                                appliedLeave?.employee?.image
                                              : "assets/img/profiles/avatar-09.jpg"
                                          }
                                        />
                                      </Link>
                                      <Link
                                        to={
                                          URLS.profile +
                                          "/" +
                                          appliedLeave?.employee?.id
                                        }
                                      >
                                        {appliedLeave?.employee?.name}{" "}
                                        <span>
                                          {appliedLeave?.employee?.designation}
                                        </span>
                                      </Link>
                                    </h2>
                                  </td>
                                  <td>{appliedLeave?.name}</td>
                                  <td>
                                    {moment(appliedLeave?.from).format(
                                      "DD/MM/YYYY"
                                    )}
                                    {/* {appliedLeave?.from} */}
                                  </td>
                                  <td>
                                    {moment(appliedLeave?.to).format(
                                      "DD/MM/YYYY"
                                    )}
                                    {/* {appliedLeave?.to} */}
                                  </td>
                                  <td>
                                    {appliedLeave?.totalDays
                                      ? this.calculateDays(
                                          appliedLeave?.totalDays
                                        )
                                      : "---"}
                                  </td>
                                  <td>
                                    {this.calculateDays(appliedLeave?.days)}
                                  </td>
                                  <td>
                                    {this.calculateDays(
                                      appliedLeave?.remaining
                                    )}
                                  </td>
                                  <td>
                                    {this.checkIfLeaveFinished(
                                      appliedLeave?.to
                                    )}
                                  </td>
                                  <td>{appliedLeave?.reason}</td>
                                  <td className="text-center">
                                    <div className="dropdown action-label">
                                      <a
                                        className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                        href="javaScript:void(0);"
                                        // data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        {appliedLeave?.status === "New" ? (
                                          <i className="fa fa-dot-circle-o text-purple">
                                            {/* {this.changeStatusToPending(
                                              appliedLeave
                                            )} */}
                                          </i>
                                        ) : appliedLeave?.status ===
                                          "Pending" ? (
                                          <i className="fa fa-dot-circle-o text-info"></i>
                                        ) : appliedLeave?.status ===
                                          "Approved" ? (
                                          <i className="fa fa-dot-circle-o text-success"></i>
                                        ) : (
                                          <i className="fa fa-dot-circle-o text-danger"></i>
                                        )}
                                        {appliedLeave?.status}
                                      </a>
                                      {/* <div className="dropdown-menu dropdown-menu-right">
                                        <a
                                          className="dropdown-item"
                                          href="javaScript:void(0);"
                                        >
                                          <i className="fa fa-dot-circle-o text-purple"></i>
                                          New
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javaScript:void(0);"
                                        >
                                          <i className="fa fa-dot-circle-o text-info"></i>
                                          Pending
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javaScript:void(0);"
                                          data-toggle="modal"
                                          data-target="#approve_leave"
                                        >
                                          <i className="fa fa-dot-circle-o text-success"></i>
                                          Approved
                                        </a>
                                        <a
                                          className="dropdown-item"
                                          href="javaScript:void(0);"
                                          data-toggle="modal"
                                          data-target="#decline_leave"
                                        >
                                          <i className="fa fa-dot-circle-o text-danger"></i>
                                          Declined
                                        </a>
                                      </div> */}
                                    </div>
                                  </td>
                                  <td className="text-right">
                                    <div className="dropdown dropdown-action">
                                      <a
                                        href="javaScript:void(0);"
                                        className="action-icon dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="material-icons">
                                          more_vert
                                        </i>
                                      </a>

                                      <div className="dropdown-menu dropdown-menu-right">
                                        {appliedLeave?.status === "New" && (
                                          <a
                                            className="dropdown-item"
                                            href="javaScript:void(0);"
                                            data-toggle="modal"
                                            data-target="#edit_leave"
                                          >
                                            <i className="fa fa-pencil m-r-5"></i>{" "}
                                            Edit
                                          </a>
                                        )}

                                        {(appliedLeave?.status === "New" ||
                                          appliedLeave?.status ===
                                            "Declined") && (
                                          <a
                                            className="dropdown-item"
                                            href="javaScript:void(0);"
                                            data-toggle="modal"
                                            data-target="#delete_leave"
                                          >
                                            <i className="fa fa-trash-o m-r-5"></i>{" "}
                                            Delete
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}

            {/*<!-- Add Leave Modal -->*/}
            <ApplyLeaveModal
              updateAppliedLeavesList={this.updateAppliedLeavesList.bind(this)}
              employees={employees}
              leaveBalances={leaveBalances}
            />
            {/*<!-- /Add Leave Modal -->*/}

            {/*<!-- Edit Leave Modal -->*/}
            <EditLeaveModal
              updateAppliedLeavesList={this.updateAppliedLeavesList.bind(this)}
              employees={employees}
              employeeAvailableLeaves={
                selectedLeave && leaveBalances
                  ? leaveBalances[selectedLeave.employee.id]
                  : null
              }
              editLeave={selectedLeave}
            />
            {/*<!-- /Edit Leave Modal -->*/}

            {/*<!-- Approve Leave Modal -->*/}
            <ApproveLeaveModal
              updateAppliedLeavesList={this.updateAppliedLeavesList.bind(this)}
              appliedLeave={selectedLeave}
              employee={loggedEmployee}
            />
            {/*<!-- /Approve Leave Modal -->*/}

            {/*<!-- Decline Leave Modal -->*/}
            <DeclineLeaveModal
              updateAppliedLeavesList={this.updateAppliedLeavesList.bind(this)}
              appliedLeave={selectedLeave}
              employee={loggedEmployee}
            />
            {/*<!-- /Decline Leave Modal -->*/}

            {/*<!-- Delete Leave Modal -->*/}
            <DeleteLeaveModal
              removeLeaveFormAppliedLeavesList={
                this.removeLeaveFormAppliedLeavesList
              }
              deleteLeave={selectedLeave}
            />
            {/*<!-- /Delete Leave Modal -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Leaves));
