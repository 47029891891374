import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Datepicker from "react-datetime";
import { Document, Page } from "react-pdf";

const EducationModal = ({
  saveEducationInfo,
  educationInfo,
  handleEducationInfoField,
  handleEducationInfoStartField,
  handleEducationInfoCompleteField,
  displayEducationCertificates,
  onDocumentLoadSuccess,
  pdfPageNumber,
  eduRef,
  handleEducationInfoFileField,
  addMoreEducationInfo,
}) => {
  return (
    <div id="education_info" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title"> Education Information</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={saveEducationInfo}>
              {educationInfo?.map((info, idx) => {
                return (
                  <Form
                    style={{
                      border: "1px solid #e3e3e3",
                      borderRadius: "5px",
                      padding: "20px 10px",
                      margin: "10px",
                    }}
                  >
                    <Form.Group>Education Information</Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Institution
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={info.institution}
                          name="institution"
                          id={"institution" + idx}
                          onChange={(e) => handleEducationInfoField(idx)(e)}
                        />
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Subject
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={info.subject}
                          name="subject"
                          id={"subject" + idx}
                          onChange={(e) => handleEducationInfoField(idx)(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Starting Date
                      </Form.Label>
                      <Col sm={4}>
                        <div classname="datePicker">
                          <i
                            class="fa fa-calendar fa-xs calendar-icon"
                            aria-hidden="true"
                          ></i>
                          <Datepicker
                            value={info.startDate}
                            name="startDate"
                            id={"startDate" + idx}
                            closeOnSelect={true}
                            onChange={(e) =>
                              handleEducationInfoStartField(idx)(e)
                            }
                          />
                        </div>
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Complete Date
                      </Form.Label>
                      <Col sm={4}>
                        <div classname="datePicker">
                          <i
                            class="fa fa-calendar fa-xs calendar-icon"
                            aria-hidden="true"
                          ></i>
                          <Datepicker
                            value={info.completeDate}
                            name="completeDate"
                            id={"completeDate" + idx}
                            closeOnSelect={true}
                            onChange={(e) =>
                              handleEducationInfoCompleteField(idx)(e)
                            }
                          />
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Degree
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={info.degree}
                          name="degree"
                          id={"degree" + idx}
                          onChange={(e) => handleEducationInfoField(idx)(e)}
                        />
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Grade
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={info.grade}
                          name="grade"
                          id={"grade" + idx}
                          onChange={(e) => handleEducationInfoField(idx)(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Upload Employee Certificate
                      </Form.Label>
                      <Col sm={10}>
                        <div className="file-upload-div">
                          <div className="file-preview">
                            <Document
                              file={displayEducationCertificates[idx]}
                              onLoadSuccess={onDocumentLoadSuccess}
                              noData={
                                <div
                                  style={{
                                    width: "110px",
                                    height: "120px",
                                    border: "1px solid #bebebe",
                                  }}
                                ></div>
                              }
                            >
                              <Page
                                pageNumber={pdfPageNumber}
                                height="120"
                                width="120"
                                className="change-pdf-size"
                              />
                            </Document>
                          </div>

                          <div className="upload-btn-wrapper">
                            <button className="custom-upload-btn">
                              Browse
                            </button>
                            <input
                              type="file"
                              label="Upload File"
                              name="certificate"
                              ref={eduRef}
                              onChange={(e) =>
                                handleEducationInfoFileField(idx)
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                  </Form>
                );
              })}
              <div className="add-more">
                <button
                  className="btn btn-primary"
                  onClick={addMoreEducationInfo}
                >
                  <i className="fa fa-plus-circle" /> Add More
                </button>
              </div>

              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  data-toggle="modal"
                  data-target="#education_info"
                >
                  Save
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationModal;
