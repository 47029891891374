/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import "../../assets/scss/job.css";
import { URLS } from "../urls";
import { useHistory } from "react-router-dom";

import GoBack from "../goBack";
import SideCard from "./sideCard";
import Profile from "./profile";
import Schedule from "./schedule";
import Documents from "./docments";
import { fetchActionsUtil } from "../../utils";
import ModalLoader from "../ModalLoader";

const ApplicantProfile = (props) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    applications: [],
    activeView: "profile",
    job: {},
  });

  const token = "dummy token";

  const { job, activeView, applications } = state;

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const urlSplit = window.location.pathname.split("/");
      const id = urlSplit.length > 3 ? urlSplit[4] : null;

      if (id) {
        const jobData = await fetchActionsUtil(
          `${URLS.backendApplications}/candidate/${id}`,
          "GET",
          token
        );

        const [jobResult] = await Promise.all([jobData]);

        const { application, job } = jobResult.application;

        setState((el) => ({
          ...el,
          applications: application,
          job: job,
        }));
      } else {
        history.goBack(); // Goes back to the previous page
      }
    } catch (error) {
      console.log(error);

      history.goBack(); // Goes back to the previous page
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const handleToggle = (view) => {
    setState((el) => ({ ...el, activeView: view }));
  };

  const headers = [
    {
      name: "Profile",
      statusName: "profile",
    },
    {
      name: "Schedule Interview",
      statusName: "schedule",
    },
    {
      name: "Documents",
      statusName: "documents",
    },
  ];
  // console.log({ applications });

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Invex ERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        <div className="page-wrapper">
          <div className="top_header bg-white w-100 p-2">
            <div className="content container-fluid p-3">
              <div className="content_header_wrapper d-flex align-items-center justify-content-between">
                <div className="header_wrapper">
                  <GoBack />
                  <div>
                    <h3 className="other_det_1 pb-0">
                      {job?.jobTitle} Applicant’s profile
                    </h3>
                    <p className="grey-xl pb-0">
                      Applicant’s details status and data
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 px-3">
            <div className="applicant_wrapper gap-md d-flex w-100">
              {applications.length !== 0 ? (
                <SideCard applications={applications} job={job} />
              ) : null}
              <div className="applicant_details_left rounded border bg-white">
                <div className="p-3 px-4">
                  <div className="d-flex align-items-center gap-lg">
                    {headers.map((el) => (
                      <div
                        className={`navtab ${
                          activeView === el.statusName
                            ? "active_navtab"
                            : "navtab"
                        }`}
                        onClick={() => handleToggle(el.statusName)}
                      >
                        {el.name}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="border-bottom mb-3"></div>
                <div className="personal-profile">
                  {activeView === "profile" && (
                    <Profile applications={applications} />
                  )}
                  {activeView === "schedule" && (
                    <Schedule applications={applications} />
                  )}
                  {activeView === "documents" && (
                    <Documents applications={applications} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar-overlay" data-reff=""></div>
      <ModalLoader show={isLoading} />
    </div>
  );
};

export default ApplicantProfile;
