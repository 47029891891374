import React, { useEffect, useState } from "react";
import { mdiChevronUp, mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import "../../assets/scss/job.css";
import { fetchActionsUtil, useCookie } from "../../utils";
import { URLS } from "../urls";

const JobSideBar = ({
  getSearches,
  searches = [],
  branchID = null,
  sideBars = [],
}) => {
  const branch_id = useCookie("branch_id");

  const [state, setState] = useState([]);
  const [open, setOpen] = useState(null);

  const fetchDetails = async () => {
    const branch = branchID ? branchID : branch_id;

    const { sideBars } = await fetchActionsUtil(
      `${URLS.backendJobs}/get-sideBars/${branch}`
    );

    setState(sideBars);
  };

  useEffect(() => {
    if (sideBars.length > 0) {
      setState(sideBars);
    } else {
      fetchDetails();
    }
  }, []);

  const toggleVisibility = (index) => {
    if (open === index) {
      setOpen();
    } else {
      setOpen(index);
    }
  };

  const DisplayItems = ({ name, type }) => {
    let select = searches[type];
    const data = { [type]: name };
    return (
      <li>
        <p className="list_text">
          <input
            onClick={() => getSearches(data)}
            type="checkbox"
            className="list_checkbox"
            checked={select?.find((el) => el === name)}
          />
          {name}
        </p>
      </li>
    );
  };

  return (
    <div className="w-12 rounded border p-3">
      <div className="left_SideBar ">
        {state?.length > 0
          ? state?.map((items, i) => (
              <div className="box-container dropdown" key={i}>
                <div className="h-dash-sidebar">
                  <div className="span_row">
                    <h3>{items?.name}</h3>
                    <Icon
                      path={i === open ? mdiChevronDown : mdiChevronUp}
                      size={1}
                      color="#515b6f"
                      onClick={() => toggleVisibility(i)}
                      className="p-cursor"
                    />
                  </div>
                </div>
                <ul className={`dropdown-content ${open === i ? "open" : ""}`}>
                  {items?.child?.map((el, ind) => (
                    <DisplayItems name={el} key={ind} type={items?.type} />
                  ))}
                </ul>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default JobSideBar;
