import React from "react";
import AllTypeTasksEmployee from "../tasks/AllTypeTasksEmployee";

const Task = ({ projectTasks }) => {
  return (
    <div className="project-task">
      <ul className="nav nav-tabs nav-tabs-top nav-justified mb-0">
        <li className="nav-item">
          <a
            className="nav-link active"
            href="#all_tasks"
            data-toggle="tab"
            aria-expanded="true"
          >
            All Tasks
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            href="#pending_tasks"
            data-toggle="tab"
            aria-expanded="false"
          >
            Pending Tasks
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            href="#under_reviewed_tasks"
            data-toggle="tab"
            aria-expanded="false"
          >
            Under Reviewing
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            href="#completed_tasks"
            data-toggle="tab"
            aria-expanded="false"
          >
            Completed Tasks
          </a>
        </li>
      </ul>
      <AllTypeTasksEmployee projectTasks={projectTasks} />
    </div>
  );
};

export default Task;
