import React from "react";

const DeleteModal = ({ handleDeleteProject }) => {
  return (
    <div className="modal custom-modal fade" id="delete_project" role="dialog">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="form-header">
              <h3>Delete Project</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div className="modal-btn delete-action">
              <div className="row">
                <div className="col-6">
                  <span
                    className="btn btn-primary continue-btn p-cursor"
                    onClick={() => handleDeleteProject()}
                  >
                    Delete
                  </span>
                </div>
                <div className="col-6">
                  <span
                    data-dismiss="modal"
                    className="btn btn-primary cancel-btn p-cursor"
                  >
                    Cancel
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
