import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import TopBar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import "../../assets/scss/job.css";

import MainDisplay from "./mainDisplay";
import calender from "../../assets/img/CalendarBlank.svg";
import { Button } from "react-bootstrap";

const PipelineView = ({ applications = [], dropDownSelect }) => {
  const [open, setOpen] = useState(0);
  const [state, setState] = useState([]);

  useEffect(() => {
    const dataDisplay = [
      {
        name: "New Applications",
        color: "#0F172A",
        status: "pending",
        appliers: applications.filter((el) => el.status === "New"),
      },
      {
        name: "Shortlisted",
        color: "#0F172A",
        status: "shortlisted",
        appliers: applications.filter((el) => el.status === "shortlisted"),
        button: (
          <div>
            <Button variant="outline-primary" size="sm">
              <img src={calender} alt="" className="svg-w-sm" /> Schedule
              Interview
            </Button>
          </div>
        ),
      },
      {
        name: "Rejected",
        color: "#0F172A",
        status: "rejected",
        appliers: applications.filter((el) => el.status === "rejected"),
      },
    ];

    setState(dataDisplay);
  }, [applications]);

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Invex ERP</title>
      </Helmet>
      <div className="main-wrapper">
        <TopBar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="w-100 flex-gap-3">
          {/*<!-- Page Content -->*/}
          {state?.map((el, i) => (
            <MainDisplay
              applications={el.appliers}
              color={el.color}
              name={el.name}
              status={el.status}
              key={i}
              toggle={toggle}
              index={i}
              open={open}
              ClickButton={el?.button}
              dropDownSelect={dropDownSelect}
            />
          ))}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
};

export default PipelineView;
