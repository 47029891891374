import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';

import { Helmet } from "react-helmet";
import Topbar from '../topbar/Topbar';
import Sidebar from '../sidebar/Sidebar';
import { Button } from 'react-bootstrap';
import "../../assets/scss/calendar.css";

function Calendar(props) {


  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">



        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper calender">
          <div className='ga-wrapper'>
            <header>
              <div>
                <h1 className="page-title">
                  My Schedules
                </h1>
                <p>
                  All schedule and events
                </p>
              </div>


              <div>
                <Button>
                  + Add Events
                </Button>
              </div>


            </header>

            <main>

              <iframe title='Fexspace Calender' src={`${process.env.REACT_APP_MESSENGER_FRONTEND}/calendar/month`}>
              </iframe>

            </main>
          </div>

        </div>
        {/*<!-- /Page Wrapper -->*/}

      </div>
      {/*<!-- /Main Wrapper -->*/}		{/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  )

}

const mapStateToProps = state => {
  return {
    User: state.api.User,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Calendar))

