import React, { Component } from "react";
import { URLS } from "../urls";
import { Link } from "react-router-dom";
import user from "../../assets/img/user.jpg";
import { NewLogo } from "../../utils";

import { mdiBellOutline, mdiHome, mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";

class TopbarGuest extends Component {
  render() {
    return (
      <div>
        <header className="main_container w-100  p-3 d-flex flex-row align-items-center justify-content-between">
          <Link to={`${URLS.login}`} className="">
            <div className="d-flex gap-3 p-3 align-items-center">
              <span className="home_btn_wrapper d-block job-logo p-2 d-flex flex-row align-items-center justify-content-center">
                <Icon path={mdiHome} size={1} color="#2463ae" />
              </span>
              <NewLogo />
            </div>
          </Link>
          <div className="flex-row left_bar d-flex gap-3">
            <Icon path={mdiBellOutline} size={1} color="#2463ae" />
            <div className="profile_chev">
              <img src={user} alt="profile-image" className="profile_image" />
              <Icon path={mdiChevronDown} size={1} color="#2463ae" />
            </div>
          </div>
        </header>

        {/* <!-- Logo --> 
		 <div class="header">
				<div className='header-left'>
					<Link to={`${URLS.login}`} className='logo'>
                        <img src={URLS.landingPage +'/assets/img/white-logo.png'} alt='' style={{ width: "120%" }}/>
                    </Link>
                </div>
				{/* <!-- /Logo --> */}
        {/* <a id='toggle_btn' href='javascript:void(0);'>
                    <span className='bar-icon'>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </a> */}
        {/* <!-- Header Title --> */}
        {/* <div class="page-title-box float-left">
					<h3>Mudiame HR</h3>
                </div> */}
        {/* <!-- /Header Title --> */}

        {/* <!-- Header Menu --> */}
        {/* <ul class="nav user-menu">
					<li class="nav-item">
						<a class="nav-link" href={`${URLS.login}`}>Login</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="register.html">Register</a>
					</li>
				</ul> */}
        {/* <!-- /Header Menu --> */}

        {/* <!-- Mobile Menu --> 
				<div class="dropdown mobile-user-menu">
					<a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
					<div class="dropdown-menu dropdown-menu-right">
						<a class="dropdown-item" href="login.html">Login</a>
						<a class="dropdown-item" href="register.html">Register</a>
					</div>
				</div>
				</div>
				{/* <!-- /Mobile Menu --> </div> */}
      </div>
    );
  }
}

export default TopbarGuest;
