import React, { useState } from "react";
import { Form, Modal, InputGroup } from "react-bootstrap";
import { URLS } from "../urls";
import moment from "moment";
import DatePicker from "react-datetime";
import { useCookie } from "../../utils";

import { Close } from "@mui/icons-material";
import mapPin from "../../assets/img/MapPin.svg";
import ModalLoader from "../ModalLoader";
// import calendar from "../../assets/img/calendar-line.svg";

export const radios = ["Full Time", "Part Time", "Internship", "Remote"];

const AddJobModal = (props) => {
  const branch_id = useCookie("branch_id");

  const initialize = {
    jobTitle: "",
    department: "",
    location: "",
    vacancies: 0,
    applications: 0,
    views: 0,
    experience: "",
    salaryFrom: "",
    salaryTo: "",
    jobType: "Full Time",
    status: "Open",
    startDate: "",
    expiredDate: "",
    description: "",
    branch_id,
    Position: "",

    salaryType: "",
    MultipleCandidates: false,
    educationLevel: "",
    responsibilities: "",
    duties: "",
    contactPerson: "",
    phoneNumber: "",
    showContact: false,
  };

  const [state, setState] = useState({
    job: {
      ...initialize,
    },

    PositionType: [
      "UI Designer",
      "Project Manager",
      "Developer",
      "Marketing",
      "Sales",
    ],
  });

  const [isLoading, setIsLoading] = useState(false);

  const updateJobTextFields = (event) => {
    const { job } = state;
    const { name, value, type } = event.target;

    job[name] = type === "checkbox" ? !job[name] : value;

    setState((el) => ({
      ...el,
      job,
    }));
  };
  const updateJobStartDate = (event) => {
    const { job } = state;
    job.startDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    setState((el) => ({
      ...el,
      job,
    }));
  };
  const updateJobExpiredDate = (event) => {
    const { job } = state;
    job.expiredDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    setState((el) => ({
      ...el,
      job,
    }));
  };
  const submitForm = (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const token = "dummy token";
      const { job } = state;

      // console.log({ job });

      fetch(`${URLS.backendJobs}/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({ job: job }),
      })
        .then((res) => res.json())
        .then((result) => {
          setIsLoading(false);
          setState((el) => ({
            ...el,
            job: {
              ...initialize,
            },
          }));
          props.fetchData();
          props.setOpenAddJob(false);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onEmploymentType = (e) => {
    const { name, id } = e.target;

    const { job } = state;
    job[name] = id;
    setState((el) => ({
      ...el,
      job,
    }));
  };

  const { job, PositionType } = state;
  const { departments } = props;
  return (
    <Modal
      className="custom-modal"
      show={props.OpenAddJob}
      centered
      onHide={() => {
        props.setOpenAddJob(false);
      }}
      size="lg"
    >
      <div className="modal-content p-4 position-relative">
        <div className="modal_header w-100 d-flex align-items-center justify-content-between">
          <div className="modal_title">
            <h1>Create Vacancy</h1>
            <p>Create a new job vacancy</p>
          </div>
          <button
            className="cursor-pointer"
            onClick={() => {
              props.setOpenAddJob(false);
            }}
          >
            <Close color="#414652" />
          </button>
        </div>
        <br></br>
        <div className="tw-modal-body p-1">
          <div className="w-100 justify-content-center d-flex gap-5">
            <form onSubmit={submitForm}>
              <div className="d-flex flex-row">
                <div className="d-flex flex-column gap-5 mr-2">
                  <div className="border tw-modal-content">
                    <div className="d-flex flex-col gap-5 align-items-start">
                      <h3 className="Heading_modal">Basic Information</h3>

                      <div className="form-group">
                        <label className="tw-form-label">
                          Job Title <span>*</span>
                        </label>
                        <input
                          className="tw-form-control border"
                          type="text"
                          value={job.jobTitle}
                          name="jobTitle"
                          placeholder="Position Name"
                          onChange={updateJobTextFields}
                        />
                      </div>
                      <div className="form-group">
                        <label className="tw-form-label">Job Department</label>
                        <select
                          as="select"
                          name="department"
                          className="tw-form-control border"
                          placeholder="Choose department"
                          value={job.department}
                          onChange={updateJobTextFields}
                          required
                        >
                          <option value="">Select</option>
                          {departments?.length &&
                            departments.map((department) => (
                              <option value={department.name}>
                                {department.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label className="tw-form-label">Position Type</label>
                        <select
                          as="select"
                          name="Position"
                          className="tw-form-control border"
                          placeholder="Choose Position Type"
                          value={job.Position}
                          onChange={updateJobTextFields}
                          required
                        >
                          <option value="">Select</option>
                          {PositionType?.length &&
                            PositionType.map((d) => (
                              <option value={d}>{d}</option>
                            ))}
                        </select>
                      </div>

                      <div className="">
                        <div className="form-group">
                          <label>No of Vacancies</label>
                          <input
                            className="tw-form-control border"
                            type="number"
                            value={job.vacancies}
                            name="vacancies"
                            onChange={updateJobTextFields}
                          />
                        </div>
                      </div>

                      <Form.Group>
                        <Form.Label className="tw-form-label">
                          Employment Type
                        </Form.Label>
                        <div className="d-flex align-items-center w-100 gap-5">
                          {radios?.map((el, i) => (
                            <Form.Check
                              key={i}
                              inline
                              label={el}
                              name="jobType"
                              type="radio"
                              id={el}
                              onClick={onEmploymentType}
                              checked={el === job.jobType}
                            />
                          ))}
                        </div>
                      </Form.Group>

                      <div className="form-group">
                        <label className="tw-form-label">Job Location</label>
                        <div className="tw-form-control d-flex align-items-center justify-content-between  border">
                          <input
                            type="text"
                            value={job.location}
                            name="location"
                            placeholder="Choose location"
                            className="ghost-input"
                            onChange={updateJobTextFields}
                          />
                          <img src={mapPin} alt="map" width={14} height={14} />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="tw-form-label">Job Description</label>
                        <textarea
                          className="tw-form-control border"
                          rows="6"
                          value={job.description}
                          name="description"
                          placeholder="Enter your detail description"
                          onChange={updateJobTextFields}
                        ></textarea>
                      </div>

                      <div className="form-group">
                        <label className="tw-form-label">Salary</label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="number"
                            placeholder="From"
                            name="salaryFrom"
                            value={job.salaryFrom}
                            onChange={updateJobTextFields}
                          />

                          <Form.Control
                            type="number"
                            placeholder="To"
                            name="salaryTo"
                            value={job.salaryTo}
                            onChange={updateJobTextFields}
                          />
                          <select
                            className="host-input"
                            name="salaryType"
                            value={job.salaryType}
                            onChange={updateJobTextFields}
                          >
                            <option value="">Select</option>
                            <option>USD</option>
                            <option>NGN</option>
                            <option>YEN</option>
                            <option>PND</option>
                            <option>RPS</option>
                          </select>
                        </InputGroup>
                      </div>

                      <div className="form-group">
                        <label className="tw-form-label">
                          Multiple Candidates
                        </label>
                        <div className="d-flex gap-4">
                          <input
                            type="checkbox"
                            checked={job.MultipleCandidates}
                            name="MultipleCandidates"
                            onChange={updateJobTextFields}
                          />
                          <label
                            for="multiple choices"
                            className="tw-form-label-radio"
                          >
                            Yes, I am hiring multiple candidates
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border tw-modal-content">
                    <h3 className="Heading_modal">Date and Status</h3>

                    <div className="form-group">
                      <label className="tw-form-label">Status</label>
                      <select
                        as="select"
                        name="status"
                        className="border tw-form-control"
                        value={job.status}
                        onChange={updateJobTextFields}
                        required
                      >
                        <option value="Open">Open</option>
                        <option value="Closed">Closed</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                    </div>
                    <div className="d-flex gap-5 align-items-center">
                      <div className="">
                        <label className="tw-form-label">Opening Date</label>
                        {/* <div className="tw-form-control-sm d-flex align-items-center justify-content-between border"> */}
                        {/* <input
                          value={job.startDate}
                          closeOnSelect={true}
                          placeholder="Choose Date"
                          onChange={updateJobStartDate}
                          className="ghost-input"
                        /> */}

                        <DatePicker
                          value={job.startDate}
                          closeOnSelect={true}
                          onChange={updateJobStartDate}
                        />
                        {/* <img src={calendar} alt="map" width={14} height={14} /> */}
                        {/* </div> */}
                      </div>
                      <div className="">
                        <label className="tw-form-label">closing Date</label>
                        {/* <div className="tw-form-control-sm d-flex align-items-center justify-content-between border"> */}
                        {/* <input
                          placeholder="Choose Date"
                          value={job.expiredDate}
                          closeOnSelect={true}
                          onChange={updateJobExpiredDate}
                          className="ghost-input"
                        />
                        <img src={calendar} alt="map" width={14} height={14} /> */}
                        <DatePicker
                          value={job.expiredDate}
                          closeOnSelect={true}
                          onChange={updateJobExpiredDate}
                        />
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex position-relative flex-column gap-5">
                  <div className="border tw-modal-content">
                    <h3 className="Heading_modal mb-4">
                      Applicant Requirements
                    </h3>

                    <div className="form-group">
                      <label className="tw-form-label">
                        Work Experience <span>*</span>
                      </label>
                      <input
                        className="border tw-form-control"
                        type="text"
                        value={job.experience}
                        placeholder="Work Experience"
                        name="experience"
                        onChange={updateJobTextFields}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="tw-form-label">
                        Education Qualification{" "}
                      </label>
                      <input
                        className="border tw-form-control"
                        type="text"
                        value={job.educationLevel}
                        placeholder="Educational level"
                        name="educationLevel"
                        onChange={updateJobTextFields}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="tw-form-label">
                        Job Responsibilities
                      </label>
                      <textarea
                        className="tw-form-control border"
                        rows="6"
                        value={job.responsibilities}
                        name="responsibilities"
                        placeholder="Enter the job responsibilities"
                        onChange={updateJobTextFields}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label className="tw-form-label">Duties</label>
                      <textarea
                        className="tw-form-control border"
                        rows="6"
                        value={job.duties}
                        name="duties"
                        placeholder="Enter the job duties"
                        onChange={updateJobTextFields}
                      ></textarea>
                    </div>
                  </div>
                  <div className="border tw-modal-content">
                    <h3 className="Heading_modal">Contact Information</h3>
                    <form onSubmit={submitForm}>
                      <div className="form-group">
                        <label className="tw-form-label">Contact person</label>
                        <input
                          className="border tw-form-control"
                          type="text"
                          value={job.contactPerson}
                          placeholder="Contact name"
                          name="contactPerson"
                          onChange={updateJobTextFields}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label className="tw-form-label">Phone Number</label>
                        <input
                          className="border tw-form-control"
                          type="text"
                          value={job.phoneNumber}
                          placeholder="(555) xxxx xxxx"
                          name="phoneNumber"
                          onChange={updateJobTextFields}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label className="tw-form-label">Show Contact</label>
                        <div className="d-flex gap-4">
                          <input
                            type="checkbox"
                            name="showContact"
                            onChange={updateJobTextFields}
                            checked={job.showContact}
                          />
                          <label
                            for="show contact"
                            className="tw-form-label-radio"
                          >
                            Show the name and phone number on this job page
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="tw-submit">
                    <button className="tw-btn-cancel">Cancel</button>
                    <button type="submit" className="tw-btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <ModalLoader show={isLoading} />
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddJobModal;
