import { useState, useEffect } from "react";

export const useQueryString = () => {
  // Function to parse query string into an object
  const parseQueryString = (queryString) => {
    if (!queryString) return {};

    const params = new URLSearchParams(queryString);
    const queryObject = {};

    params.forEach((value, key) => {
      if (queryObject[key]) {
        // Handle multiple values for the same key (e.g., key=1&key=2)
        queryObject[key] = [].concat(queryObject[key], value);
      } else {
        queryObject[key] = value;
      }
    });

    return queryObject;
  };

  // Function to convert an object into a query string
  const stringifyQueryString = (queryObject) => {
    const params = new URLSearchParams();

    Object.keys(queryObject).forEach((key) => {
      const value = queryObject[key];
      if (Array.isArray(value)) {
        value.forEach((val) => params.append(key, val));
      } else {
        params.set(key, value);
      }
    });

    return params.toString();
  };

  // State to hold the parsed query string data
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    // Get the current URL's query string
    const queryString = window.location.search;
    setQueryParams(parseQueryString(queryString));
  }, []);

  // Example of modifying query string using state
  const updateQueryString = (newParams) => {
    const updatedParams = { ...queryParams, ...newParams };
    const newQueryString = stringifyQueryString(updatedParams);

    // Update the URL without refreshing the page
    window.history.pushState({}, "", "?" + newQueryString);
    setQueryParams(updatedParams);
  };

  return {
    queryParams,
    updateQueryString,
  };
};

export function useDebounceQueryString(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
