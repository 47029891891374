import React from "react";
import { Link } from "react-router-dom";
import { URLS } from "../urls";
import currency from "currency.js";
import { Dropdown, Table } from "react-bootstrap";

const EmployeeTable = ({
  showSalary,
  searchEmlpoyeeList,
  setState,
  onEditEmployee,
  deleteEmployee,
  OnFullName,
  setOpenDelete,
  startIndex,
}) => {
  const dropsItems = [
    {
      action: ({ employee }) => {
        setState((el) => ({ ...el, showEdit: true }));
        onEditEmployee(employee._id, employee.userId);
      },
      icon: <i className="fa fa-pencil m-r-5"></i>,
      name: "Edit",
    },
    {
      action: ({ employee }) => {
        setOpenDelete(true);
        deleteEmployee(employee._id);
      },
      icon: <i className="fa fa-trash-o m-r-5"></i>,
      name: "Delete",
    },
    {
      action: ({ employee }) => {
        setState((el) => ({
          ...el,
          openEditSalary: true,
          employee_id: employee?._id,
          jobgradeid: employee.jobInfo?.grade?._id,
        }));
        OnFullName(employee);
      },
      icon: <i className="fa fa-money m-r-5"></i>,
      name: "Edit Salary",
    },
    {
      action: ({ employee }) => {
        setState((el) => ({
          ...el,
          employeeUserId: employee.userId,
          openTermination: true,
        }));

        OnFullName(employee);
      },
      icon: <i className="fa fa-minus-circle m-r-5"></i>,
      name: "Terminate",
    },
  ];

  const DropDowns = ({ action, icon, name }) => {
    return (
      <Dropdown.Item
        as="div"
        className="p-cursor"
        onClick={() => {
          action();
        }}
      >
        {icon} {name}
      </Dropdown.Item>
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <Table responsive className="table-custom">
          <thead>
            <tr className="text-nowrap">
              <th></th>
              <th>No</th>
              <th className="text-left">Name</th>
              <th>EmployeeId</th>
              {showSalary && <th>Salary</th>}
              <th>Job Grade</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Join Date</th>
              <th>Start Date</th>
            </tr>
          </thead>

          <tbody>
            {searchEmlpoyeeList.map((employee, index) => {
              const displayImage =
                employee?.emergencyInfo && employee?.emergencyInfo?.image;

              const profileImage = displayImage
                ? URLS.backendStatic + "/" + displayImage
                : "/assets/img/profiles/avatar-03.jpg";

              return (
                <tr key={index}>
                  <td className="text-right">
                    <Dropdown>
                      <Dropdown.Toggle variant="" bsPrefix="">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                      >
                        {dropsItems?.map((el) => (
                          <DropDowns
                            action={() => el.action({ employee })}
                            icon={el.icon}
                            name={el.name}
                          />
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>{startIndex + index + 1}</td>
                  <td className="w-100">
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="table-avatar blue-link">
                        <Link to={`profile/${employee._id}`} className="avatar">
                          <img alt="" src={profileImage} />
                        </Link>
                      </div>
                      <div className="table-avatar blue-link">
                        <h2 className="text-nowrap text-capitalize">
                          <Link to={`profile/${employee._id}`}>
                            {employee.personalInfo?.name?.first +
                              " " +
                              employee.personalInfo?.name?.last}
                          </Link>
                        </h2>
                      </div>
                    </div>
                  </td>
                  <td>{employee.jobInfo?.employeeId}</td>
                  {showSalary && (
                    <td>
                      {currency(employee.jobInfo?.salary || 0, {
                        symbol: "",
                      }).format()}
                    </td>
                  )}
                  <td>{employee.jobInfo?.grade?.name}</td>
                  <td>{employee.personalInfo?.email}</td>
                  <td>{employee.personalInfo?.phone?.home}</td>
                  <td>{employee.jobInfo?.joinDate}</td>
                  <td>{employee.jobInfo?.startDate}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default EmployeeTable;
