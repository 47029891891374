/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import { useCookie } from "../../utils";
import { AddSuspension } from "./AddSuspension";
import { EditSuspension } from "./EditSuspension";
import { DeleteSuspension } from "./DeleteSuspension";

function Suspension() {
  const branch_id = useCookie("branch_id");

  const [suspensions, setSuspensions] = useState([]);
  const [suspensionIdToDelete, setSuspensionIdToDelete] = useState("");

  const [suspensionDataToUpdate, setSuspensionDataToUpdate] = useState({
    employeeName: "",
    employeeId: "",
    noticeDate: "",
    suspensionDate: "",
    reason: "",
    departmentId: "",
    branch_id,
    type: "",
  });

  const [openAddSuspension, setOpenAddSuspension] = useState(false);
  const [editSuspension, setEditSuspension] = useState(false);
  const [deleteSuspension, setDeleteSuspension] = useState(false);

  useEffect(() => {
    getSuspensions();
    return () => {};
  }, []);

  const setSuspensionToUpdate = (suspension) => {
    setSuspensionDataToUpdate({
      employeeName: `${suspension?.employee?.personalInfo.name.first || ""}  ${
        suspension?.employee?.personalInfo.name.last || ""
      }`,
      departmentId: suspension?.employee?.jobInfo?.department,
      employeeId: suspension?.employeeId,
      noticeDate: suspension?.noticeDate,
      suspensionDate: suspension?.suspensionDate,
      reason: suspension?.reason,
      type: suspension?.type,
    });
  };

  const getSuspensions = () => {
    const token = "dummy token";
    fetch(`${URLS.backendSuspension}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setSuspensions(result.suspensions);
      })
      .catch((err) => console.log(err));
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Suspension</BreadcrumbItem>
                </Breadcrumb>
                {/* <h4 className="page-title">Employee</h4> */}
              </div>
              <div className="col-auto text-right float-right ml-auto m-b-30">
                <div
                  className="btn add-btn"
                  onClick={() => setOpenAddSuspension(true)}
                >
                  <i className="fa fa-plus"></i>Add Suspension
                </div>
              </div>
            </div>
            {/*<!-- /Page Title -->*/}

            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  {
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Employee </th>
                          <th>Department </th>
                          <th>Type </th>
                          <th>Reason </th>
                          <th>Notice Date </th>
                          <th>Suspension Date </th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {suspensions?.map((suspension, index) => {
                          const { employee, department } = suspension;
                          console.log({ employee, department });
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <h2 className="table-avatar blue-link">
                                  <Link
                                    to={`profile/${employee._id}`}
                                    className="avatar"
                                  >
                                    <img
                                      alt=""
                                      src="assets/img/profiles/avatar-02.jpg"
                                    />
                                  </Link>
                                  <Link to={`profile/${employee._id}`}>
                                    {employee.personalInfo.name.first || ""}{" "}
                                    {employee.personalInfo.name.last || ""}
                                  </Link>
                                </h2>
                              </td>
                              <td>{department?.name}</td>
                              <td>{suspension.type}</td>
                              <td>{suspension.reason}</td>
                              <td>{suspension.noticeDate}</td>
                              <td>{suspension.suspensionDate}</td>
                              <td className="text-right">
                                <div className="dropdown dropdown-action p-cursor">
                                  <div
                                    className="action-icon dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="material-icons">more_vert</i>
                                  </div>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <div
                                      className="dropdown-item"
                                      onClick={() => {
                                        setSuspensionToUpdate(suspension);
                                        setEditSuspension(true);
                                      }}
                                    >
                                      <i className="fa fa-pencil m-r-5"></i>{" "}
                                      Edit
                                    </div>
                                    <div
                                      className="dropdown-item"
                                      onClick={() => {
                                        setSuspensionIdToDelete(suspension._id);
                                        setDeleteSuspension(true);
                                      }}
                                    >
                                      <i className="fa fa-trash-o m-r-5"></i>{" "}
                                      Delete
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  }
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Page Content -->*/}

          {/*<!-- Add Resignation Modal -->*/}
          {openAddSuspension && (
            <AddSuspension
              show={openAddSuspension}
              handleClose={setOpenAddSuspension}
              suspensions={suspensions}
              setSuspensions={setSuspensions}
            />
          )}
          {/*<!-- /Add Resignation Modal -->*/}

          {/*<!-- Edit Resignation Modal -->*/}
          {editSuspension && (
            <EditSuspension
              suspensionDataToUpdate={suspensionDataToUpdate}
              suspensions={suspensions}
              setSuspensions={setSuspensions}
              setSuspensionDataToUpdate={setSuspensionDataToUpdate}
              show={editSuspension}
              handleClose={setEditSuspension}
            />
          )}
          {/*<!-- /Edit Resignation Modal -->*/}

          {/*<!-- Delete Resignation Modal -->*/}
          {deleteSuspension && (
            <DeleteSuspension
              suspensionIdToDelete={suspensionIdToDelete}
              suspensions={suspensions}
              setSuspensions={setSuspensions}
              show={deleteSuspension}
              handleClose={setDeleteSuspension}
            />
          )}
          {/*<!-- /Delete Resignation Modal -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Suspension));
