import * as React from "react";
import { Table } from "reactstrap";
import ProgressBar from "./ProgressBar";
import dots from "../../assets/img/dots.svg";
import MenuPopover from "./Menu-Popover";


const GoalProgress = () => {
  const [showmenu , setShowmenu] = React.useState(false);

  function toggleMenu() {
    setShowmenu(!showmenu);
  }

  return (
    <div>
      <Table responsive className="tw-table-striped w-100 position-relative">
        <thead className="w-100">
          <tr className="w-100">
            <th className="">
              <p>Goal Name</p>
            </th>
            <th className="">
              <p>Category</p>
            </th>
            <th className="">
              <p>Due Date</p>
            </th>
            <th className="">
              <p> Status</p>
            </th>
            <th className="">
              <p>Progress</p>
            </th>
          </tr>
        </thead>
        <tbody className="position-relative">
       
          {showmenu && <MenuPopover />}
          <tr className="my-4 position-relative">
            <td>Close up HR fulltime</td>
            <td className="language-box">Head of marketing</td>
            <td className="date-text">18 / 11 / 24</td>
            <td>
              <div className="status-in-progress">
                <div>.</div> <p>in Progress</p>
              </div>
            </td>

            <td>
            <ProgressBar />
            </td>
            <td>
              <button className=" w-auto" onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
          <tr className="my-4">
            <td>Close up HR fulltime</td>
            <td className="language-box">Head of marketing</td>
            <td className="date-text">18 / 11 / 24</td>
            <td>
              <div className="status-in-progress">
                <div>.</div> <p>in Progress</p>
              </div>
            </td>

            <td>
            <ProgressBar />
            </td>
            <td>
              <button className=" w-auto" onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
          <tr className="my-4">
            <td>Close up HR fulltime</td>
            <td className="language-box">Head of marketing</td>
            <td className="date-text">18 / 11 / 24</td>
            <td>
              <div className="status-completed">
                <div>.</div> <p>Completed</p>
              </div>
            </td>

            <td>
            <ProgressBar />
            </td>
            <td>
              <button className=" w-auto" onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
          <tr className="my-4">
            <td>Close up HR fulltime</td>
            <td className="language-box"> Marketing employee</td>
            <td className="date-text">18 / 11 / 24</td>
            <td>
              <div className="status-in-progress">
                <div>.</div> <p>in Progress</p>
              </div>
            </td>

            <td>
            <ProgressBar />
            </td>
            <td>
              <button className=" w-auto" onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
          <tr className="my-4">
            <td>Close up HR fulltime</td>
            <td className="language-box">HR Manager</td>
            <td className="date-text">18 / 11 / 24</td>
            <td>
              <div className="status-completed">
                <div>.</div> <p>Completed</p>
              </div>
            </td>

            <td>
            <ProgressBar />
            </td>
            <td>
              <button className=" w-auto" onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
          <tr className="my-4">
            <td>Close up HR fulltime</td>
            <td className="language-box">Procurement Division</td>
            <td className="date-text">18 / 11 / 24</td>
            <td>
              <div className="status-completed">
                <div>.</div> <p>Completed</p>
              </div>
            </td>

            <td>
            <ProgressBar />
            </td>
            <td>
              <button className=" w-auto" onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
          <tr className="my-4">
            <td>Close up HR fulltime</td>
            <td className="language-box">Head of marketing</td>
            <td className="date-text">18 / 11 / 24</td>
            <td>
              <div className="status-completed">
                <div>.</div> <p>Completed</p>
              </div>
            </td>

            <td>
            <ProgressBar />
            </td>
            <td>
              <button className=" w-auto" onClick={toggleMenu}>
                <img src={dots} alt="" width={15} />
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

// Head of marketing
// HR Manager
// Marketing employee
// Procurement Division
// HR Manager
// Procurement Division
// HR Manager
// Procurement Division
// HR Manager
// Procurement Division

export default GoalProgress;
