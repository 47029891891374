import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const BarChart = () => {
  const data = {
    labels: ["Group 1", "Group 2", "Group 3"], // Replace with your actual group labels
    datasets: [
      {
        label: "Dataset A",
        data: [2, 2, 2], // Values for each bar
        backgroundColor: "rgba(18, 109, 216, 1)", // Bar color for this dataset
        borderRadius: 30, // Round the tops
        barPercentage: 0.2, // Adjust the bar width
        categoryPercentage: 0.8, // Adjust spacing within groups
      },
      {
        label: "Dataset B",
        data: [2, 2, 2], // Values for each bar
        backgroundColor: "rgba(207, 228, 252, 1)", // Bar color for this dataset
        borderRadius: 30, // Round the tops
        barPercentage: 0.2,
        categoryPercentage: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "#555",
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`, // Example: "Dataset A: 2"
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: "#555",
        },
        grid: {
          display: false, // Hide grid lines for the X-axis
        },
      },
      y: {
        ticks: {
          color: "#555",
        },
        grid: {
          color: "#eee", // Light grid lines for the Y-axis
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "400px", margin: "auto" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
