import { mdiChevronLeft } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { useHistory } from "react-router-dom";

const GoBack = () => {
  const history = useHistory();

  return (
    <div className="p-cursor">
      <p className="d-flex align-items-center" onClick={() => history.goBack()}>
        <div className="nav_back">
          <span>
            <Icon path={mdiChevronLeft} size={1.5} color="#2463ae" />
          </span>
          <p className="nav_back-text pb-0">Back</p>
        </div>
      </p>
    </div>
  );
};

export default GoBack;
