import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import $ from "jquery";
import "../../assets/css/jobgrade.css";
import { URLS } from "../urls";
import { toast } from "react-toastify";

const EditJobCategoryModal = ({
  setLoader,
  id,
  changeEditJobState,
  refetch,
  isShow,
}) => {
  const [state, setState] = useState({ name: "", description: "", _id: "" });

  const OnChange = (event) => {
    const { name, value } = event.target;
    setState((el) => ({
      ...el,
      [name]: value,
    }));
  };

  const componentWillMount = () => {
    const token = "dummy token";

    fetch(`${URLS.backendJobCategory}/get-one/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setState({
          ...result?.jobCategory,
        });
      });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      componentWillMount();
    }

    return () => {
      mounted = false;
    };
  }, []);

  const submitJob = (e) => {
    e.preventDefault();
    console.log("in submit");
    setLoader(true);
    const token = "dummy token";
    fetch(`${URLS.backendJobCategory}/edit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ jobCategory: state }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          changeEditJobState();
          refetch();
          toast("Job grade edited successfully", {
            type: "success",
          });
        } else {
          changeEditJobState();
          const e = Error(result.err);
          console.log(e);
        }
      })
      .catch((err) => {
        toast("Error unable to edit Job Grade", {
          type: "error",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <Modal
      show={isShow}
      onHide={() => changeEditJobState()}
      // dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={submitJob}
          style={{
            maxHeight: "100%",
            borderRadius: "10px",
          }}
        >
          <Form.Group
            as={Row}
            style={{
              alignItems: "left",
              justifyContent: "left",
              fontSize: "16px",
              color: "#ffffff",
              fontWeight: "bold",
              height: "40px",
              padding: "10px 15px",
              marginLeft: "0",
              marginRight: "0",
              display: "block",
              backgroundColor: "#5378b2",
            }}
          >
            <span
              style={{
                display: "inline-block",
                height: "20px",
                lineHeight: "20px",
                float: "left",
              }}
            >
              Edit Job Category
            </span>
            <span
              style={{
                display: "inline-block",
                height: "10px",
                float: "right",
                padding: "5px",
                position: "relative",
              }}
            ></span>
          </Form.Group>
          <Form.Group
            as={Row}
            style={{
              marginLeft: "0",
              marginRight: "0",
              "padding-left": "10px",
            }}
          >
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Job Grade
            </Form.Label>
            <Col sm={6}>
              <Form.Control
                name="name"
                value={state.name}
                onChange={OnChange}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            style={{
              marginLeft: "0",
              marginRight: "0",
              "padding-left": "10px",
            }}
          >
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Description
            </Form.Label>
            <Col sm={6}>
              <textarea
                className="form-control"
                rows="5"
                name="description"
                value={state.description}
                onChange={OnChange}
              />
            </Col>
          </Form.Group>

          <div className="d-flex justify-content-center p-4">
            <button
              style={{
                borderRadius: "7px",
                backgroundColor: "#5378b2",
                border: "none",
              }}
              className="btn btn-primary submit-btn"
            >
              Save Changes
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default EditJobCategoryModal;
