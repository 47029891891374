import React, { useEffect, useState } from "react";
import Datepicker from "react-datetime";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import { NormalDot } from "../icons";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { URLS } from "../urls";
import { fetchActionsUtil, useCookie } from "../../utils";

const Activities = () => {
  const history = useHistory();

  const branch_id = useCookie("branch_id");
  const token = useCookie("token");

  const [state, setState] = useState({
    leaves: [],
    loans: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const leavesData = fetchActionsUtil(
        `${URLS.backendApplyLeaves}/get/${branch_id}?status=New`,
        "GET",
        token
      );

      const loanData = fetchActionsUtil(
        `${URLS.backendLoan}/by-branch/${branch_id}?stat=Pending`,
        "GET",
        token
      );

      const [leavesResult, loanResult] = await Promise.all([
        leavesData,
        loanData,
      ]);

      let empData = [];
      let empLoan = [];

      leavesResult.applyLeaves.forEach((element) => {
        empData.push({
          ...element.employee,
          title: element?.name,
          type: "New leave request",
          details: `A leave request from ${element?.employee?.name}`,
        });
      });

      loanResult.loan.forEach((element) => {
        const theName = `${element.userID?.personalInfo?.name?.first || ""} ${
          element.userID?.personalInfo?.name?.middle || ""
        } ${element.userID?.personalInfo?.name?.last || ""}`;
        empLoan.push({
          name: theName,
          title: element?.Title,
          id: element?._id,
          type: "New loan request",
          details: `A loan request from ${theName}`,
        });
      });

      setState({
        leaves: empData,
        loans: empLoan,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  // console.log(state);

  const properties = {
    duration: 1500,
    autoplay: true,
    transitionDuration: 1000,
    arrows: false,
    infinite: true,
    easing: "ease",
    // indicators: (i) => <div className="indicator">{i + 1}</div>,
  };

  const MainDataDisplay = ({ type, details }) => {
    return (
      <div className="activity-box mb-2">
        <div className="d-flex align-items-baseline">
          <NormalDot />
          <div className="mx-2">
            <div className="text-muted activity-box-time">{type}</div>
            <div className="activity-details">{details}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="info activities-dashboard">
      <div>
        <Datepicker
          // value={personalInfo.birth}
          closeOnSelect={false}
          // onChange={this.updatePersonalInfoBirth.bind(this)}
          open={true}
          className="calender-class"
          input={false}
        />
      </div>
      <div className="main-activity">
        <div className="activity-line"></div>
        <div>
          <p className="activity-text">Activities</p>

          <div className="activity-slider">
            <Slide {...properties}>
              {state.leaves?.map((el, i) => (
                <MainDataDisplay
                  key={i}
                  type={el?.type}
                  details={el?.details}
                />
              ))}
            </Slide>
          </div>

          <div className="activity-slider">
            <Slide {...properties}>
              {state.loans?.map((el, i) => (
                <MainDataDisplay
                  type={el?.type}
                  details={el?.details}
                  key={i}
                />
              ))}
            </Slide>
          </div>

          {/* <div className="activity-foot d-flex justify-content-center">
            <Button variant="" onClick={() => history.push(URLS.calendar)}>View More Events</Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Activities;
