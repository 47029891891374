import { Close } from "@mui/icons-material";
import React, { useState } from "react";
import { URLS } from "../urls";
import moment from "moment";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import ModalLoader from "../ModalLoader";
import { DatePicker } from "antd";

const ApplyJob = ({ _id, openApply, setOpenApply, componentWillMount }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    application: {
      jobId: "",
      name: "",
      email: "",
      phone: "",
      message: "",
      applyDate: "",
      status: "New",

      birthDate: "",
      Address: "",
      gender: "",

      resume: {
        original: "",
        unique: "",
        ext: "",
      },
    },
    applicationFile: null,
  });

  //   const getDateDiff = (date) => {
  //     let lastDate = moment(date, "DD/MM/YYYY");
  //     let today = moment(new Date(), "DD/MM/YYYY");
  //     let days = lastDate.diff(today, "days");
  //     if (days === 0) return "Application ends Today";
  //     else if (days === 1) return "Application ends Tomorrow";
  //     else return "Application ends in " + days + " days";
  //   };

  const updateApplicationFile = (event) => {
    const { application } = state;
    const file = event.target.files[0];
    const name = file.name;
    const lastDot = name.lastIndexOf(".");
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    application.resume.original = fileName;
    application.resume.ext = ext;
    setState((el) => ({
      ...el,
      application,
      applicationFile: file,
    }));
  };

  const submitApplication = (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const token = "dummy token";
      const { application, applicationFile } = state;
      application.applyDate = moment(new Date()).format("DD/MM/YYYY");
      application.jobId = _id;
      application.resume.unique =
        _id + "@" + application.email + "." + application.resume.ext;
      let formData = new FormData();
      formData.append("application", JSON.stringify({ ...application }));
      formData.append("file", applicationFile, application.resume.unique);
      console.log("formData", formData);
      fetch(`${URLS.backendApplications}/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Token ${token}`,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          setIsLoading(false);
          if (result.success) {
            toast.success("Submitted Successfully");
            componentWillMount();
            setOpenApply(false);
          } else {
            console.log(result);

            if (result.error.message) {
              toast.error(result.error.message);
            } else {
              toast.error("You have already Submitted");
            }
          }
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const updateApplicationText = (event) => {
    const { application } = state;
    application[event.target.name] = event.target.value;
    setState((el) => ({
      ...el,
      application,
    }));
  };

  const updateJobStartDate = (date, dateString) => {
    const { application } = state;

    application.birthDate = dateString
      ? dateString
      : moment().format("DD/MM/YYYY");

    setState((el) => ({
      ...el,
      application,
    }));
  };

  const { application } = state;
  return (
    <Modal
      className="custom-modal"
      show={openApply}
      centered
      onHide={() => {
        setOpenApply(false);
      }}
      size="md"
    >
      <div className="modal-content p-4">
        <div className="modal_header w-100 d-flex align-items-center justify-content-between">
          <div className="modal_title">
            <h1>Apply For Vacancy</h1>
            {/* <p>Create a new job vacancy</p> */}
          </div>
          <button
            className="cursor-pointer"
            onClick={() => setOpenApply(false)}
          >
            <Close color="#414652" />
          </button>
        </div>
        <div className="modal-body p-2">
          <br></br>
          <form onSubmit={submitApplication} className="d-flex flex-col gap-5">
            <div className="tw-form-group">
              <label className="tw-form-label">Applicant Name</label>
              <input
                className="tw-form-control-full  border"
                type="text"
                placeholder="Enter applicant name"
                name="name"
                value={application.name}
                onChange={(e) => updateApplicationText(e)}
                required
              />
            </div>
            <div className="form-group">
              <label className="tw-form-label">Phone Number</label>
              <input
                className="tw-form-control-full  border"
                type="text"
                name="phone"
                placeholder="(555) xxxx xxxx"
                value={application.phone}
                onChange={(e) => updateApplicationText(e)}
                required
              />
            </div>
            <div className="form-group">
              <label className="tw-form-label">Email Address</label>
              <input
                className="tw-form-control-full  border"
                type="email"
                name="email"
                placeholder="Email"
                value={application.email}
                onChange={(e) => updateApplicationText(e)}
                required
              />
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <p className="tw-form-label">BirthDate</p>

                  <DatePicker onChange={updateJobStartDate} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="tw-form-label">Gender</label>
                  <br></br>
                  <select
                    name="gender"
                    required
                    className="tw-form-control-full border"
                    onChange={(e) => updateApplicationText(e)}
                    value={application.gender}
                  >
                    <option value="">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="tw-form-label">Address</label>
              <textarea
                className="tw-form-control-full border"
                name="Address"
                placeholder="Enter your Address"
                rows="7"
                value={application.Address}
                onChange={(e) => updateApplicationText(e)}
                required
              ></textarea>
            </div>
            <div className="form-group">
              <label className="tw-form-label">Work Experience</label>
              <textarea
                className="tw-form-control-full  border"
                name="message"
                placeholder="Tell us about your work experience"
                rows="7"
                value={application.message}
                onChange={(e) => updateApplicationText(e)}
                required
              ></textarea>
            </div>

            <div className="form-group">
              <label className="tw-form-label">Upload your CV</label>
              <div className="d-flex align-items-center  justify-content-between tw-form-control-full border">
                <input
                  type="file"
                  className="ghost-input"
                  id="cv_upload"
                  placeholder="Choose file"
                  onChange={(e) => updateApplicationFile(e)}
                  required
                />
              </div>
            </div>
            <div className="tw-submit-1">
              <button
                onClick={() => setOpenApply(false)}
                className="tw-btn-cancel"
              >
                Cancel
              </button>
              <button type="submit" className="tw-btn-primary">
                Apply
              </button>
            </div>
          </form>
        </div>

        <ModalLoader show={isLoading} />
      </div>
    </Modal>
  );
};

export default ApplyJob;
