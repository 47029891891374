import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { TabContent } from "reactstrap";
import { Dropdown } from "react-bootstrap";
import { CSVLink } from "react-csv";
import currency from "currency.js";
import moment from "moment";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import Loader from "../Loader";
import { PDFIcon, ExcelIcon, ExportIcon, SummaryCloseIcon } from "../icons";
import { useDownloadExcel } from "../useDownloadExcel";
import { allMonths, useCookie } from "../../utils";
import DeductionHeadReport from "./DeductionHeadReport";
import DeductionsTableReport from "./DeductionTableReport";

function DeductionReports() {
  const branch_id = useCookie("branch_id");

  const date = new Date();
  const [activeTab, setActiveTab] = useState(null);
  const [deductions, setDeductions] = useState([]);
  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: date.getMonth() + 1,
    payrollYear: date.getFullYear(),
  });
  const [payroll, setPayroll] = useState([]);
  const [totalNetpay, setTotalNetPay] = useState(0);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [excelData, setExcelData] = useState([]);

  const [groupName, setGroupName] = useState([]);

  const [IsGroup, setIsGroup] = useState(false);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  useEffect(() => {
    getPayroll();

    return () => {};
  }, [payrollDate]);

  async function getPayroll() {
    try {
      setIsLoading(true);
      const token = "dummy token";
      const payrollResonse = await fetch(
        `${URLS.backendPayroll}/get-payroll/${branch_id}?payrollMonth=${payrollDate.payrollMonth}&payrollYear=${payrollDate.payrollYear}&employeeType=All`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      const payrollResult = await payrollResonse.json();
      const { payroll } = payrollResult;
      // console.log(payroll);
      // console.log(payroll[0]?.deductions);
      if (payroll.length) {
        setDeductions(payroll[0]?.deductions);
        // setActiveTab(payroll[0]?.deductions[0]?.slug);
        setGroupName(payroll[0]?.DeductionGroupName);
        setPayroll(payroll);
        const total = payroll
          .map((el) => {
            return el.deductions.map((p) => parseFloat(p.total));
          })
          .flat()
          .reduce((cur, pre) => parseFloat(cur) + parseFloat(pre), 0);

        setTotalNetPay(total);
      } else {
        setPayroll([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  const calculatePensionBal = ({ employee, normal }) => {
    const companyPension = employee.deductions
      .filter((el) => el.slug === "company-pension")
      .map((deduction, index) => deduction.total)
      .reduce((a, b) => a + b, 0);

    const pension = employee.deductions
      .filter((el) => el.groupName === activeTab)
      .map((el) => el.total)
      .reduce((a, b) => a + b, 0);

    return normal
      ? companyPension + pension
      : currency(companyPension + pension, {
          symbol: "",
        }).format();
  };

  const onDownloadExcelData = async () => {
    let headers = deductions.map((setting, index) => [setting.name]);

    const semiHead = IsGroup
      ? groupName
          .filter((el) => el === activeTab)
          .map((setting, index) => setting)
      : headers;

    headers = ["No", "Name", ...semiHead];

    const pensionAmount = ({ employee }) => {
      let data = employee.deductions.map((deduction) => deduction.total);

      if (IsGroup) {
        data = employee.deductions
          .filter((el) => el.groupName === activeTab)
          .map((deduction) => deduction.total)
          .reduce((a, b) => a + b, 0);
      }

      return data;
    };

    let exData = payroll.map((employee, index) => [
      index + 1,
      employee.user?.personalInfo?.name.first +
        " " +
        employee.user?.personalInfo?.name.last,
      pensionAmount({ employee }),
      // ...employee.deductions.map((deduction) => deduction.total),
    ]);

    const date = `Date Prepared: ${moment(new Date()).format(
      "MMMM DD yyyy k:mm:ss z"
    )}`;

    const total = payroll
      .map((el) => {
        return el.deductions.map((p) => parseFloat(p.total));
      })
      .flat()
      .reduce((cur, pre) => parseFloat(cur) + parseFloat(pre));

    exData = [
      // [company],
      [
        `Deductions Report for ${allMonths[payrollDate.payrollMonth - 1]} ${
          payrollDate.payrollYear
        }`,
      ],
      [date],
      [""],
      headers,
      ...exData,
      [""],
      ["Deductions Summary"],
      [
        "Total Deductions Amount",
        currency(total, {
          symbol: "",
        }).format(),
      ],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  const onDownloadExcelPensionData = async () => {
    let headers = [
      "No",
      "Name",
      "Pension Admin",
      "Pension Number",
      "Employee Contribution",
      "Employer Contribution",
      "Total",
    ];

    const pensionAmount = ({ employee }) => {
      let data = employee.deductions
        .filter((el) => el.slug === "pension")
        .map((deduction) => deduction.total);

      if (IsGroup) {
        data = employee.deductions
          .filter((el) => el.groupName === activeTab)
          .map((deduction) => deduction.total)
          .reduce((a, b) => a + b, 0);
      }

      return data;
    };

    let exData = payroll.map((employee, index) => [
      index + 1,
      employee.user?.personalInfo?.name.first +
        " " +
        employee.user?.personalInfo?.name.last,
      employee.user?.bankDetails?.pensionAdmin ?? "...",
      `=""${employee.user?.bankDetails?.pensionNumber}""` ?? "...",

      pensionAmount({ employee }),

      ...employee.deductions
        .filter((el) => el.slug === "company-pension")
        .map((deduction) => deduction.total),

      IsGroup
        ? calculatePensionBal({ employee, normal: true })
        : employee.penTotal,
    ]);

    const date = `Date Prepared: ${moment(new Date()).format(
      "MMMM DD yyyy k:mm:ss z"
    )}`;

    // const total = payroll
    //   .map((el) => {
    //     return el.deductions.map((p) => parseFloat(p.total));
    //   })
    //   .flat()
    //   .reduce((cur, pre) => parseFloat(cur) + parseFloat(pre));

    exData = [
      // [company],
      [
        `Deductions Report for ${allMonths[payrollDate.payrollMonth - 1]} ${
          payrollDate.payrollYear
        }`,
      ],
      [date],
      [""],
      headers,
      ...exData,
      [""],
      // ["Deductions Summary"],
      // [
      //   "Total Deductions Amount",
      //   currency(total, {
      //     symbol: "",
      //   }).format(),
      // ],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  const checkActive = () => {
    return (
      activeTab.toLowerCase() === "pension" ||
      activeTab.toLowerCase() === "pensions"
    );
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12 col-12 text-capitalize">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Deduction Reports</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="col-sm-5 col-5"></div>
              {/*<div className="col-sm-7 col-7 text-right m-b-30"></div>*/}
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}
            <div className="p-3 col-sm-12 col-12 p-0">
              {isLoading && <Loader />}
              <div className="d-flex justify-content-between my-4">
                <div className="d-flex">
                  <div className="form-inline">
                    <label>Month</label>
                    <select
                      className="form-control mx-2"
                      value={payrollDate.payrollMonth}
                      onChange={(e) => {
                        setPayrollDate({
                          payrollMonth: e.target.value,
                          payrollYear: payrollDate.payrollYear,
                        });
                        // getPayroll();
                      }}
                    >
                      {[...Array(12)].map((e, index) => (
                        <option key={index}>{index + 1}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-inline ml-3">
                    <label>Year</label>
                    <input
                      type="number"
                      style={{ width: "100px" }}
                      className="form-control mx-2"
                      value={payrollDate.payrollYear}
                      onChange={(e) => {
                        setPayrollDate({
                          payrollMonth: payrollDate.payrollMonth,
                          payrollYear: e.target.value,
                        });
                        // getPayroll();
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <div className="mx-4">
                    <CSVLink
                      className="btn print d-none"
                      filename={`${
                        activeTab === "pension" ? "Pension" : "Deductions"
                      } Report(${moment(new Date()).format(
                        "DD-MMM-yyyy hh:mm:ss a"
                      )}).csv`}
                      data={excelData}
                      ref={CSVLinkRef}
                    />

                    {deductions.length > 0 && (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          className="print"
                          disabled={isfetchingExcel}
                          bsPrefix=""
                        >
                          <span className="mx-2">Export</span>
                          <ExportIcon color="#008000" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className="text-center"
                        >
                          <Dropdown.Item
                            as="div"
                            onClick={() =>
                              checkActive()
                                ? onDownloadExcelPensionData()
                                : onDownloadExcelData()
                            }
                            className="p-cursor"
                          >
                            Excel <ExcelIcon color="#008000" />
                          </Dropdown.Item>
                          <Dropdown.Item as="div">
                            <a
                              href={`${URLS.backendPayroll}/get-payroll/PDF/${branch_id}?payrollMonth=${payrollDate.payrollMonth}&payrollYear=${payrollDate.payrollYear}&doc=deductions`}
                              target="blank"
                              className="text-dark"
                            >
                              PDF
                              <PDFIcon color="#ff0000" />
                            </a>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                  <button
                    onClick={() => setBtnSummaryActive(!btnSummryActive)}
                    className="btn summaryBtn "
                  >
                    Show Summary
                  </button>
                </div>
              </div>

              <DeductionHeadReport
                deductions={deductions}
                activeTab={activeTab}
                toggle={toggle}
                groupName={groupName}
                setIsGroup={setIsGroup}
              />

              {activeTab ? (
                <TabContent activeTab={activeTab} className="pt-0">
                  <DeductionsTableReport
                    activeTab={activeTab}
                    deductions={deductions}
                    payroll={payroll}
                    groupName={groupName}
                    IsGroup={IsGroup}
                    checkActive={checkActive}
                    calculatePensionBal={calculatePensionBal}
                  />
                </TabContent>
              ) : (
                <div
                  className="w-100 d-flex justify-content-center align-items-center bg-white"
                  style={{ height: "250px" }}
                >
                  <p>No Records</p>
                </div>
              )}
            </div>

            <div
              className={
                btnSummryActive
                  ? `summaryNew border-top open`
                  : `summaryNew border-top`
              }
            >
              <div className="row justify-content-between mt-4">
                <div className="col">
                  <div className="my-3">
                    <div className="d-flex gap-3 align-items-center mb-4">
                      <h4 className="m-0">Total Deduction Amount</h4>{" "}
                      <hr className="flex-grow-1 m-0" />
                      {btnSummryActive && (
                        <button
                          onClick={() => setBtnSummaryActive(!btnSummryActive)}
                          className="btn p-0"
                        >
                          <SummaryCloseIcon />
                        </button>
                      )}
                    </div>

                    <div
                      className="gridCont mb-3 three-col-grid"
                      style={{ gridTemplateRows: "unset" }}
                    >
                      {/*  */}
                      <div className="gridChild gridChildBorderLeftBlue">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {deductions.length > 0
                              ? currency(totalNetpay, {
                                  symbol: "",
                                }).format()
                              : currency(0, {
                                  symbol: "",
                                }).format()}
                          </p>
                          <p className="gridChld2">Total Deduction Amount</p>
                        </div>
                      </div>
                      {/*  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DeductionReports));
