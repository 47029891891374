import React, { useState } from "react";
import reloadbtn from "../../assets/img/Reload-btn.svg";
import plusicon from "../../assets/img/Plus.svg";
import search from "../../assets/img/search.svg";
import GoalLibrary from "./GoalLibrary";
import GoalProgress from "./GoalProgress";
import chevronleft from "../../assets/img/chevron-right.svg";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import france from "../../assets/img/flags/fr.png";
import english from "../../assets/img/flags/gb.png";
import { Close } from "@mui/icons-material";

const GoalManagement = () => {
  const [modal, setModal] = useState(false);
  const [activelang, setactivelang] = useState("english");
  const toggleModal = () => setModal(!modal);

  const [state, setState] = useState({
    applications: [],
    activeView: "library",
    job: {},
  });

  const { job, activeView, applications } = state;

  const reloadpage = () => {
    window.location.reload();
  };

  const handleToggle = (view) => {
    setState((prevState) => ({ ...prevState, activeView: view }));
  };

  const headers = [
    {
      name: "Goal Library",
      statusName: "library",
    },
    {
      name: "Goal Progresss",
      statusName: "progress",
    },
  ];

  return (
    <div className="goal-management d-flex flex-col gap-5">
      <div className="d-flex align-items-center gap-md">
        <div className="border tw-rounded-lg search-goal">
          <img width={18} src={search} alt="mui-search" />
          <input
            type="text"
            placeholder="Search goal..."
            className="input-none"
          />
        </div>
        <select className="select-category border">
          <option selected value="Sort by Category">
            Sort by Category
          </option>
          <option value="Head of marketing">Head of marketing</option>
          <option value="HR Manager">HR Manager</option>
          <option value="Marketing employee">Marketing employee</option>
          <option value="Procurement Division">Procurement Division</option>
        </select>
        <button onClick={reloadpage}>
          <img src={reloadbtn} width={40} alt="" />
        </button>
        <div className="">
          <button className="tw-btn" onClick={toggleModal}>
            <img src={plusicon} width={18} alt="" />
            <p>New Goal Plan</p>
          </button>
        </div>
      </div>
      <div className="p-4 tw-rounded-lg border">
        <div className="d-flex flex-col gap-md">
          <div className="d-flex align-items-center gap-lg">
            {headers.map((el) => (
              <div
                key={el.statusName}
                className={`navtab ${
                  activeView === el.statusName ? "active_navtab" : "navtab"
                }`}
                onClick={() => handleToggle(el.statusName)}
              >
                {el.name}
              </div>
            ))}
          </div>

          <div className="mt-4 management-switcher">
            {activeView === "library" && (
              <GoalLibrary applications={applications} />
            )}
            {activeView === "progress" && (
              <GoalProgress applications={applications} />
            )}
          </div>
        </div>
      </div>
      <div className="d-flex tw-pagination py-4 align-items-center justify-content-between gap-lg flex-wrap">
        <div className="d-flex align-items-center gap-md">
          <p className="date-text-md">Show</p>
          <select name="" id="" className="show-rows-select px-2">
            <option selected value="10 rows">
              10 rows
            </option>
            <option value="20 rows">20 rows</option>
            <option value="30 rows">30 rows</option>
            <option value="40 rows">40 rows</option>
            <option value="50 rows">50 rows</option>
            <option value="60 rows">60 rows</option>
            <option value="70 rows">70 rows</option>
            <option value="80 rows">80 rows</option>
            <option value="90 rows">90 rows</option>
            <option value="100 rows">100 rows</option>
          </select>
        </div>
        <div className="tw-pagination-wrapper">
          <div className="tw-pagination">
            <div className="tw-pagination-item paginate-active">1</div>
            <div className="tw-pagination-item">2</div>
            <div className="tw-pagination-item">3</div>
            <div className="tw-pagination-item">4</div>
            <div className="tw-pagination-item">5</div>
            <div className="tw-pagination-item">6</div>
            <p>...</p>
            <div className="tw-pagination-item">10</div>
            <div className="tw-pagination-item">
              <img src={chevronleft} width={15} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* Modal Component */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <div className="tw-modal-sm tw-rounded-lg">
          <div className="tw-modal-header">
            <div className="">
              <h1>New Goal Template</h1>
              <p>Create a new goal</p>
            </div>
            <button className="" onClick={toggleModal}>
              <Close />
            </button>
          </div>
          <div className="tw-modal-sm-body">
            <div className="tw-form-group">
              <label className="tw-form-labels">Goal Name:</label>
              <input
                className="tw-form border"
                type="text"
                name="goal_name"
                placeholder="Type your goal name"
                // value={application.email}
                // onChange={this.updateApplicationText.bind(this)}
                required
              />
            </div>
            <div className="tw-form-group">
              <label className="tw-form-labels">Language:</label>
              <div className="d-flex gap-lg">
                <div
                  className={`tw-language-choice d-flex align-items-center gap-md ${
                    activelang === "french" ? "tw-language-choice-active" : ""
                  }`}
                  onClick={() => setactivelang("french")}
                >
                  <input
                    type="radio"
                    name="language"
                    id="french"
                    checked={activelang === "french"}
                    readOnly
                  />
                  <p>French</p>
                  <img src={france} alt="French" />
                </div>

                <div
                  className={`tw-language-choice d-flex align-items-center gap-md ${
                    activelang === "english" ? "tw-language-choice-active" : ""
                  }`}
                  onClick={() => setactivelang("english")}
                >
                  <input
                    type="radio"
                    name="language"
                    id="english"
                    checked={activelang === "english"}
                    readOnly
                  />
                  <p>English</p>
                  <img src={english} alt="English" />
                </div>
              </div>
              <div className="tw-form-group">
                <label className="tw-form-labels">Instruction to User:</label>
                <textarea
                  className="tw-form border"
                  name="instructions"
                  rows="4"
                  style={{ height: 120 }}
                  placeholder="Write some details about the instructions"
                  // value={application.email}
                  // onChange={this.updateApplicationText.bind(this)}
                  required
                ></textarea>
              </div>
              <div className="tw-form-group">
                <label className="tw-form-labels">Goal Category</label>
                <select
                  className="tw-form border"
                  name="goal_category"
                  // value={application.email}
                  // onChange={this.updateApplicationText.bind(this)}
                  required
                >
                  <option selected value="Head of marketing">
                    Head of marketing
                  </option>
                  <option value="HR Manager">HR Manager</option>
                  <option value="Marketing employee">Marketing employee</option>
                  <option value="Procurement Division">
                    Procurement Division
                  </option>
                </select>
              </div>
              <div className="d-flex mt-2 gap-md w-100 align-items-center">
                <div className="tw-form-group">
                  <label className="tw-form-labels">Start Date:</label>
                  <input
                    type="date"
                    placeholder="Start Date"
                    className="tw-date border"
                    name="date"
                    id="date"
                  />
                </div>
                <div className="tw-form-group">
                  <label className="tw-form-labels">End Date::</label>
                  <input
                    type="date"
                    placeholder="End Date"
                    className="tw-date border"
                    name="date"
                    id="date"
                  />
                </div>
              </div>
              <hr />
              <button type="submit" className="tw-btn w-100">
                Create Goal Template
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Modal Component */}
    </div>
  );
};

export default GoalManagement;
