import React, { useEffect, useMemo, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import moment from "moment";
import currency from "currency.js";
import { URLS } from "../../urls";
import DatePicker from "react-datetime";
import { checkDates, checkDays, useCookie } from "../../../utils";
import Select from "react-select";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { converArrayToObject } from "../../../utils";
import { DisapprovedCheckIcon } from "../../icons";
import CurrencyInput from "react-currency-input-field";

export function ApplyUserAllowanceModal({
  setShowAddOvertimeModal,
  refetch,
  setSelected,
  openApproval,
}) {
  const cookies = new Cookies();
  const userid = cookies.get("userId");
  const branch_id = useCookie("branch_id");
  const [state, setState] = useState({
    overtimeDate: new Date(),
    hours: 0,
    employeeId: "",
    description: "",
    allowanceId: "",
    branch_id,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [employee, setEmployees] = useState({});
  //   const [allowance, setAllowance] = useState([]);
  const [chechedAllowance, setChechedAllowance] = useState([]);
  const [contracts, setcontracts] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [addedArray, setAddedArray] = useState([]);
  const [useCustom, setUseCustom] = useState(false);
  const [overTimeAmount, setOverTimeAmount] = useState(0);
  const [holdIt, setHoldIt] = useState({
    overtimeDate: new Date(),
    description: "",
    allowanceId: "",
    amount: 0,
    name: "",
    job: "",
    tittle: "",
    overtimeDateTo: new Date(),
    days: 0,
  });

  const getEmployees = () => {
    return new Promise(async (resolve, reject) => {
      const token = "dummy token";
      fetch(`${URLS.backendEmployees}/getByUserId/${userid}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setEmployees(result.employee);
          setState({
            ...state,
            employeeId: result?.employee?.userId || "",
          });
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  const getContracts = () => {
    return new Promise(async (resolve, reject) => {
      const token = "dummy token";
      fetch(`${URLS.backendContracts}/get`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setcontracts(result?.contracts);
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  const getJobs = () => {
    return new Promise(async (resolve, reject) => {
      const token = "dummy token";
      fetch(`${URLS.mainSiteBackend}/job`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          const MappedJobs = result?.data?.jobs?.map((el) => ({
            label: el?.title,
            value: el?.jobNumber,
          }));
          setJobs(MappedJobs);
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  useEffect(() => {
    const setUp = async () => {
      setLoading(true);
      try {
        await Promise.all([getEmployees(), getContracts(), getJobs()]);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    setUp();
    return () => {};
  }, []);

  useEffect(() => {
    const total = addedArray?.reduce(
      (c, p) => parseFloat(c) + parseFloat(p.amount),
      0
    );
    const hours = addedArray?.reduce(
      (c, p) => parseFloat(c) + parseFloat(p.days),
      0
    );

    setState((st) => ({ ...st, hours, total }));
  }, [addedArray]);

  useEffect(() => {
    getAllowanceForUser();
  }, [employee]);

  const allowanceForSelectedUser = useMemo(() => {
    return chechedAllowance
      .filter((el) => el)
      .map((el) => ({ ...el, label: el.name, value: el._id }));
  }, [chechedAllowance]);

  const getAllowanceForUser = async () => {
    let usersAllowance = [];
    let maturity = true;
    let duration = true;
    const token = "dummy token";

    const data = await fetch(
      `${URLS.backendAllowance}/branch-earned/${branch_id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    const { allowance } = await data.json();

    // Find the selected user
    const { jobInfo } = employee;

    // get the contract name from the selected user
    employee.usedcontract = contracts.find(
      (el) => el._id === jobInfo?.contractType
    )?.contractName;

    allowance &&
      allowance.forEach((el) => {
        // Check the maturity period of the allowance
        if (el["paymentPeriod"] === "CustomPeriod") {
          const getTime = el["maturityPeriod"]?.split(" ");
          maturity = getTime
            ? checkDates(jobInfo?.startDate, getTime[0], getTime[1])
            : false;
        }

        // Check for Duration Period of the allowance
        if (el["durationType"] === "CustomDuration") {
          duration = checkDays({
            startDate: el["dateRange"]["startDate"],
            endDate: el["dateRange"]["endDate"],
          });
        }

        if (maturity && duration) {
          if (
            el["DeductType"] === "Employees" &&
            el["selectedDeducts"]?.includes(employee["userId"])
          ) {
            usersAllowance.push(el);
          } else if (
            el["DeductType"] === "Job Grade" &&
            el["selectedDeducts"]?.includes(jobInfo?.grade?._id)
          ) {
            usersAllowance.push(el);
          } else if (
            el["DeductType"] === "Contract" &&
            el["selectedDeducts"]?.includes(jobInfo?.contractType)
          ) {
            usersAllowance.push(el);
          } else if (el["DeductType"] === "All") {
            usersAllowance.push(el);
          } else if (
            el["DeductType"] === "Permanent" &&
            employee["usedcontract"] === undefined
          ) {
            usersAllowance.push(el);
          }
        }
      });
    setChechedAllowance(usersAllowance);

    //console.log(usersAllowance);
  };

  const getAllowanceMoney = (bene, hours) => {
    let singleEmployee = employee;
    let totalSum = 0;
    let sum = 0;
    let total = 0;
    let perDay = false;

    singleEmployee = {
      ...singleEmployee?.personalInfo,
      ...singleEmployee?.jobInfo,
      ...converArrayToObject(
        singleEmployee?.jobInfo?.usergrade?.custom,
        "value",
        "name",
        true
      ),
      ...singleEmployee?.jobInfo?.usergrade?.salary,
    };

    bene = {
      ...bene,
      ...converArrayToObject(bene["customSettings"], "status", "name"),
    };

    // eslint-disable-next-line no-unused-vars
    for (const key in bene) {
      // When its Percentage
      if (
        key !== "enabled" &&
        key !== "earned" &&
        bene["paymentType"] === "Percentage" &&
        bene[key] === true
      ) {
        sum += parseFloat(singleEmployee[key] ? singleEmployee[key] : 0);
      }

      // When is Static
      if (bene["paymentType"] === "Static") {
        total = bene.percentage;
      }
    }

    // Calculating total by Percentage
    if (bene["paymentType"] === "Percentage") {
      if (bene["calculatePer"] === "hour" || bene["calculatePer"] === "day") {
        total = ((bene.percentage / 100) * sum).toFixed(2);
        // total = total * hours;
        perDay = true;
        totalSum += parseFloat(total);
      } else {
        total = ((bene.percentage / 100) * sum).toFixed(2);
        totalSum += parseFloat(total);
      }
    }

    // Calculating total by Static Amount
    if (bene["paymentType"] === "Static") {
      if (bene["calculatePer"] === "hour" || bene["calculatePer"] === "day") {
        // total = total * hours;
        perDay = true;
        totalSum = parseFloat(total);
      } else {
        totalSum = parseFloat(total);
      }
    }

    // console.log(totalSum);
    return { totalSum, perDay };
  };

  const addMore = (e) => {
    e.preventDefault();

    var from = moment(
      moment(new Date(holdIt.overtimeDate)).format("YYYY-MM-DD"),
      "YYYY-MM-DD"
    );
    var to = moment(
      moment(new Date(holdIt.overtimeDateTo)).format("YYYY-MM-DD"),
      "YYYY-MM-DD"
    );
    var duration = moment.duration(to.diff(from));
    var days = duration.asDays() + 1;

    // const found = addedArray.find(
    //   (el) => el?.allowanceId === holdIt.allowanceId && el?.job === holdIt.job
    // );

    // if (found) {
    //   return toast("Already Added.", {
    //     type: "error",
    //     position: "top-right",
    //   });
    // }

    const selected = allowanceForSelectedUser?.find(
      (el) => el.value === holdIt.allowanceId
    );
    // const amount = getAllowanceMoney(selected, days);

    let total = 0;
    const { totalSum, perDay } = getAllowanceMoney(selected, days);

    if (useCustom) {
      if (perDay) {
        total = overTimeAmount * days;
      } else {
        total = overTimeAmount;
      }
    } else {
      if (perDay) {
        total = totalSum * days;
      } else {
        total = totalSum;
      }
    }

    setAddedArray((el) =>
      el.concat({
        overtimeDate: holdIt.overtimeDate,
        description: holdIt.description,
        allowanceId: holdIt.allowanceId,
        // amount,
        amount: total,
        name: allowanceForSelectedUser?.find(
          (item) => item?.value === holdIt.allowanceId
        )?.label,
        job: holdIt.job,
        tittle: holdIt.tittle,
        days,
        overtimeDateTo: holdIt.overtimeDateTo,
      })
    );
    setHoldIt({
      overtimeDate: new Date(),
      description: "",
      allowanceId: "",
      amount: 0,
      name: "",
      job: "",
      tittle: "",
      overtimeDateTo: new Date(),
      days: 0,
    });
  };

  const remove = (ind) => {
    // const remove = addedArray.filter(
    //   (el) => el?.allowanceId !== id && el?.job !== jobId
    // );
    const remove = addedArray.filter((el, index) => index !== ind);
    setAddedArray(remove);
  };

  const validationTo = (currentDate) => {
    if (holdIt.overtimeDate) {
      return currentDate.isAfter(
        moment(holdIt.overtimeDate, "DD MMM, YYYY").add(-1, "days")
      );
    }
  };

  const validationFrom = (currentDate) => {
    return currentDate.isBefore(
      moment(new Date(), "DD MMM, YYYY").add(0, "days")
    );
  };

  const submit = (e) => {
    e.preventDefault();
    console.log("in submit");
    setLoading(true);

    // console.log({ overtime: { state, addedArray } });
    const token = "dummy token";
    fetch(`${URLS.backendOvertime}/add-new`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ overtime: { state, addedArray } }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          toast("Success", {
            type: "success",
            position: "top-right",
          });
          refetch();
          setShowAddOvertimeModal(false);

          setSelected({
            ...result.overtime,
            newStatus: "Sent for Approval",
          });
          openApproval(true);
        } else {
          toast(result?.overtime, {
            type: "error",
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        toast("Error unable to perform  action", {
          type: "error",
          position: "top-right",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!useCustom) {
      setOverTimeAmount(0);
    }
  }, [useCustom]);

  return (
    <Modal
      className="modal custom-modal fade"
      show={true}
      centered
      onHide={() => setShowAddOvertimeModal(false)}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Apply For Allowance</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form>
          <div className="row">
            <div className="d-flex col-6 mb-4">
              <label className="mx-3">Use Custom Amount</label>
              <div
                className="onoffswitch ml-0"
                onClick={(e) => {
                  setUseCustom(!useCustom);
                }}
              >
                <input
                  type="checkbox"
                  name="onoffswitch"
                  className="onoffswitch-checkbox"
                  checked={useCustom}
                />
                <label className="onoffswitch-label">
                  <span className="onoffswitch-inner"></span>
                  <span className="onoffswitch-switch"></span>
                </label>
              </div>
            </div>

            <div className="d-flex align-items-baseline col-6 mb-4">
              <label>Amount</label>

              <CurrencyInput
                className="form-control mx-4"
                value={overTimeAmount}
                onValueChange={(value, name) => {
                  setOverTimeAmount(value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-8">
              <label>
                Allowance <span className="text-danger">*</span>
              </label>
              <Select
                key={holdIt.allowanceId}
                closeMenuOnSelect={true}
                isSearchable
                required
                classNamePrefix="form-control"
                value={allowanceForSelectedUser?.find(
                  (el) => el.value === holdIt.allowanceId
                )}
                onChange={(selected) => {
                  setHoldIt({
                    ...holdIt,
                    allowanceId: selected?.value || "",
                  });
                }}
                options={allowanceForSelectedUser}
                isClearable
                isDisabled={state?.employeeId === ""}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-6">
              <label>
                From Date <span className="text-danger">*</span>
              </label>
              <div className="cal-icon">
                <DatePicker
                  value={holdIt.overtimeDate}
                  dateFormat="DD MMM, YYYY"
                  onChange={(date) => {
                    setHoldIt({
                      ...holdIt,
                      overtimeDate: date,
                      overtimeDateTo: date,
                    });
                  }}
                  closeOnSelect={true}
                  timeFormat={false}
                  required={true}
                  isValidDate={validationFrom}
                />
              </div>
            </div>
            <div className="form-group col-6">
              <label>
                To Date <span className="text-danger">*</span>
              </label>
              <div className="cal-icon">
                <DatePicker
                  value={holdIt.overtimeDateTo}
                  dateFormat="DD MMM, YYYY"
                  onChange={(date) => {
                    setHoldIt({
                      ...holdIt,
                      overtimeDateTo: date,
                    });
                  }}
                  closeOnSelect={true}
                  timeFormat={false}
                  isValidDate={validationTo}
                />
              </div>
            </div>
            {/* //o: new Date(), */}
          </div>
          <div className="row">
            <div className="form-group col-6" style={{ zIndex: "99" }}>
              <label>
                Job <span className="text-danger">*</span>
              </label>
              <Select
                key={holdIt.job}
                closeMenuOnSelect={true}
                isSearchable
                required
                classNamePrefix="form-control"
                value={jobs?.find((el) => el.value === holdIt.job)}
                onChange={(selected) => {
                  setHoldIt({
                    ...holdIt,
                    job: selected?.value || "",
                    tittle: selected?.label || "",
                  });
                }}
                options={jobs}
                isClearable
                isDisabled={
                  state?.employeeId === "" || holdIt.allowanceId === ""
                }
              />
            </div>
            <div className="form-group col-6">
              <label>Job Done</label>
              <textarea
                rows="2"
                className="form-control"
                onChange={(e) => {
                  setHoldIt({
                    ...holdIt,
                    description: e.target.value,
                  });
                }}
                value={holdIt.description}
              ></textarea>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              disabled={holdIt.allowanceId === "" || holdIt.job === ""}
              onClick={addMore}
              className="btn btn-primary mb-3"
            >
              Add
            </button>
          </div>

          {addedArray?.length > 0 ? (
            <Table
              striped="true"
              responsive
              sticky="true"
              className="product-table text-nowrap"
            >
              <thead>
                <tr>
                  <th>Allowance</th>
                  <th>Job</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Days</th>
                  <th>Amount</th>
                  <th>Job Done</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {addedArray?.map((el, i) => (
                  <tr key={i}>
                    <td>{el?.name}</td>
                    <td>{el?.tittle}</td>
                    <td>
                      {moment(new Date(el?.overtimeDate)).format(
                        "MMMM DD yyyy"
                      )}
                    </td>
                    <td>
                      {moment(new Date(el?.overtimeDateTo)).format(
                        "MMMM DD yyyy"
                      )}
                    </td>
                    <td>{el?.days}</td>
                    <td>
                      {currency(el?.amount, {
                        symbol: "",
                      }).format()}
                    </td>
                    <td>{el?.description}</td>
                    <td>
                      <p className="p-cursor" onClick={() => remove(i)}>
                        {DisapprovedCheckIcon()}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : null}

          <div className="summaryNew border-top">
            <div
              className="gridCont three-col-grid"
              style={{ gridTemplateRows: "unset" }}
            >
              <div></div>
              <div></div>
              <div className="gridChild gridChildBorderLeftDarkerGreen">
                <div className="gridChildLeft">
                  <p className="gridChld1">
                    {currency(state.total, {
                      symbol: "",
                    }).format()}
                  </p>
                  <p className="gridChld2">Total Allowance </p>
                </div>
              </div>
            </div>
          </div>

          <div className="submit-section">
            <button
              onClick={submit}
              className="btn btn-primary submit-btn"
              disabled={loading || addedArray?.length < 1}
            >
              {loading ? "Please wait..." : "Submit"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
