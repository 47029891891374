import React, { useEffect, useState } from "react";
import { allMonths, fetchActionsUtil, useCookie } from "../../utils";
import { URLS } from "../urls";
import ModalLoader from "../ModalLoader";
import currency from "currency.js";
import TableComponent from "../TableComponent";
import { SummaryChartIconUp, SummaryCloseIcon } from "../icons";

const SalaryHistory = ({ eId }) => {
  const token = useCookie("token");
  const date = new Date();

  const [payroll, setPayroll] = useState([]);
  const [companySettings, setCompanySettings] = useState({});
  const [retaireIt, setRetaireIt] = useState(false);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);

  const [totalGross, setTotalGross] = useState(0);
  const [totalNet, setTotalNet] = useState(0);
  const [totalDeduct, setTotalDeduct] = useState(0);

  const [totalAllowance, setTotalAllowance] = useState(0);
  const [totalBonus, setTotalBonus] = useState(0);
  const [totalBenefit, setTotalBenefit] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: "",
    payrollYear: date.getFullYear(),
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const payrollData = fetchActionsUtil(
        `${URLS.backendPayroll}/getPayrollByEmployeeId/${eId}?payrollMonth=${payrollDate.payrollMonth}&payrollYear=${payrollDate.payrollYear}`,
        "GET",
        token
      );

      const settingData = fetchActionsUtil(
        `${URLS.backendCompanySettings}/get-settings`,
        "POST",
        token
      );

      const [payrollResult, settingResult] = await Promise.all([
        payrollData,
        settingData,
      ]);

      setCompanySettings({
        ...settingResult?.settings,
        extras: settingResult?.settings?.extras === "true" ? true : false,
      });

      setPayroll(payrollResult.payroll);

      const retairement = payroll.find((el) => el.retairefee > 0);
      retairement && setRetaireIt(true);

      if (payrollResult?.payroll?.length > 0) {
        payrollResult.payroll.forEach((el) => {
          setTotalGross((g) => currency(g).add(el.ogGrossPay));
          setTotalNet((n) => currency(n).add(el.netPay));
          setTotalDeduct((d) => currency(d).add(el.totalDeduction));

          setTotalAllowance((g) => currency(g).add(el.totalAllowances));
          setTotalBonus((n) => currency(n).add(el.totalBonus));
          setTotalBenefit((d) => currency(d).add(el.totalBenefits));
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [payrollDate]);

  const tableHead = () => {
    payroll[0].settingHeaders = payroll[0]?.settingHeaders
      ? payroll[0]?.settingHeaders
      : {};

    return (
      <thead className="position-sticky top-0">
        <tr>
          <th>No</th>
          <th>Month</th>
          {payroll[0].useExcel === "Use Timesheet" && <th>SFA ID</th>}
          <th>Job Grade</th>

          {companySettings?.extras ? (
            <>
              <th>Days Committed</th>
              <th>Days On</th>
              <th>Transit Days</th>
              <th>Overstay Days</th>
              <th>Arrears Days</th>
            </>
          ) : null}

          <th>Location</th>
          <th>LSP</th>
          {payroll[0].useExcel === "Use Timesheet" && (
            <>
              <th>Days Worked</th>
              <th>Proration</th>
            </>
          )}

          <th>Gross Salary</th>
          {Object.entries(payroll[0]?.settingHeaders).length === 0 ||
          payroll[0]?.settingHeaders?.basic ? (
            <th>Basic</th>
          ) : null}
          {Object.entries(payroll[0]?.settingHeaders).length === 0 ||
          payroll[0]?.settingHeaders?.housing ? (
            <th>Housing</th>
          ) : null}
          {Object.entries(payroll[0]?.settingHeaders).length === 0 ||
          payroll[0]?.settingHeaders?.transport ? (
            <th>Transport</th>
          ) : null}
          {Object.entries(payroll[0]?.settingHeaders).length === 0 ||
          payroll[0]?.settingHeaders?.medical ? (
            <th>Medical</th>
          ) : null}
          {Object.entries(payroll[0]?.settingHeaders).length === 0 ||
          payroll[0]?.settingHeaders?.utility ? (
            <th>Utility</th>
          ) : null}
          {Object.entries(payroll[0]?.settingHeaders).length === 0 ||
          payroll[0]?.settingHeaders?.entertainment ? (
            <th>Entertainment</th>
          ) : null}
          {Object.entries(payroll[0]?.settingHeaders).length === 0 ||
          payroll[0]?.settingHeaders?.dressing ? (
            <th>Dressing</th>
          ) : null}
          {payroll[0]?.customHeaders?.map((el) => (
            <th>{el} </th>
          ))}
          {/* <th>Bonuses</th> */}
          <th>Earned Gross </th>

          {payroll[0]?.deductions
            .filter((el) => !el.groupName)
            .map((setting, index) => {
              return (
                <th className="text-capitalize" key={index}>
                  {setting.name}
                </th>
              );
            })}

          {payroll[0]?.DeductionGroupName.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting}
                <br />{" "}
              </th>
            );
          })}

          {payroll[0]?.bonus
            .filter((el) => !el.groupName)
            .map((setting, index) => {
              return (
                <th className="text-capitalize" key={index}>
                  {setting.name}
                </th>
              );
            })}

          {payroll[0]?.BonusGroupName.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting}
                <br />{" "}
              </th>
            );
          })}

          {payroll[0]?.allowances
            .filter((el) => !el.groupName)
            .map((setting, index) => {
              return (
                <th className="text-capitalize" key={index}>
                  {setting.name}
                </th>
              );
            })}

          {payroll[0]?.AllowanceGroupName.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting}
                <br />{" "}
              </th>
            );
          })}

          {payroll[0]?.benefits
            .filter((el) => !el.groupName)
            .map((setting, index) => {
              return (
                <th className="text-capitalize" key={index}>
                  {setting.name}
                </th>
              );
            })}

          {payroll[0]?.BenefitGroupName.map((setting, index) => {
            return (
              <th className="text-capitalize" key={index}>
                {setting}
                <br />{" "}
              </th>
            );
          })}

          <th>Loan</th>
          {retaireIt && <th>IOU Deductions</th>}
          <th>Attendance Deduction</th>
          <th>Total Deductions</th>
          <th>Total Bonus</th>
          <th>Total Allowance</th>
          <th>Total Benefit</th>
          <th>Remark</th>
          <th>Net Pay</th>
          <th>Payslip</th>
        </tr>
      </thead>
    );
  };

  const tableBodyData = (employee, index) => {
    employee.settingHeaders = employee?.settingHeaders
      ? employee?.settingHeaders
      : {};
    return (
      <>
        <td>{index + 1}</td>
        <td>
          {`${allMonths[employee?.payrollMonth - 1]} ${employee.payrollYear}`}
        </td>
        {employee.useExcel === "Use Timesheet" && <td>{employee.sfa_id}</td>}
        <td>{employee.gradeName}</td>

        {companySettings?.extras ? (
          <>
            <td>
              {employee?.userExtras?.DaysCommitted === ""
                ? 0
                : employee?.userExtras?.DaysCommitted}
            </td>
            <td>
              {employee?.userExtras?.DayOn === ""
                ? 0
                : employee?.userExtras?.DayOn}
            </td>
            <td>
              {employee?.userExtras?.TransitDays === ""
                ? 0
                : employee?.userExtras?.TransitDays}
            </td>
            <td>
              {employee?.userExtras?.OverStayDays === ""
                ? 0
                : employee?.userExtras?.OverStayDays}
            </td>
            <td>
              {employee?.userExtras?.ArrearsDays === ""
                ? 0
                : employee?.userExtras?.ArrearsDays}
            </td>
          </>
        ) : null}

        <td>{employee.usedlocation}</td>
        <td>{employee.usedcontract}</td>
        {employee.useExcel === "Use Timesheet" && (
          <>
            <td>{employee?.totalDaysWorked}</td>
            <td>{parseFloat(employee?.numProration).toFixed(2)}</td>
          </>
        )}
        <td>
          {currency(employee.grossPay, {
            symbol: "",
          }).format()}
        </td>
        {Object.entries(employee?.settingHeaders).length === 0 ||
        employee?.settingHeaders?.basic ? (
          <td>
            {currency(employee.basic, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(employee?.settingHeaders).length === 0 ||
        employee?.settingHeaders?.housing ? (
          <td>
            {currency(employee.housing, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(employee?.settingHeaders).length === 0 ||
        employee?.settingHeaders?.transport ? (
          <td>
            {currency(employee.transport, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(employee?.settingHeaders).length === 0 ||
        employee?.settingHeaders?.medical ? (
          <td>
            {currency(employee.medical, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(employee?.settingHeaders).length === 0 ||
        employee?.settingHeaders?.utility ? (
          <td>
            {currency(employee.utility, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(employee?.settingHeaders).length === 0 ||
        employee?.settingHeaders?.entertainment ? (
          <td>
            {currency(employee.entertainment, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {Object.entries(employee?.settingHeaders).length === 0 ||
        employee?.settingHeaders?.dressing ? (
          <td>
            {currency(employee.dressing, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        {employee?.custom?.map((el) => (
          <td>
            {currency(el?.value, {
              symbol: "",
            }).format()}
          </td>
        ))}
        <td>
          {currency(employee.ogGrossPay, {
            symbol: "",
          }).format()}
        </td>

        {/* <td>{employee.bonuses}</td> */}

        {employee.deductions
          .filter((el) => !el.groupName)
          .map((deduction, index) => {
            return (
              <td key={index}>
                {currency(deduction.total, {
                  symbol: "",
                }).format()}
              </td>
            );
          })}

        {employee.DeductionGroupName.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.deductions
                  ?.filter((el) => el.groupName === e)
                  .map((el) => el.total)
                  .reduce((a, b) => a + b, 0),
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}

        {employee.bonus
          .filter((el) => !el.groupName)
          .map((bouns, index) => {
            return (
              <td key={index}>
                {currency(bouns.total, {
                  symbol: "",
                }).format()}
              </td>
            );
          })}

        {employee.BonusGroupName.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.bouns
                  ?.filter((el) => el.groupName === e)
                  .map((el) => el.total)
                  .reduce((a, b) => a + b, 0),
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}

        {employee.allowances
          .filter((el) => !el.groupName)
          .map((allowance, index) => {
            return (
              <td key={index}>
                {currency(allowance.total, {
                  symbol: "",
                }).format()}
              </td>
            );
          })}

        {employee.AllowanceGroupName.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.allowances
                  ?.filter((el) => el.groupName === e)
                  .map((el) => el.total)
                  .reduce((a, b) => a + b, 0),
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}

        {employee.benefits
          .filter((el) => !el.groupName)
          .map((benefit, index) => {
            return (
              <td key={index}>
                {currency(benefit.total, {
                  symbol: "",
                }).format()}
              </td>
            );
          })}

        {employee.BenefitGroupName.map((e, i) => {
          return (
            <td key={i}>
              {currency(
                employee.benefits
                  ?.filter((el) => el.groupName === e)
                  .map((el) => el.total)
                  .reduce((a, b) => a + b, 0),
                {
                  symbol: "",
                }
              ).format()}
            </td>
          );
        })}

        <td>
          {currency(employee.loanDeduct, {
            symbol: "",
          }).format()}
        </td>
        {retaireIt && (
          <td>
            {employee.remainingRetairment
              ? currency(employee.remainingRetairment, {
                  symbol: "",
                }).format()
              : 0}
          </td>
        )}
        <td>
          {currency(employee.attendanceTotal, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(employee.allTotalDeduction, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(employee.totalBonus, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(employee.totalAllowances, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(employee.totalBenefits, {
            symbol: "",
          }).format()}
        </td>
        {/* <td>{employee.otherDeductions}</td> */}
        <td>{employee.remark}</td>
        <td>
          {currency(employee.netPay, {
            symbol: "",
          }).format()}
        </td>
        <td style={{ fontSize: "22px" }}>
          <a
            className="mx-2 text-primary"
            target="blank"
            href={URLS.backendPayroll + "/view-payslip/" + employee._id}
            title="View slip"
          >
            <i className="la la-eye"></i>
          </a>
        </td>
      </>
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-between my-4">
        <div className="d-flex">
          <div className="form-inline">
            <label>Month</label>
            <select
              className="form-control mx-2"
              value={payrollDate.payrollMonth}
              onChange={(e) =>
                setPayrollDate({
                  payrollMonth: e.target.value,
                  payrollYear: payrollDate.payrollYear,
                })
              }
            >
              <option value={""}>Select Month</option>
              {[...Array(12)].map((e, index) => (
                <option key={index}>{index + 1}</option>
              ))}
            </select>
          </div>
          <div className="form-inline ml-3">
            <label>Year</label>
            <input
              type="number"
              style={{ width: "100px" }}
              className="form-control mx-2"
              value={payrollDate.payrollYear}
              onChange={(e) =>
                setPayrollDate({
                  payrollMonth: payrollDate.payrollMonth,
                  payrollYear: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="d-flex">
          <button
            onClick={() => setBtnSummaryActive(!btnSummryActive)}
            className="btn summaryBtn "
          >
            Show Summary
          </button>
        </div>
      </div>

      {payroll.length > 0 ? (
        <div className="row my-4">
          <TableComponent
            responsive
            striped
            sticky
            tableHeadsFunction={tableHead}
            mainDataArray={payroll}
            tableDataRowFunction={tableBodyData}
            className="product-table text-nowrap"
          />
        </div>
      ) : (
        <div className="d-flex justify-content-center my-4">
          <div>
            <h2>No salary yet</h2>
          </div>
        </div>
      )}

      <div
        className={
          btnSummryActive
            ? `summaryNew border-top open`
            : `summaryNew border-top`
        }
      >
        <div className="row justify-content-between mt-4">
          <div className="col">
            <div className="my-3">
              <div className="d-flex gap-3 align-items-center mb-4">
                <h4 className="m-0">Payroll Summary</h4>{" "}
                <hr className="flex-grow-1 m-0" />
                {btnSummryActive && (
                  <button
                    onClick={() => setBtnSummaryActive(!btnSummryActive)}
                    className="btn p-0"
                  >
                    <SummaryCloseIcon />
                  </button>
                )}
              </div>

              <div
                className="gridCont mb-3 three-col-grid"
                style={{ gridTemplateRows: "unset" }}
              >
                {/*  */}
                <div className="gridChild gridChildBorderLeftBlue">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(totalGross, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Earned Gross </p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                {/*  */}
                <div className="gridChild gridChildBorderLeftDarkerGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(totalAllowance, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Allowance</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>
                {/*  */}
                <div className="gridChild gridChildBorderLeftDarkerGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(totalBonus, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Bonus</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                {/*  */}
              </div>

              {/* Second Layer */}

              <div
                className="gridCont mb-3 three-col-grid"
                style={{ gridTemplateRows: "unset" }}
              >
                {/*  */}

                <div className="gridChild gridChildBorderLeftDarkerGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(totalBenefit, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Benefit</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>
                {/*  */}

                <div className="gridChild gridChildBorderLeftOrange">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(totalDeduct, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Deduction</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>
                {/*  */}
                <div className="gridChild gridChildBorderLeftDarkerGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(totalNet, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Net Amount</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalLoader show={isLoading} />
    </div>
  );
};

export default SalaryHistory;
