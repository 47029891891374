import React, { useState, useEffect } from "react";
import { Col, Form, Row, Modal } from "react-bootstrap";
import Select from "react-select";
import { Fragment } from "react";
import "../../assets/css/jobgrade.css";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import { useCookie } from "../../utils";
import { fetchActionsUtil } from "../../utils";

export const EditEmployeeSalaryModal = (props) => {
  const branch_id = useCookie("branch_id");

  const [salary, setSalary] = useState({
    basic: 0,
    housing: 0,
    medical: 0,
    transport: 0,
    utility: 0,
    dressing: 0,
    entertainment: 0,
  });
  const [totalGrade, setTotalGrade] = useState(0);
  const [jobGrades, setJobGrade] = useState([]);
  const [totaljobGrades, setTptalJobGrade] = useState([]);
  const [salarySetting, setSalarySetting] = useState({});
  const [stopPercentage, setStopPercentage] = useState(true);
  const [singleGrade, setSingleGrade] = useState("");
  const [singleCustom, setSingleCustom] = useState([]);
  const [customSetting, setCustomSetting] = useState([]);
  const [showSetting, setShowSetting] = useState({});
  const [typeOfSalary, setTypeOfSalary] = useState("");
  const [allJobGrade, setAllJobGrade] = useState([]);

  const token = "dummy token";

  const getSalaryByuser = async (id) => {
    const data = await fetchActionsUtil(
      `${URLS.backendemployeeSalary}/single/${id}`,
      "GET",
      token
    );

    const fetchCustom = await fetchActionsUtil(
      `${URLS.backendsalarySetting}/get/${branch_id}`,
      "GET",
      token
    );

    const items = {};
    Object.entries(fetchCustom?.jobGrades[0]).forEach((el) => {
      if (el[1] > 0) {
        items[`${el[0]}`] = true;
      }
    });

    const newCustom = fetchCustom?.jobGrades[0]?.custom?.map((el) => ({
      name: el.name,
      value: 0,
    }));

    const cus =
      data?.jobGrades?.custom?.length > 0 ? data?.jobGrades?.custom : newCustom;
    if (data?.jobGrades) {
      setSalary(data?.jobGrades?.salary);
      setSingleCustom(cus);
      setShowSetting(items);
    }
  };

  const getSalaryByBranch = async (id) => {
    const data = await fetchActionsUtil(
      `${URLS.backendsalarySetting}/get/${id}`,
      "GET",
      token
    );
    if (data?.jobGrades?.length > 0) {
      const {
        basic,
        housing,
        medical,
        transport,
        utility,
        dressing,
        entertainment,
      } = data?.jobGrades[0];

      setSalarySetting({
        basic,
        housing,
        medical,
        transport,
        utility,
        dressing,
        entertainment,
      });
      setCustomSetting(data?.jobGrades[0]?.custom);
      setTypeOfSalary(data?.jobGrades[0]?.typeOfSalary);
    }
  };

  const getJobgrade = async (id) => {
    const data = await fetchActionsUtil(
      `${URLS.backendJobGrades}/get/${id}`,
      "GET",
      token
    );

    if (data?.jobGrades) {
      const jobs = data?.jobGrades?.map((el) => ({
        label: el?.name,
        value: el?._id,
      }));
      const jobsTotals = data?.jobGrades?.map((el) => {
        const tol = Object.values(el?.salary).reduce(
          (p, c) => parseFloat(p) + parseFloat(c),
          0
        );
        const totalcustom =
          el?.custom?.length > 0
            ? el.custom.reduce(
                (c, p) => parseFloat(c) + parseFloat(p?.value),
                0
              )
            : 0;

        return {
          id: el?._id,
          total: tol + totalcustom,
        };
      });
      setTptalJobGrade(jobsTotals);
      setJobGrade(jobs);
      setAllJobGrade(data?.jobGrades);
    }
  };

  useEffect(() => {
    getSalaryByuser(props.user_id);
    getSalaryByBranch(branch_id);
    getJobgrade(branch_id);
    setSingleGrade(props.jobgradeid);
  }, [props.user_id, props.jobgradeid]);

  useEffect(() => {
    if (stopPercentage) {
      const tol = Object.values(salary).reduce(
        (p, c) => parseFloat(p) + parseFloat(c),
        0
      );
      // console.log(singleCustom);
      const totalcustom =
        singleCustom?.length > 0
          ? singleCustom?.reduce(
              (c, p) => parseFloat(c) + parseFloat(p?.value),
              0
            )
          : 0;

      setTotalGrade(tol + totalcustom);
    }
  }, [salary, stopPercentage, singleCustom]);

  const updateSalaryInputs = (value, selectedJob) => {
    let grade = {};
    let cus = [];

    if (typeOfSalary === "Percentage") {
      Object.entries({
        ...salarySetting,
      }).forEach((el) => {
        if (el[1] > 0) {
          const money = (parseFloat(el[1]) / 100) * parseFloat(value);
          grade[`${el[0]}`] = money;
        }
      });
      cus = customSetting.map((el) => {
        if (el?.percentage > 0) {
          const money = (parseFloat(el?.percentage) / 100) * parseFloat(value);
          return { name: el?.name, value: money };
        } else {
          return { name: el?.name, value: 0 };
        }
      });
    } else if (selectedJob && typeOfSalary !== "Percentage") {
      grade = selectedJob?.salary;
      cus = selectedJob?.custom;
    }

    setSalary({ ...grade });
    setSingleCustom(cus);
  };

  const calculatePercentage = (value) => {
    setTotalGrade(value);
    setStopPercentage(false);
    updateSalaryInputs(value);
  };

  const onChangeInputs = (name, value) => {
    setStopPercentage(true);
    setSalary({ ...salary, [name]: value });
  };

  const onChangeCustom = (name, value) => {
    setStopPercentage(true);

    const all = singleCustom.map((el, id) => {
      if (el.name === name) {
        return { ...el, value };
      } else {
        return el;
      }
    });

    setSingleCustom(all);
  };

  const onSelectedGrade = (item) => {
    setSingleGrade(item);
    const slay = totaljobGrades.find((el) => el.id === item).total;
    const selectedJob = allJobGrade.find((el) => el._id === item);

    setSingleCustom(selectedJob.custom);
    updateSalaryInputs(slay, selectedJob);
    setTotalGrade(slay);
  };

  const submitJob = async (e) => {
    e.preventDefault();
    try {
      console.log("in submit");
      const grade = {};
      Object.entries(salary).forEach((el) => {
        grade[`${el[0]}`] = parseFloat(el[1]);
      });
      const jobGrade = {
        name: props?.name ? props?.name : new Date().toDateString(),
        branch_id,
        salary: grade,
        totalGrade: parseFloat(totalGrade),
        userID: props.user_id,
        singleGrade,
        custom: singleCustom,
      };
      //console.log(jobGrade);
      const result = await fetchActionsUtil(
        `${URLS.backendemployeeSalary}/add`,
        "POST",
        token,
        {
          jobGrade: jobGrade,
        }
      );

      if (result.success) {
        props.handleClose();

        toast("Salary updated successfully", {
          type: "success",
        });
      } else {
        const e = Error(result.err);
        console.log(e);
        toast("Unable to edit salary", {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      {...props}
      show={props.show}
      onHide={props.handleClose}
      aria-labelledby=""
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit {props?.name} Salary</Modal.Title>
      </Modal.Header>
      <Form onSubmit={submitJob} className="px-3 mt-3">
        <Form.Group as={Row}>
          {/*  */}
          <Form.Label style={{ fontSize: "14px" }} column sm={2}>
            Job Grade
          </Form.Label>
          <Col sm={8}>
            <Select
              closeMenuOnSelect={true}
              isSearchable={true}
              onChange={(selected) => {
                onSelectedGrade(selected.value);
              }}
              value={jobGrades.find((d) => singleGrade === d.value)}
              options={jobGrades}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          {/*  */}
          {/* {showSetting?.basic ? ( */}
          <>
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Basic
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={salary?.basic}
                allowNegativeValue={false}
                name="basic"
                // allowDecimals={false}
                onValueChange={(value, name) => onChangeInputs(name, value)}
              />
            </Col>
          </>
          {/* ) : null} */}
          {/*  */}
          {/* {showSetting?.housing ? ( */}
          <>
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Housing
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={salary?.housing}
                allowNegativeValue={false}
                name="housing"
                // allowDecimals={false}
                onValueChange={(value, name) => onChangeInputs(name, value)}
              />
            </Col>
          </>
          {/* ) : null} */}
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          {/* {showSetting?.medical ? ( */}
          <>
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Medical
            </Form.Label>

            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={salary?.medical}
                allowNegativeValue={false}
                name="medical"
                // allowDecimals={false}
                onValueChange={(value, name) => onChangeInputs(name, value)}
              />
            </Col>
          </>
          {/* ) : null}
           {showSetting?.transport ? ( */}
          <>
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Transport
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={salary?.transport}
                allowNegativeValue={false}
                name="transport"
                // allowDecimals={false}
                onValueChange={(value, name) => onChangeInputs(name, value)}
              />
            </Col>
          </>
          {/* ) : null} */}
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          {/* {showSetting?.utility ? ( */}
          <>
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Utility
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={salary?.utility}
                allowNegativeValue={false}
                name="utility"
                // allowDecimals={false}
                onValueChange={(value, name) => onChangeInputs(name, value)}
              />
            </Col>
          </>
          {/* ) : null}
          {showSetting?.dressing ? ( */}
          <>
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Dressing
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={salary?.dressing}
                allowNegativeValue={false}
                name="dressing"
                // allowDecimals={false}
                onValueChange={(value, name) => onChangeInputs(name, value)}
              />
            </Col>
          </>
          {/* ) : null} */}
        </Form.Group>

        <Form.Group as={Row} className="mb-2">
          {/* {showSetting?.entertainment ? ( */}
          <>
            <Form.Label
              style={{ fontSize: "14px" }}
              column
              // sm={{ span: 2, offset: 6 }}
              sm={2}
            >
              Entertainment
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control mb-3"
                value={salary?.entertainment}
                allowNegativeValue={false}
                name="entertainment"
                // allowDecimals={false}
                onValueChange={(value, name) => onChangeInputs(name, value)}
              />
            </Col>
          </>
          {/* ) : null} */}
          {singleCustom?.map((el, i) => (
            <Fragment key={i}>
              <Form.Label
                style={{ fontSize: "14px" }}
                column
                // sm={{ span: 2, offset: 6 }}
                sm={2}
              >
                {el?.name}
              </Form.Label>
              <Col sm={4} className="mb-2">
                <CurrencyInput
                  className="form-control"
                  value={el?.value}
                  allowNegativeValue={false}
                  name={el?.name}
                  // allowDecimals={false}
                  onValueChange={(value, name) => onChangeCustom(name, value)}
                />
              </Col>
            </Fragment>
          ))}
        </Form.Group>
        {/* //singleCustom */}
        <Form.Group as={Row}>
          <Form.Label style={{ fontSize: "14px" }} column sm={2}>
            <b>GROSS SALARY</b>
          </Form.Label>
          <Col sm={4}>
            <CurrencyInput
              className="form-control"
              value={totalGrade}
              allowNegativeValue={false}
              onValueChange={(value, name) => calculatePercentage(value)}
              disabled={typeOfSalary !== "Percentage"}
            />
          </Col>
        </Form.Group>
        <div className="d-flex justify-content-center p-4">
          <button
            style={{
              borderRadius: "7px",
              backgroundColor: "#5378b2",
              border: "none",
            }}
            className="btn btn-primary submit-btn"
          >
            Update Salary
          </button>
        </div>
      </Form>
    </Modal>
  );
};
