import React, { useEffect, useState } from "react";
import { fetchActionsUtil, useCookie } from "../../utils";
import moment from "moment";
import { URLS } from "../urls";
import ModalLoader from "../ModalLoader";

const LeaveHistory = ({ eId }) => {
  const token = useCookie("token");

  const [state, setState] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const leavesData = fetchActionsUtil(
        `${URLS.backendApplyLeaves}/getById/${eId}`,
        "GET",
        token
      );

      const [leavesResult] = await Promise.all([leavesData]);

      setState(leavesResult.applyLeaves);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const calculateDays = (days) => {
    return days > 1 ? `${days} days ` : `${days} day`;
  };

  const checkIfLeaveFinished = (date) => {
    let data;

    const check = moment(new Date()).isAfter(moment(date));

    if (!check) {
      data = (
        <p className="dropdown-item open-active" href="#">
          {" "}
          Active
        </p>
      );
    } else {
      data = (
        <p className="dropdown-item rejected" href="#">
          {" "}
          Finished
        </p>
      );
    }

    return data;
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          {state?.length > 0 ? (
            <table className="table table-striped custom-table mb-0 datatable">
              <thead>
                <tr>
                  <th>Leave Type</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Total no of days</th>
                  <th>No of days taken</th>
                  <th>No of days left</th>
                  <th>Active</th>
                  <th>Reason</th>
                  <th className="text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                {state.map((appliedLeave) => {
                  return (
                    <tr>
                      <td>{appliedLeave?.name}</td>
                      <td>
                        {moment(appliedLeave?.from).format("DD/MM/YYYY")}
                        {/* {appliedLeave?.from} */}
                      </td>
                      <td>
                        {moment(appliedLeave?.to).format("DD/MM/YYYY")}
                        {/* {appliedLeave?.to} */}
                      </td>
                      <td>
                        {appliedLeave?.totalDays
                          ? calculateDays(appliedLeave?.totalDays)
                          : "---"}
                      </td>
                      <td>{calculateDays(appliedLeave?.days)}</td>
                      <td>{calculateDays(appliedLeave?.remaining)}</td>
                      <td>{checkIfLeaveFinished(appliedLeave?.to)}</td>
                      <td>{appliedLeave?.reason}</td>
                      <td className="text-center">
                        <div className="dropdown action-label">
                          <a
                            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                            href="javaScript:void(0);"
                            // data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {appliedLeave?.status === "New" ? (
                              <i className="fa fa-dot-circle-o text-purple"></i>
                            ) : appliedLeave?.status === "Pending" ? (
                              <i className="fa fa-dot-circle-o text-info"></i>
                            ) : appliedLeave?.status === "Approved" ? (
                              <i className="fa fa-dot-circle-o text-success"></i>
                            ) : (
                              <i className="fa fa-dot-circle-o text-danger"></i>
                            )}
                            {appliedLeave?.status}
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="d-flex justify-content-center my-4">
              <div>
                <h2>No leave yet</h2>
              </div>
            </div>
          )}
        </div>

        <ModalLoader show={isLoading} />
      </div>
    </div>
  );
};

export default LeaveHistory;
