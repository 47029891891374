import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, ModalBody } from "reactstrap";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Button, Col, Form, Row, Modal, Table } from "react-bootstrap";
import Select from "react-select";
import ModalLoader from "../ModalLoader";
import { toast } from "react-toastify";
import { URLS } from "../urls";
import { paginationOptions, useCookie } from "../../utils";
import Cookies from "universal-cookie";
import moment from "moment";
import Papa from "papaparse";
import ReactPaginate from "react-paginate";
import TableComponent from "../TableComponent";
import DisplaySingleModal from "./DisplaySingleModal";
import AddAttendanceModal from "./AddAttendance";

function NewTimeSheet(props) {
  const branch_id = useCookie("branch_id");
  const date = new Date();
  const [loading, setLoading] = useState(false);
  const [UsersID, setUsersID] = useState("");
  const [showSaveButton, setshowSaveButton] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDisplaySingle, setShowDisplaySingle] = useState(false);
  const [searchName, setSearchName] = useState("");
  //----

  const [timeSheetToDelete, setTimeSheetToDelete] = useState();
  const [showAttendance, setShowAttendence] = useState({});
  const [payrollDate, setPayrollDate] = useState({
    payrollYear: date.getFullYear(),
    payrollMonth: date.getMonth() + 1,
  });

  useEffect(() => {
    getAttendance();
    return () => {};
  }, []);

  const getAttendance = (name = "") => {
    setLoading(true);
    const token = "dummy token";
    fetch(
      `${URLS.backendattendance}/get/${branch_id}?payrollYear=${payrollDate.payrollYear}&payrollMonth=${payrollDate.payrollMonth}&Name=${name}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setShowAttendence({ excellDetails: result?.attendance });
        setLoading(false);
        setshowSaveButton(false);
      })
      .catch((err) => {
        setLoading(false);
        toast("Unable to get TimeSheet", {
          type: "error",
        });
        setshowSaveButton(false);
      });
  };

  const SaveAttendance = () => {
    setLoading(true);
    const token = "dummy token";
    fetch(`${URLS.backendattendance}/add`, {
      method: "POST",
      body: JSON.stringify({
        showAttendance,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        toast("Save Successfully", {
          type: "success",
        });
        setLoading(false);
        setshowSaveButton(false);
      })
      .catch((err) => {
        setLoading(false);
        setshowSaveButton(false);
        toast("Unable to get TimeSheet", {
          type: "error",
        });
      });
  };

  const deleteTimeSheet = (data) => {
    setTimeSheetToDelete();
    setLoading(true);
    const token = "dummy token";
    fetch(`${URLS.backendPayroll}/delete-time-sheet`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          toast("Successs", {
            type: "success",
          });
          getAttendance();
        } else {
          throw Error();
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast("Unable to delete", {
          type: "error",
        });
      });
  };

  const search = () => {
    getAttendance();
  };

  const onRowClick = (attendance) => {
    setUsersID(attendance.ID);
    setShowDisplaySingle(true);
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th>No</th>
          <th>Name </th>
          <th>ID </th>
          <th>Date </th>
          <th>In-Time</th>
          <th>Out-Time</th>
          <th>Result</th>
        </tr>
      </thead>
    );
  };

  const tableBodyData = (attendance, index) => {
    return (
      <>
        <td>{index + 1}</td>
        <td>{attendance.Name}</td>
        <td>{attendance.ID}</td>
        <td>{moment(attendance?.Date)?.format("MM/DD/YYYY")}</td>
        <td>{attendance.in}</td>
        <td>{attendance.out}</td>
        <td>{attendance.result}</td>
      </>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      getAttendance(searchName);
    }, 1000);
  }, [searchName]);

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col">
                <Breadcrumb> 
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Attendance</BreadcrumbItem>
                </Breadcrumb>
                {/* <h4 className="page-title">Employee</h4> */}
              </div>
              <div className="col-auto text-right float-right ml-auto m-b-30">
                <button
                  className="btn add-btn"
                  onClick={() => setShowUploadModal(true)}
                >
                  <i className="fa fa-plus"></i>Upload Attendance
                </button>
              </div>
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}

            <div className="p-3">
              <div className="d-flex justify-content-between">
                <div className="form-inline ml-3  mb-3">
                  <label>Month</label>
                  <select
                    className="form-control mx-2"
                    name="payrollMonth"
                    value={payrollDate.payrollMonth}
                    onChange={(e) =>
                      setPayrollDate({
                        ...payrollDate,
                        payrollMonth: e.target.value,
                      })
                    }
                  >
                    {[...Array(12)].map((e, index) => (
                      <option key={index}>{index + 1}</option>
                    ))}
                  </select>
                  <label>Year</label>
                  <input
                    type="number"
                    style={{ width: "100px" }}
                    className="form-control mx-2"
                    value={payrollDate.payrollYear}
                    onChange={(e) =>
                      setPayrollDate({
                        ...payrollDate,
                        payrollYear: e.target.value,
                      })
                    }
                  />
                  <Button onClick={() => search()}>Search</Button>
                </div>

                <div className="mx-3">
                  <Form.Group className="mb-3">
                    <Form.Control
                      name="title"
                      placeholder="Enter a title"
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                    />
                  </Form.Group>
                </div>

                <div>
                  {showAttendance?.excellDetails?.length > 0 &&
                    showSaveButton && (
                      <button
                        onClick={SaveAttendance}
                        className="btn btn-primary"
                      >
                        Save Attendance
                      </button>
                    )}
                </div>
              </div>

              <div className="mt-4">
                {/*<!-- JobGradeHistory Table -->*/}
                <div className="info">
                  <div className="table-height">
                    {showAttendance?.excellDetails?.length > 0 && (
                      <TableComponent
                        responsive
                        borderless
                        // striped
                        sticky
                        // hover
                        tableHeadsFunction={tableHead}
                        mainDataArray={showAttendance?.excellDetails}
                        tableDataRowFunction={tableBodyData}
                        className="product-table text-nowrap"
                        onDoubleClickRow={onRowClick}
                      />
                    )}
                  </div>
                  {/* <div className="d-flex justify-content-between px-3 align-items-center pagination">
                    <div className="pagination_left">
                      <p className="m-0 p-0">Show</p>
          <select
            // value={queryParams.limit}
            name="limit"
            className="form-select "
            // onChange={(e) => handleSearchQueryChange(e)}
          >
            <option value="10">10 rows</option>
            <option value="20">20 rows</option>
            <option value="30">30 rows</option>
            <option value="40">40 rows</option>
            <option value="50">50 rows</option>
            <option value="100">100 rows</option>
          </select>
                      <p className="m-0 text-muted">
                        Showing {data?.startIndex + 1} to{" "}
                        {data?.endIndex <= data?.count
                          ? data?.endIndex
                          : data?.count}{" "}
                        of {data.count} entries
                      </p>
                    </div>

                    <ReactPaginate
                      {...paginationOptions}
                      pageCount={Math.ceil(data?.count / queryParams.limit)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={0}
                      onPageChange={({ selected }) => {
                        setQueryParams({
                          ...queryParams,
                          page: selected + 1,
                        });
                        scrollToTop();
                      }}
                      forcePage={queryParams.page - 1}
                    />
                  </div> */}
                </div>
                {/*<!-- Table --> */}
              </div>
            </div>

            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
      <ModalLoader show={loading} />
      {showUploadModal && (
        <AddAttendanceModal
          setShowUploadModal={setShowUploadModal}
          showAttendance={setShowAttendence}
          updatesetPayrollDate={setPayrollDate}
          setshowSaveButton={setshowSaveButton}
        />
      )}
      {/* {showUploadModalToEdit && selectedTimesheetToEdit ? (
        <UploadTimeSheetModal
          selectedTimesheetToEdit={selectedTimesheetToEdit}
          setShowUploadModal={setShowUploadModalToEdit}
          setSelectedTimesheetToEdit={setSelectedTimesheetToEdit}
        />
      ) : null} */}
      {timeSheetToDelete && (
        <DeleteTimeSheetModal
          setTimeSheetToDelete={setTimeSheetToDelete}
          deleteTimeSheet={deleteTimeSheet}
          timeSheetToDelete={timeSheetToDelete}
        />
      )}
      {showDisplaySingle && (
        <DisplaySingleModal
          setShowDisplaySingle={setShowDisplaySingle}
          UserId={UsersID}
          selectedTimes={payrollDate}
        />
      )}
    </body>
  );
}

function DeleteTimeSheetModal({
  timeSheetToDelete,
  setTimeSheetToDelete,
  deleteTimeSheet,
}) {
  return (
    <Modal
      centered
      show={true}
      onHide={() => {
        setTimeSheetToDelete();
      }}
      className="custom-modal"
    >
      <Modal.Body>
        <div className="form-header">
          <h3>Delete Timesheet</h3>
          <p>Are you sure want to delete?</p>
        </div>
        <div className="modal-btn delete-action p-cursor">
          <div className="row">
            <div className="col-6">
              <div
                className="btn  continue-btn btn-danger "
                onClick={() => deleteTimeSheet(timeSheetToDelete)}
              >
                Delete
              </div>
            </div>
            <div className="col-6">
              <div
                data-dismiss="modal"
                className="btn btn-primary cancel-btn"
                onClick={() => {
                  setTimeSheetToDelete();
                }}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewTimeSheet));
