import React, { Component } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import $ from "jquery";
import "../../assets/css/jobgrade.css";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import currency from "currency.js";
import { branch_id } from "../../utils";

export default class EditJobGradeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobGrade: {
        name: "",
        description: "",
        type: "",
        salary: {
          basic: 0,
          housing: 0,
          medical: 0,
          transport: 0,
          utility: 0,
          entertainment: 0,
          dressing: 0,
          offshoreAllowances: 0,
          transitAllowances: 0,
          overTimeAllowances: 0,
        },
        custom: [],
      },
      totalcustom: 0,
      showItems: {},
    };
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr?.map((item) => [item[key], item]))?.values()];
  }

  componentWillMount() {
    const token = "dummy token";

    fetch(`${URLS.backendsalarySetting}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const items = {};
        Object.entries(result?.jobGrades[0]).forEach((el) => {
          if (el[1] > 0) {
            items[`${el[0]}`] = true;
          }
        });

        let newGrades = result?.jobGrades[0]?.custom;
        newGrades = newGrades?.map((el) => ({ name: el?.name, value: 0 }));

        const { jobGrade } = this.props;

        const totalcustom =
          jobGrade?.custom?.length > 0
            ? jobGrade.custom.reduce(
                (c, p) => parseFloat(c) + parseFloat(p?.value),
                0
              )
            : 0;

        const custom = jobGrade?.custom;
        const addNewCustom = newGrades?.concat(custom);

        const makeCustom = custom && this.getUniqueListBy(addNewCustom, "name");

        // console.log(addNewCustom, makeCustom);

        jobGrade.custom = jobGrade?.custom?.length > 0 ? makeCustom : newGrades;

        this.setState({
          jobGrade: jobGrade,
          totalcustom,
          showItems: items,
        });
      });
  }

  updateJobGradeName = (event) => {
    const { jobGrade } = this.state;
    jobGrade.name = event.target.value;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeDescription = (event) => {
    const { jobGrade } = this.state;
    jobGrade.description = event.target.value;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryBasic = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.basic = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryHousing = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.housing = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryMedical = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.medical = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryTransport = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.transport = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryUtility = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.utility = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryEntertainment = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.entertainment = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryDressing = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.dressing = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryOffshoreAllowances = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.offshoreAllowances = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryTransitAllowances = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.transitAllowances = event;
    this.setState({
      jobGrade,
    });
  };
  updateJobGradeSalaryOverTimeAllowances = (event) => {
    const { jobGrade } = this.state;
    jobGrade.salary.overTimeAllowances = event;
    this.setState({
      jobGrade,
    });
  };
  submitJob = (e) => {
    e.preventDefault();
    console.log("in submit");
    this.props.setLoader(true);
    const { jobGrade } = this.state;
    const token = "dummy token";
    fetch(`${URLS.backendJobGrades}/edit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ jobGrade: jobGrade }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          this.props.changeEditJobState();
          this.props.refech();
          toast("Job grade edited successfully", {
            type: "success",
          });
        } else {
          this.props.changeEditJobState();
          const e = Error(result.err);
          console.log(e);
        }
      })
      .catch((err) => {
        toast("Error unable to edit Job Grade", {
          type: "error",
        });
      })
      .finally(() => {
        this.props.setLoader(false);
      });
  };

  cancelJob = (e) => {
    e.preventDefault();
    console.log("in cancel");
    this.props.changeEditJobState();
    // $('#add_job').hide();
  };

  updateType = (e) => {
    const { jobGrade } = this.state;
    jobGrade.type = e.target.value;
    this.setState({
      jobGrade,
    });
  };

  onChangeNewOnes = (i, name, value) => {
    const { jobGrade } = this.state;
    const all = jobGrade.custom.map((el, id) => {
      if (el.name === name) {
        return { ...el, value };
      } else {
        return el;
      }
    });
    jobGrade.custom = all;

    const totalcustom =
      jobGrade.custom?.length > 0
        ? jobGrade.custom.reduce(
            (c, p) => parseFloat(c) + parseFloat(p?.value),
            0
          )
        : 0;
    this.setState({ jobGrade, totalcustom });
  };

  render() {
    const { jobGrade, totalcustom, showItems } = this.state;
    return (
      <Modal
        show={this.props.isShow}
        onHide={() => this.props.changeEditJobState()}
        // dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={this.submitJob}
            style={{
              maxHeight: "100%",
              borderRadius: "10px",
            }}
          >
            <Form.Group
              as={Row}
              style={{
                alignItems: "left",
                justifyContent: "left",
                fontSize: "16px",
                color: "#ffffff",
                fontWeight: "bold",
                height: "40px",
                padding: "10px 15px",
                marginLeft: "0",
                marginRight: "0",
                display: "block",
                backgroundColor: "#5378b2",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  height: "20px",
                  lineHeight: "20px",
                  float: "left",
                }}
              >
                Edit Job Grade
              </span>
              <span
                style={{
                  display: "inline-block",
                  height: "10px",
                  float: "right",
                  padding: "5px",
                  position: "relative",
                }}
              ></span>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{
                marginLeft: "0",
                marginRight: "0",
                "padding-left": "10px",
              }}
            >
              <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                Job Grade
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  value={jobGrade.name}
                  onChange={this.updateJobGradeName}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{
                marginLeft: "0",
                marginRight: "0",
                "padding-left": "10px",
              }}
            >
              <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                Salary Type
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  as="select"
                  custom
                  required
                  value={jobGrade.type}
                  onChange={this.updateType}
                >
                  <option value="">Select Salary type</option>
                  <option
                    value="prorated"
                    selected={jobGrade.type === "prorated"}
                  >
                    Prorated
                  </option>
                  <option value="normal" selected={jobGrade.type === "normail"}>
                    Normal
                  </option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{
                marginLeft: "0",
                marginRight: "0",
                "padding-left": "10px",
              }}
            >
              <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                Description
              </Form.Label>
              <Col sm={6}>
                <textarea
                  className="form-control"
                  rows="5"
                  value={jobGrade.description}
                  onChange={this.updateJobGradeDescription}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              style={{
                backgroundColor: "#5378b2",
                alignItems: "left",
                justifyContent: "left",
                fontSize: "16px",
                color: "#ffffff",
                fontWeight: "bold",
                height: "40px",
                padding: "10px 15px",
                marginLeft: "0",
                marginRight: "0",
                display: "block",
              }}
            >
              Job Grade Salary Settings
            </Form.Group>

            <Form.Group
              as={Row}
              style={{
                marginLeft: "0",
                marginRight: "0",
                "padding-left": "10px",
              }}
            >
              {/* {jobGrade.salary.basic > 0 || showItems?.basic ? ( */}
              <>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Basic
                </Form.Label>
                <Col sm={4}>
                  <CurrencyInput
                    className="form-control"
                    value={jobGrade.salary.basic}
                    allowNegativeValue={false}
                    // allowDecimals={false}
                    onValueChange={(value, name) =>
                      this.updateJobGradeSalaryBasic(value)
                    }
                  />
                </Col>
              </>
              {/* ) : null} */}
              {/* {jobGrade.salary.housing > 0 || showItems?.housing ? ( */}
              <>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Housing
                </Form.Label>
                <Col sm={4}>
                  <CurrencyInput
                    className="form-control"
                    value={jobGrade.salary.housing}
                    allowNegativeValue={false}
                    // allowDecimals={false}
                    onValueChange={(value, name) =>
                      this.updateJobGradeSalaryHousing(value)
                    }
                  />
                </Col>
              </>
              {/* ) : null} */}
            </Form.Group>
            <Form.Group
              as={Row}
              style={{
                marginLeft: "0",
                marginRight: "0",
                "padding-left": "10px",
              }}
            >
              {/* {jobGrade.salary.medical > 0 || showItems?.medical ? ( */}
              <>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Medical
                </Form.Label>
                <Col sm={4}>
                  <CurrencyInput
                    className="form-control"
                    value={jobGrade.salary.medical}
                    allowNegativeValue={false}
                    // allowDecimals={false}
                    onValueChange={(value, name) =>
                      this.updateJobGradeSalaryMedical(value)
                    }
                  />
                </Col>
              </>
              {/* ) : null}
            {jobGrade.salary.transport > 0 || showItems?.transport ? ( */}
              <>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Transport
                </Form.Label>
                <Col sm={4}>
                  <CurrencyInput
                    className="form-control"
                    value={jobGrade.salary.transport}
                    allowNegativeValue={false}
                    // allowDecimals={false}
                    onValueChange={(value, name) =>
                      this.updateJobGradeSalaryTransport(value)
                    }
                  />
                </Col>
              </>
              {/* ) : null} */}
            </Form.Group>
            <Form.Group
              as={Row}
              style={{
                marginLeft: "0",
                marginRight: "0",
                "padding-left": "10px",
              }}
            >
              {/* {jobGrade.salary.utility > 0 || showItems?.utility ? ( */}
              <>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Utility
                </Form.Label>
                <Col sm={4}>
                  <CurrencyInput
                    className="form-control"
                    value={jobGrade.salary.utility}
                    allowNegativeValue={false}
                    // allowDecimals={false}
                    onValueChange={(value, name) =>
                      this.updateJobGradeSalaryUtility(value)
                    }
                  />
                </Col>
              </>
              {/* ) : null} */}
              {/* {jobGrade.salary.entertainment > 0 || showItems?.entertainment ? ( */}
              <>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Entertainment
                </Form.Label>
                <Col sm={4}>
                  <CurrencyInput
                    className="form-control"
                    value={jobGrade.salary.entertainment}
                    allowNegativeValue={false}
                    // allowDecimals={false}
                    onValueChange={(value, name) =>
                      this.updateJobGradeSalaryEntertainment(value)
                    }
                  />
                </Col>
              </>
              {/* ) : null} */}
            </Form.Group>

            <Form.Group
              as={Row}
              style={{
                marginLeft: "0",
                marginRight: "0",
                "padding-left": "10px",
              }}
            >
              {/* {jobGrade.salary.dressing > 0 || showItems?.dressing ? ( */}
              <>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Dressing
                </Form.Label>
                <Col sm={4}>
                  <CurrencyInput
                    className="form-control"
                    value={jobGrade.salary.dressing}
                    allowNegativeValue={false}
                    // allowDecimals={false}
                    onValueChange={(value, name) =>
                      this.updateJobGradeSalaryDressing(value)
                    }
                  />
                </Col>
              </>
              {/* ) : null} */}
              {jobGrade?.custom?.length > 0
                ? jobGrade?.custom?.map((el, i) => (
                    <>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        {el?.name}
                      </Form.Label>
                      <Col sm={4}>
                        <CurrencyInput
                          className="form-control mb-3"
                          value={el?.value}
                          allowNegativeValue={false}
                          // allowDecimals={false}
                          onValueChange={(value, name) =>
                            this.onChangeNewOnes(i, el?.name, value)
                          }
                        />
                      </Col>
                    </>
                  ))
                : ""}
            </Form.Group>

            <Form.Group
              as={Row}
              style={{
                marginLeft: "0",
                marginRight: "0",
                "padding-left": "10px",
              }}
            >
              <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                <b>GROSS SALARY</b>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  value={currency(jobGrade.salary.basic, {
                    symbol: "",
                  })
                    .add(jobGrade.salary.housing)
                    .add(jobGrade.salary.medical)
                    .add(jobGrade.salary.transport)
                    .add(jobGrade.salary.utility)
                    .add(jobGrade.salary.entertainment)
                    .add(jobGrade.salary.dressing)
                    .add(totalcustom)
                    .format()}
                  disabled
                />
              </Col>
            </Form.Group>

            <div className="d-flex justify-content-center p-4">
              <button
                style={{
                  borderRadius: "7px",
                  backgroundColor: "#5378b2",
                  border: "none",
                }}
                className="btn btn-primary submit-btn"
              >
                Save Changes
              </button>
            </div>

            {/* <Form.Group
            as={Row}
            style={{
              backgroundColor: "#5378b2",
              alignItems: "left",
              justifyContent: "left",
              fontSize: "16px",
              color: "#ffffff",
              fontWeight: "bold",
              height: "40px",
              padding: "10px 15px",
              marginLeft: "0",
              marginRight: "0",
              display: "block",
            }}
          >
            Other Allowance
          </Form.Group>
          <Form.Group
            as={Row}
            style={{
              marginLeft: "0",
              marginRight: "0",
              "padding-left": "10px",
            }}
          >
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Offshore Allowance
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={jobGrade.salary.offshoreAllowances}
                allowNegativeValue={false}
                // allowDecimals={false}
                onValueChange={(value, name) =>
                  this.updateJobGradeSalaryOffshoreAllowances(value)
                }
              />
            </Col>
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              Transit Allowance
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={jobGrade.salary.transitAllowances}
                allowNegativeValue={false}
                // allowDecimals={false}
                onValueChange={(value, name) =>
                  this.updateJobGradeSalaryTransitAllowances(value)
                }
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            style={{
              marginLeft: "0",
              marginRight: "0",
              "padding-left": "10px",
            }}
          >
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              OverTime Allowance
            </Form.Label>
            <Col sm={4}>
              <CurrencyInput
                className="form-control"
                value={jobGrade.salary.overTimeAllowances}
                allowNegativeValue={false}
                // allowDecimals={false}
                onValueChange={(value, name) =>
                  this.updateJobGradeSalaryOverTimeAllowances(value)
                }
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            style={{
              marginBottom: "30px",
              marginLeft: "0",
              marginRight: "0",
              paddingRight: "15px",
              justifyContent: "space-between",
            }}
          >
            <Form.Label style={{ fontSize: "14px" }} column sm={2}>
              <b>TOTAL NET SALARY</b>
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                value={currency(jobGrade.salary.basic, {
                  symbol: "",
                })
                  .add(jobGrade.salary.housing)
                  .add(jobGrade.salary.medical)
                  .add(jobGrade.salary.transport)
                  .add(jobGrade.salary.utility)
                  .add(jobGrade.salary.entertainment)
                  .add(jobGrade.salary.dressing)
                  .add(jobGrade.salary.offshoreAllowances)
                  .add(jobGrade.salary.transitAllowances)
                  .add(jobGrade.salary.overTimeAllowances)
                  .format()}
                disabled
              />
            </Col>


          </Form.Group> */}
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}
