import * as React from "react";
import { useState } from "react";
import plusicon from "../../assets/img/Plus.svg";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import user1 from "../../assets/img/user-1.png";
import user2 from "../../assets/img/user-2.png";
import user3 from "../../assets/img/user-3.png";
import { Close } from "@mui/icons-material";
import { Modal } from "reactstrap";

const ContiniousFeedback = () => {
  const [value, setValue] = React.useState(4);
  const [novalue, setnoValue] = React.useState(0);
  const [dataempty, setDataEmpty] = React.useState(true);

  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  return (
    <>
      {/* Modal Component */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <div className="tw-modal-sm tw-rounded-lg">
          <div className="tw-modal-header">
            <div className="">
              <h1>New Self Assesment</h1>
              <p>Create a new review for yourself</p>
            </div>
            <button className="" onClick={toggleModal}>
              <Close />
            </button>
          </div>
          <div className="tw-modal-sm-body">
            <div className="tw-form-group">
              <label className="tw-form-labels">Performance Rating:</label>
              <Box sx={{ "& > legend": { mt: 2 } }}>
                <Rating
                  name="simple-controlled"
                  value={novalue}
                  onChange={(event, newValue) => {
                    setValue(newValue);1  ``
                  }}
                />
              </Box>
            </div>

            <div className="tw-form-group">
              <label className="tw-form-labels">Self Feedback:</label>
              <textarea
                className="tw-form border"
                name="instructions"
                rows="4"
                style={{ height: 120 }}
                placeholder="Write some details about your performance"
                // value={application.email}
                // onChange={this.updateApplicationText.bind(this)}
                required
              ></textarea>
            </div>
            <div className="tw-line"></div>
            <button type="submit" className="tw-btn w-100">
              Submit
            </button>
          </div>
        </div>
      </Modal>
      {/* Modal Component */}
      <div className="d-flex flex-col gap-md tw-performance">
        <div className="d-flex align-items-center justify-content-between gap-md">
          <select name="year" id="" className="select-chart">
            <option value="this year">This Year</option>
            <option value="6 months ago">6 months ago</option>
            <option value="1 Year Ago">1 Year Ago</option>
            <option value="2 Years Ago">2 Years Ago</option>
          </select>
          <div className="tw-btn">
            <img src={plusicon} width={18} alt="" />
            <p>Create Peformance Template</p>
          </div>
        </div>
        <div className="d-flex justify-content-center gap-md">
          <div
            className={`performance-rating  ${dataempty ? "block" : "hidden"}`}
          >
            <h2 className="rating-heading mb-4">Performance Rating</h2>
            <div className="mt-2 d-flex align-items-center justify-content-center flex-col gap-sm ">
              <div className="d-flex text-center flex-col gap-md">
                <h1>4/5</h1>
                <div className="stars-text">Stars</div>
                <div className="d-flex align-items-center gap-sm">
                  <Box sx={{ "& > legend": { mt: 2 } }}>
                    <Rating
                      name="simple-controlled"
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                  </Box>
                </div>
              </div>
              <div className="self-feedback mt-2">
                <h2>Self Feedback</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur.
                </p>
              </div>
            </div>
          </div>
          <div
            className={`performance-rating  ${dataempty ? "hidden" : "block"}`}
          >
            <h2 className="rating-heading mb-4">Performance Rating</h2>
            <div className="mt-2 d-flex align-items-center justify-content-center flex-col gap-sm">
              <div className="d-flex text-center flex-col gap-md">
                <h1>4/5</h1>
                <div className="stars-text">Stars</div>
                <div className="d-flex align-items-center gap-sm">
                  <Box sx={{ "& > legend": { mt: 2 } }}>
                    <Rating
                      name="simple-controlled"
                      value={novalue}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                  </Box>
                </div>
              </div>

              <button onClick={toggleModal} className="tw-btn w-100">Take Self Assessment</button>
            </div>
          </div>
          <div className="performance-feedback flex-grow-1 border tw-rounded-lg p-4">
            <h1>Performance Feedback</h1>
            <div
              className={`performance-feedback-wrapper ${
                dataempty ? "block" : "hidden"
              }`}
            >
              <div className={`performance-feedback-content position-relative`}>
                <div className="content-box">
                  <div className="bulletin-dot"></div>
                  <h1>Mid-Year Review</h1>
                  <div className="group-1">
                    <img src={user1} alt="user" />
                    <div className="feedback-box">
                      <div className="d-flex align-items-center gap-md">
                        <h2>Robert L</h2> <p>. Manager</p>
                      </div>
                      <div className="feedback-statement">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`performance-feedback-content position-relative`}>
                <div className="content-box">
                  <div className="bulletin-dot"></div>

                  <div className="group-1">
                    <img src={user2} alt="user" />
                    <div className="feedback-box">
                      <div className="d-flex align-items-center gap-md">
                        <h2>Sophia G</h2> <p>. Manager</p>
                      </div>
                      <div className="feedback-statement">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam,
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`performance-feedback-content position-relative`}>
                <div className="content-box">
                  <div className="bulletin-dot"></div>

                  <div className="group-1">
                    <img src={user3} alt="user" />
                    <div className="feedback-box">
                      <div className="d-flex align-items-center gap-md">
                        <h2>Thomas R</h2> <p>. ERP</p>
                      </div>
                      <div className="feedback-statement">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`performance-feedback-wrapper ${
                dataempty ? "hidden" : "block"
              }`}
            >
              <div className={`performance-feedback-content position-relative`}>
                <div className="content-box">
                  <div className="bulletin-dot"></div>
                  <h1>Mid-Year Review</h1>
                  <div className="group-1">
                    <div className="no-feedback">
                      <p>No feedback yet</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`performance-feedback-content position-relative`}>
                <div className="content-box">
                  <div className="bulletin-dot"></div>
                  <h1>End-Year Review</h1>
                  <div className="group-1">
                    <div className="no-feedback">
                      <p>No feedback yet</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContiniousFeedback;
