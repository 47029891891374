import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Datepicker from "react-datetime";

const PersonalInfoModal = ({
  savePersonalInfo,
  personalInfo,
  updatePersonalInfoFirstName,
  updatePersonalInfoLastName,
  updatePersonalInfoMiddleName,
  updatePersonalInfoAddressApartment,
  updatePersonalInfoAddressStreet,
  updatePersonalInfoAddressCity,
  updatePersonalInfoAddressState,
  updatePersonalInfoAddressZip,
  updatePersonalInfoPhoneHome,
  updatePersonalInfoPhoneAlternate,
  updatePersonalInfoEmail,
  updatePersonalInfoGovtId,
  updatePersonalGender,
  updatePersonalInfoBirth,
  updatePersonalInfoMartial,
  updatePersonalInfoSpouseName,
  updatePersonalInfoSpouseEmployer,
  updatePersonalInfoSpousePhone,
  updatePersonalInfoPrevEmployeeEmployee,
  updatePersonalInfoPrevEmployeePhone,
}) => {
  return (
    <div
      id="personal_info_modal"
      className="modal custom-modal fade"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Personal Information</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={savePersonalInfo}>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Full Name
                </Form.Label>
                <Col sm={10}>
                  <Row>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="First name"
                        value={personalInfo?.name?.first}
                        onChange={updatePersonalInfoFirstName}
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="Last name"
                        value={personalInfo?.name?.last}
                        onChange={updatePersonalInfoLastName}
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="Middle Initial"
                        value={personalInfo?.name?.middle}
                        onChange={updatePersonalInfoMiddleName}
                      />
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Address
                </Form.Label>
                <Col sm={10}>
                  <Form.Row>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="Apartment/Unit #"
                        value={personalInfo?.address?.apartment}
                        onChange={updatePersonalInfoAddressApartment}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={8}>
                      <Form.Control
                        placeholder="Street Address"
                        value={personalInfo?.address?.street}
                        onChange={updatePersonalInfoAddressStreet}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="City"
                        value={personalInfo?.address?.city}
                        onChange={updatePersonalInfoAddressCity}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="State"
                        value={personalInfo?.address?.state}
                        onChange={updatePersonalInfoAddressState}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="Zip code"
                        value={personalInfo?.address?.zip}
                        onChange={updatePersonalInfoAddressZip}
                      />
                    </Form.Group>
                  </Form.Row>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Home Phone
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    value={personalInfo?.phone?.home}
                    onChange={updatePersonalInfoPhoneHome}
                  />
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Alternate Phone
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    value={personalInfo?.phone?.alternate}
                    onChange={updatePersonalInfoPhoneAlternate}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Email
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    placeholder="Email"
                    value={personalInfo?.email}
                    onChange={updatePersonalInfoEmail}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  SSN or Gov’t ID:
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    placeholder="Gov’t ID:"
                    value={personalInfo?.govtId}
                    onChange={updatePersonalInfoGovtId}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Gender:
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    as="select"
                    value={personalInfo?.gender}
                    onChange={updatePersonalGender}
                  >
                    <option>Choose...</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Birth Date
                </Form.Label>
                <Col sm={4}>
                  <div classname="datePicker">
                    <i
                      class="fa fa-calendar fa-xs calendar-icon"
                      aria-hidden="true"
                    ></i>
                    <Datepicker
                      value={personalInfo?.birth}
                      closeOnSelect={true}
                      onChange={updatePersonalInfoBirth}
                    />
                  </div>
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Marital Status
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    value={personalInfo?.martial}
                    onChange={updatePersonalInfoMartial}
                  >
                    <option>Choose...</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorce">Divorce</option>
                    <option value="Widow">Widow</option>
                    <option value="Widower">Widower</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Spouse’s Name
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    value={personalInfo?.spouse?.name}
                    onChange={updatePersonalInfoSpouseName}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Spouse’s Employer
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    placeholder="Spouse’s Employer"
                    value={personalInfo?.spouse?.employer}
                    onChange={updatePersonalInfoSpouseEmployer}
                  />
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Spouse’s Work Phone
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    placeholder="Spouse’s Work Phone"
                    value={personalInfo?.spouse?.phone}
                    onChange={updatePersonalInfoSpousePhone}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Previous Employee
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    placeholder="Previous Employee"
                    value={personalInfo?.prevEmployee?.employee}
                    onChange={updatePersonalInfoPrevEmployeeEmployee}
                  />
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Previous Employer Phone
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    placeholder="Previous Employer Phone"
                    value={personalInfo?.prevEmployee?.phone}
                    onChange={updatePersonalInfoPrevEmployeePhone}
                  />
                </Col>
              </Form.Group>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  data-toggle="modal"
                  data-target="#personal_info_modal"
                >
                  Save
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoModal;
