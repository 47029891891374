import Nav from "react-bootstrap/Nav";
import "../../assets/scss/job.css";
import React from "react";

function UnderlineExample({ changeJobType, type }) {
  const details = [
    { name: "All Vacancies", value: "all" },
    { name: "Open", value: "open" },

    { name: "Completed", value: "completed" },

    { name: "On progress", value: "onprogress" },
  ];

  const DisplayData = ({ name, value }) => {
    return (
      <Nav.Item variant="underline">
        <Nav.Link
          eventKey="link-1"
          className={type === value ? "" : "text-secondary"}
          onClick={() => changeJobType(value)}
        >
          {name}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <Nav variant="underline" defaultActiveKey="./">
      <Nav.Item variant="underline">
        <Nav.Link>
          <h3 className=" other_det_2">Vacancy list</h3>
        </Nav.Link>
      </Nav.Item>

      {details?.map(({ name, value }) => (
        <DisplayData name={name} value={value} />
      ))}
    </Nav>
  );
}

export default UnderlineExample;
